import { useState } from "react";
import {  Link } from "react-router-dom";

import { PropTypes } from "prop-types";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

// var ps;

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import {logout} from 'actions/userActions'
import { useSelector } from 'react-redux'; 

import LanguageSelector from '../LanguageSelector'

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
 
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const { logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  /////////////

  const { t } = useTranslation();

  const navigate = useNavigate()  

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
    navigate("/")
  }

  const is_collaborator = useSelector((state) => state.userLogin.userInfo.is_collaborator);
  const is_wine_manager = useSelector((state) => state.userLogin.userInfo.is_wine_manager);

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={"https://i.ibb.co/NZ8qZsk/111111.png"}
            />
            {" "}Vineyard Note
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <LanguageSelector/>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={"https://i.ibb.co/KXBJ1dm/149071.png"}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">{t('Welcome!')}</h6>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={logoutHandler}>
                <i class="fa-solid fa-right-from-bracket"></i>
                <span>{t('Logout')}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <>
                      <div style={{ textAlign: "center" }}>
                          <img alt={logo.imgAlt} src={"https://i.ibb.co/NZ8qZsk/111111.png"} /> Vineyard Note
                      </div>
                    </>
                    // <Link to={logo.innerLink}>
                    //   <img alt={logo.imgAlt} src={"https://i.ibb.co/NZ8qZsk/111111.png"} /> {" "} AIBIOSAT
                    // </Link>
                  ) : (
                    //<a href={logo.outterLink}>
                    <>
                      <img alt={logo.imgAlt} src={"https://i.ibb.co/NZ8qZsk/111111.png"} /> {" "} Vineyard Note
                    </>
                    //</Col></a>                    
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <h6 className="navbar-heading text-muted">Home</h6>
          <Nav className="mb-md-3" navbar>
            { is_collaborator ? (
              <NavItem>
                <NavLink href="/">
                  <i class="fa-solid fa-pen-nib"></i>
                    {t('Note')}
                </NavLink>
              </NavItem>
            ):(
              <>
              </>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table, Card, CardHeader, Button, Spinner } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from "components/Headers/Header.js";

function NoteList() {

  const [loading, setLoading] = useState(true)
  const [note, setNote] = useState([])

  const token = useSelector((state) => state.userLogin.userInfo.token);

  const is_wine_manager = useSelector((state) => state.userLogin.userInfo.is_wine_manager);

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  useEffect(() => {
    const fetchData = async () => {

      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/note/note-list/`, config);
        setNote(response.data);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token]);

  const download = async () => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/note/download-note/`, config);

        // Creazione di un link per scaricare il file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'notes.zip');
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Errore durante il download:', error);
    }
  };

  return (
    <div>
      <Header />
      <Container className="mt--15" fluid>
        {loading ? (
          <>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </>
        ):(
          <>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-right mt-5">
                <Link to="/add-note">
                  <Button color="success" className="ml-auto">
                    {t('Add Note')} <i className='fa fa-plus'></i>
                  </Button>
                </Link>
                <Button color="info" className="ml-auto" onClick={download}>
                    {t('Download Note')} <i class="fa-solid fa-cloud-arrow-down"></i>
                </Button>                
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                {is_wine_manager ? (
                  <>
                    {note && note.length > 0 ? (
                      <>
                        <Card className="shadow">
                          <CardHeader className="border-0">
                            <h3 className="mb-0">{t('List of the note')}</h3>
                          </CardHeader>
                          <Table
                            className="align-items-center table-flush"
                            responsive
                            >
                            <thead>
                              <tr>
                                <th>{t('User')}</th>
                                <th>{t('Plant')}</th>
                                <th>{t('Note')}</th>
                                <th>{t('Image')}</th>
                                <th>{t('Created at')}</th>
                                <th>{t('Detail')}</th>
                                <th>{t('Edit')}</th>
                                <th>{t('Delete')}</th>                      
                              </tr>
                            </thead>
                            <tbody>
                              {note.map((farmer, index) => (
                                <tr key={index}>
                                  <td>{farmer.User_Email}</td>
                                  <td>{farmer.Plant}</td>
                                  <td>{farmer.Note}</td>
                                  <td>
                                    {farmer.Image ? (
                                      <img
                                        src={farmer.Image}
                                        alt="Note"
                                        style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                      />
                                    ) : (
                                      <span>{t('No Image')}</span>
                                    )}
                                  </td>
                                  <td>
                                    {(() => {
                                      const date = new Date(farmer.created_at); // Convert to Date object
                                      date.setHours(date.getHours() + 1); // Add 1 hour
                                      const day = date.getDate().toString().padStart(2, '0'); // Get day with leading zero
                                      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month with leading zero
                                      const year = date.getFullYear(); // Get year
                                      const hours = date.getHours().toString().padStart(2, '0'); // Get hours with leading zero
                                      const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes with leading zero
                                      return `${day}/${month}/${year} ${hours}:${minutes}`; // Return formatted date
                                    })()}
                                  </td>
                                  <td>
                                    <Link to={`/detail-note/${farmer.id}`}>
                                      <Button color="primary">{t('Detail')} <i class="fa-solid fa-magnifying-glass"></i></Button>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={`/update-note/${farmer.id}`}>
                                      <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={`/delete-note/${farmer.id}`}>
                                      <Button color="danger">
                                      {t('Delete')} <i className="fas fa-trash"></i>
                                      </Button>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Card>
                      </>
                      ):(
                        <>
                          <Card className="shadow">
                            <CardHeader className="border-0">
                              <h3 className="mb-0">{t('No Note are Registrated')}</h3>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                              >
                            </Table>
                          </Card>
                        </>        
                      )
                    }
                  </>
                ):(
                  <>
                    {note && note.length > 0 ? (
                      <>
                        <Card className="shadow">
                          <CardHeader className="border-0">
                            <h3 className="mb-0">{t('List of the note')}</h3>
                          </CardHeader>
                          <Table
                            className="align-items-center table-flush"
                            responsive
                            >
                            <thead>
                              <tr>
                                <th>{t('Plant')}</th>
                                <th>{t('Note')}</th>
                                <th>{t('Image')}</th>
                                <th>{t('Created at')}</th>
                                <th>{t('Detail')}</th>
                                <th>{t('Edit')}</th>
                                <th>{t('Delete')}</th>                      
                              </tr>
                            </thead>
                            <tbody>
                              {note.map((farmer, index) => (
                                <tr key={index}>
                                  <td>{farmer.Plant}</td>
                                  <td>{farmer.Note}</td>
                                  <td>
                                    {farmer.Image ? (
                                      <img
                                        src={farmer.Image}
                                        alt="Note"
                                        style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                      />
                                    ) : (
                                      <span>{t('No Image')}</span>
                                    )}
                                  </td>
                                  <td>  
                                    {(() => {
                                      const date = new Date(farmer.created_at); // Convert to Date object
                                      date.setHours(date.getHours() + 1); // Add 1 hour
                                      const day = date.getDate().toString().padStart(2, '0'); // Get day with leading zero
                                      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month with leading zero
                                      const year = date.getFullYear(); // Get year
                                      const hours = date.getHours().toString().padStart(2, '0'); // Get hours with leading zero
                                      const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes with leading zero
                                      return `${day}/${month}/${year} ${hours}:${minutes}`; // Return formatted date
                                    })()}
                                  </td>
                                  <td>
                                    <Link to={`/detail-note/${farmer.id}`}>
                                      <Button color="primary">{t('Detail')} <i class="fa-solid fa-magnifying-glass"></i></Button>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={`/update-note/${farmer.id}`}>
                                      <Button color="warning">{t('Edit')} <i className="fas fa-edit"></i></Button>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={`/delete-note/${farmer.id}`}>
                                      <Button color="danger">
                                      {t('Delete')} <i className="fas fa-trash"></i>
                                      </Button>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Card>
                      </>
                      ):(
                        <>
                          <Card className="shadow">
                            <CardHeader className="border-0">
                              <h3 className="mb-0">{t('No Note are Registrated')}</h3>
                            </CardHeader>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                              >
                            </Table>
                          </Card>
                        </>        
                      )
                    }
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  )
}

export default NoteList

const translation = {
  "GB": {
    "translation": {
      "Hello": "Hello",
      "Yes": "Yes",
      "No": "No",
      "Temporary_grassland": "Grassland",
      "Grassland": "Grassland",
      "Clovers": "Clovers",
      "Olive_groves": "Olive",
      "Olive": "Olive",
      "Oranges": "Oranges",
      "Apple_fruit": "Apple",
      "Apple": "Apple",
      "Cherry_fruit": "Cherry",
      "Cherry": "Cherry",
      "Nuts_trees": "Nuts",
      "Nuts": "Nuts",
      "Rye": "Rye",
      "Potatoes": "Potatoes",
      "Triticale": "Triticale",
      "Sugar_beet": "Sugar beet",
      "Sugar Beet": "Sugar Beet",
      "Soya": "Soya",
      "Cotton": "Cotton",
      "Tomatoes": "Tomatoes",
      "Set the analysis": "Set the analysis",
      "The email is empty": "The email is empty",
      "The password is empty": "The password is empty",
      "The user is not active!": "The user is not active!",
      "The email is used": "The email is used",
      "Forgot password?": "Forgot password?",
      "Create new account": "Create new account",
      "Check your email inbox and follow the instructions to change the password.": "Check your email inbox and follow the instructions to change the password.",
      "Go back to Login": "Go back to Login",
      "The email doesn't exist in our database!": "The email doesn't exist in our database!",
      "Change Password": "Change Password",
      "Send Email": "Send Email",
      "Sending email...": "Sending email...",
      "The first password is empty": "The first password is empty",
      "The confirm password is empty": "The confirm password is empty",
      "The password don't match": "The password don't match",
      "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com",
      "Confirm Password": "Confirm Password",
      "Changing Password...": "Changing Password...",
      "The password has been changed with success. Now use the new password to login.": "The password has been changed with success. Now use the new password to login.",
      "In order to register you should accept the Privacy Policy": "In order to register you should accept the Privacy Policy",
      "In order to register you should provide your name": "In order to register you should provide your name",
      "In order to register you should provide a valid email": "In order to register you should provide a valid email",
      "The current email is used, please use another one!": "The current email is used, please use another one!",
      "Registration": "Registration",
      "Name": "Name",
      "I agree with the": "I agree with the",
      "Register": "Register",
      "Registration...": "Registration...",
      "Already have an account?": "Already have an account?",
      "Check your email inbox and follow the instructions to activate your account.": "Check your email inbox and follow the instructions to activate your account.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Do you want to activate your account?",
      "Activate my account": "Activate my account",
      "Activation...": "Activation...",
      "Your Account has been activated. Now You can login": "Your Account has been activated. Now You can login",
      "Welcome!": "Welcome!",
      "My profile": "My profile",
      "Settings": "Settings",
      "Support": "Support",
      "Logout": "Logout",
      "Farm": "Farm",
      "Field": "Field",
      "Field Notebook": "Field Notebook",
      "Agrometeo": "Agrometeo",
      "Wheater": "Wheater",
      "Climatic Index": "Climatic Index",
      "Satellite": "Satellite",
      "Prescription Map": "Prescription Map",
      "Sensors": "Sensors",
      "Summary": "Summary",
      "Analysis": "Analysis",
      "Forecast": "Forecast",
      "Anomaly Detection": "Anomaly Detection",
      "Comparation": "Comparation",
      "Allert": "Allert",
      "Bio Trap": "Bio Trap",
      "Agronomic tools": "Agronomic tools",
      "Nutrient Balance": "Nutrient Balance",
      "Soil Data": "Soil Data",
      "Irrigation": "Irrigation",
      "Pest Model": "Pest Model",
      "Artificial Intelligence": "Artificial Intelligence",
      "Digital Consulting": "Digital Consulting",
      "Crop Models & simulations": "Crop Models & simulations",
      "Soil Mapping & Carbon Credits": "Soil Mapping & Carbon Credits",
      "Organic vs Convenctional": "Organic vs Convenctional",
      "Connections & Data Sharing": "Connections & Data Sharing",
      "Upgrade your profile": "Upgrade your profile",
      "Register a new farmer": "Register a new farmer",
      "Farmer Company Name:": "Farmer Company Name:",
      "Farmer First Name:": "Farmer First Name:",
      "Farmer Surname:": "Farmer Surname:",
      "Farmer Country:": "Farmer Country:",
      "Farmer City:": "Farmer City:",
      "Farmer Adress:": "Farmer Adress:",
      "Farmer Email:": "Farmer Email:",
      "Farmer Phone Number:": "Farmer Phone Number:",
      "Create Farm": "Create Farm",
      "Go back": "Go back",
      "Restart Creation": "Restart Creation",
      "Creating the farm...": "Creating the farm...",
      "Farm Registered": "Farm Registered",
      "No farmer are registered": "No farmer are registered",
      "If you want to start using this application, you should start by registering a new farmer.": "If you want to start using this application, you should start by registering a new farmer.",
      "Farm Registred": "Farm Registred",
      "Farm Name": "Farm Name",
      "Surname": "Surname",
      "Email": "Email",
      "Phone Number": "Phone Number",
      "Edit": "Edit",
      "Delete": "Delete",
      "Are you sure to remove the following farmer?": "Are you sure to remove the following farmer?",
      "Name of the farmer:": "Name of the farmer:",
      "Surname of the farmer:": "Surname of the farmer:",
      "Country of the farmer:": "Country of the farmer:",
      "City of the farmer:": "City of the farmer:",
      "Adress of the farmer:": "Adress of the farmer:",
      "Email of the farmer:": "Email of the farmer:",
      "Phone Number of the farmer:": "Phone Number of the farmer:",
      "Delete Farmer": "Delete Farmer",
      "Invalid email address": "Invalid email address",
      "Invalid phone number": "Invalid phone number",
      "Add Farm": "Add Farm",
      "Farmer Registred": "Farmer Registred",
      "Farmer": "Farmer",
      "No registered farm": "No registered farm",
      "Loading": "Loading",
      "Update Farm": "Update Farm",
      "Updataing the farm...": "Updataing the farm...",
      "The Name of the farmer is empty": "The Name of the farmer is empty",
      "The Surname of the farmer is empty": "The Surname of the farmer is empty",
      "The CountryCode of the farmer is empty": "The CountryCode of the farmer is empty",
      "The City of the farmer is empty": "The City of the farmer is empty",
      "The Adress of the farmer is empty": "The Adress of the farmer is empty",
      "The Email of the farmer is empty": "The Email of the farmer is empty",
      "The Phone Number of the farmer is empty": "The Phone Number of the farmer is empty",
      "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com": "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com",
      "Create Field": "Create Field",
      "You don't have any farm registred, first you have to set at least one farmer": "You don't have any farm registred, first you have to set at least one farmer",
      "Field name:": "Field name:",
      "Crop Rotation:": "Crop Rotation:",
      "Crop Rotation": "Crop Rotation",
      "Perennial crop": "Perennial crop",
      "Two year crop rotation": "Two year crop rotation",
      "Three year crop rotation": "Three year crop rotation",
      "Actual Crop": "Actual Crop",
      "Alfalfa": "Alfalfa",
      "Barley": "Barley",
      "Maize": "Maize",
      "Durum Wheat": "Durum Wheat",
      "The next year Crop": "The next year Crop",
      "The crop in two years' time": "The crop in two years' time",
      "Organic": "Organic",
      "Convenctional": "Convenctional",
      "Draw the edge of your field:": "Draw the edge of your field:",
      "The field name is empty": "The field name is empty",
      "Please draw the edge of your field in the map": "Please draw the edge of your field in the map",
      "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com",
      "List of the field": "List of the field",
      "Field Name:": "Field Name:",
      "Field Detail": "Field Detail",
      "Crop:": "Crop:",
      "Agronomic Management:": "Agronomic Management:",
      "Are you sure to delete this field?": "Are you sure to delete this field?",
      "Delete Field": "Delete Field",
      "Field:": "Campo:",
      "Loading...": "Loading...",
      "Edit Field": "Edit Field",
      "Draw again the edge of your field:": "Draw again the edge of your field:",
      "In red there is the old field": "In red there is the old field",
      "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com",
      "Try Again": "Try Again",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Select the field to analyze",
      "View Satellite Image": "View Satellite Image",
      "Field Details": "Field Details",
      "Actual Crop:": "Actual Crop:",
      "Time series plot": "Time series plot",
      "Satellite Image": "Satellite Image",
      "Select one field": "Select one field",
      "Field Boundary": "Field Boundary",
      "Select the Vegetation Index": "Select the Vegetation Index",
      "Set the prescription map": "Set the prescription map",
      "Do you want to define the number of zone in Automatically way or Manual?": "Do you want to define the number of zone in Automatically way or Manual?",
      "Automatic": "Automatic",
      "Manual": "Manual",
      "If is set Manual, how many zones do you want to create?": "If is set Manual, how many zones do you want to create?",
      "Max fertilizer that you want to apply?": "Max fertilizer that you want to apply?",
      "Which strategy do you want to use?": "Which strategy do you want to use?",
      "Provide higher amout of fertilizer where the crop is stronger": "Provide higher amout of fertilizer where the crop is stronger",
      "Provide lower amout of fertilizer where the crop is stronger": "Provide lower amout of fertilizer where the crop is stronger",
      "Percentage of difference of fertilizer between zones": "Percentage of difference of fertilizer between zones",
      "Selected Percentage:": "Selected Percentage:",
      "Create": "Create",
      "Send to Jhon Deere Operation Center": "Send to Jhon Deere Operation Center",
      "Download": "Download",
      "Downloading...": "Downloading...",
      "Downloading": "Downloading",
      "Sending": "Sending",
      "Sending...": "Sending...",
      "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com": "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com",
      "Creating the field...": "Creating the field...",
      "Update Field": "Update Field",
      "Updating Field": "Updating Field",
      "Integration done": "Integration done",
      "You have done the integration with jhon deere": "You have done the integration with jhon deere",
      "Start the integration with the Operation Center Jhon Deere": "Start the integration with the Operation Center Jhon Deere",
      "A problem occured during the integration with Jhon Deere": "A problem occured during the integration with Jhon Deere",
      "Start the integration with the Operation Center John Deere": "Start the integration with the Operation Center John Deere",
      "Connect to Operation Center Jhon Deere": "Connect to Operation Center Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center",
      "Satellite Image analysis": "Satellite Image analysis",
      "Please enable the geolocation in order to get the wheater data and visualization": "Please enable the geolocation in order to get the wheater data and visualization",
      "Actual Wheater Data": "Actual Wheater Data",
      "Wheater Map": "Wheater Map",
      "Select the climate variable to see": "Select the climate variable to see",
      "Forecast Temperature": "Forecast Temperature",
      "Wind": "Wind",
      "Pressure": "Pressure",
      "Snow": "Snow",
      "Precipitation": "Precipitation",
      "Clouds": "Clouds",
      "Temperature": "Temperature",
      "Add / Modify / Delete": "Add / Modify / Delete",
      "Field Registred": "Field Registred",
      "Field Name": "Field Name",
      "Select the field to view": "Select the field to view",
      "Number of farmer": "Number of farmer",
      "Number of field": "Number of field",
      "Hectares Monitored": "Hectares Monitored",
      "Anomalies": "Anomalies",
      "IoT Number": "IoT Number",
      "Last Reding": "Last Reding",
      "Soil Temperature": "Soil Temperature",
      "Soil Humidity": "Soil Humidity",
      "Electrical Conductivity": "Electrical Conductivity",
      "Organic Carbon": "Organic Carbon",
      "Nitrogen": "Nitrogen",
      "Phosporous": "Phosporous",
      "Potassium": "Potassium",
      "Battery": "Battery",
      "Select the variable": "Select the variable",
      "Select the IoT Cabin": "Select the IoT Cabin",
      "View data": "View data",
      "Time series Graph": "Time series Graph",
      "Time Series Comparation": "Time Series Comparation",
      "Table": "Table",
      "Add Allert": "Add Allert",
      "Allert Registred": "Allert Registred",
      "Sensor": "Sensor",
      "Variable": "Variable",
      "Condition": "Condition",
      "Value": "Value",
      "No Allert Registrated": "No Allert Registrated",
      "No IoT is linked to your account": "No IoT is linked to your account",
      "No IoT allert is setted": "No IoT allert is setted",
      "Please select the IoT Cabin": "Please select the IoT Cabin",
      "Please select the variable": "Please select the variable",
      "Please select the logic to apply": "Please select the logic to apply",
      "Please set the threshold value": "Please set the threshold value",
      "Select the logic": "Select the logic",
      "Set the threshold value": "Set the threshold value",
      "Adding the allert...": "Adding the allert...",
      "Higher than": "Higher than",
      "Lower than": "Lower than",
      "IoT Position": "IoT Position",
      "Create Allert": "Create Allert",
      "Are you sure to remove this allert?": "Are you sure to remove this allert?",
      "Cabin Number:": "Cabin Number:",
      "Logic - Higher or Lower:": "Logic - Higher or Lower:",
      "Threshold Value:": "Threshold Value:",
      "Variabile": "Variabile",
      "Delete Allert": "Delete Allert",
      "Update Allert": "Update Allert",
      "Updating the allert...": "Updating the allert...",
      "Please the expected yield (q/ha)": "Please the expected yield (q/ha)",
      "Please the fertilizer title (%)": "Please the fertilizer title (%)",
      "Setting of the Calculation": "Setting of the Calculation",
      "Select the Nutrient": "Select the Nutrient",
      "Select the Crop": "Select the Crop",
      "Set the expected yield (q/ha)": "Set the expected yield (q/ha)",
      "Title of fertilizer - range 0-100 %": "Title of fertilizer - range 0-100 %",
      "Calculate": "Calculate",
      "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:": "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:",
      "Set the field to get the soil data": "Set the field to get the soil data",
      "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!": "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!",
      "The digital agronomist consultant is typing": "The digital agronomist consultant is typing",
      "Send a Message": "Send a Message",
      "Digital agronomist consultant": "Digital agronomist consultant",
      "You have done the integration with John Deere": "You have done the integration with John Deere",
      "Please fill all the requests": "Please fill all the requests",
      "Set the analysis parameter": "Set the analysis parameter",
      "Date of sowing": "Date of sowing",
      "Select the soil type": "Select the soil type",
      "Sand": "Sand",
      "Loamy Sand": "Loamy Sand",
      "Sandy Loam": "Sandy Loam",
      "Loam": "Loam",
      "Silty Loam": "Silty Loam",
      "Silt": "Silt",
      "Sandy Clay Loam": "Sandy Clay Loam",
      "Clay Loam": "Clay Loam",
      "Silty Clay Loam": "Silty Clay Loam",
      "Sandy Clay": "Sandy Clay",
      "Clay": "Clay",
      "Sunflower": "Sunflower",
      "Millet": "Millet",
      "Rice": "Rice",
      "Sugarbeet": "Sugarbeet",
      "Sorghum": "Sorghum",
      "Soybean": "Soybean",
      "Canola": "Canola",
      "Tomato": "Tomato",
      "Cabbage": "Cabbage",
      "Potato": "Potato",
      "Sugarcane": "Sugarcane",
      "Select the Variety": "Select the Variety",
      "Do you want to provide a fertilization?": "Do you want to provide a fertilization?",
      "Date of fertilization": "Date of fertilization",
      "Define the amount of Nitrogen provided (kg N / ha)": "Define the amount of Nitrogen provided (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Define the amount of Phosphorus provided (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Define the amount of Potassium provided (kg K / ha)",
      "Do you want to provide a irrigation": "Do you want to provide a irrigation",
      "Date of irrigation": "Date of irrigation",
      "Define the amount of water mm": "Define the amount of water mm",
      "Next": "Next",
      "Submit": "Submit",
      "Temporal creation of the yield": "Temporal creation of the yield",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Before you can run the model you should define the crop type. Go to the Field and update section the information",
      "Set the Field where apply the analysis": "Set the Field where apply the analysis",
      "Set the Farmer": "Set the Farmer",
      "Set the Field": "Set the Field",
      "Run The Analysis": "Run The Analysis",
      "NDVI Comparation": "NDVI Comparation",
      "SAR Comparation": "SAR Comparation",
      "Probabilistic Map": "Probabilistic Map",
      "% Convenctional": "% Convenctional",
      "% Organic": "% Organic",
      "The field was declared by the farmer as": "The field was declared by the farmer as",
      "Select the start year to analyze": "Select the start year to analyze",
      "Select the base temperature for the crop": "Select the base temperature for the crop",
      "Select the max temperature for the crop": "Select the max temperature for the crop",
      "Start analysis": "Start analysis",
      "Upgrade your account": "Upgrade your account",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO",
      "Not Now": "Not Now",
      "Upgrade your account to PRO": "Upgrade your account to PRO",
      "Your Acccount is PRO": "Your Acccount is PRO",
      "You can access to all the service": "You can access to all the service",
      "Your Acccount is not PRO": "Your Acccount is not PRO",
      "Purchase the PRO Service": "Purchase the PRO Service",
      "The total price is": "The total price is",
      "The payment was successful": "The payment was successful",
      "Now you should logout and login again to access all the features just unlocked": "Now you should logout and login again to access all the features just unlocked",
      "By purchasing this service you will access :": "By purchasing this service you will access :",
      "Prescription maps that can be integrated with your display tractors": "Prescription maps that can be integrated with your display tractors",
      "Visualization & Analysis of sensor data": "Visualization & Analysis of sensor data",
      "Set allert": "Set allert",
      "All the agronomic tools": "All the agronomic tools",
      "AI Tools": "AI Tools",
      "Synchronization with Operation Center Jhon Deere": "Synchronization with Operation Center Jhon Deere",
      "Durum_wheat": "Durum Wheat",
      "Vineyards": "Vineyards",
      "perennial": "Perennial",
      "biennial": "Biennal",
      "triennial": "Triennial",
      "Kiwi": "Kiwi",
      "Garlic": "Garlic",
      "Apricot": "Apricot",
      "Orange": "Orange",
      "Asparagus": "Asparagus",
      "Oats": "Oats",
      "Basil": "Basil",
      "Swiss_chard": "Swiss chard",
      "Beet_leaves": "Beet leaves",
      "Broccoli": "Broccoli",
      "Hemp": "Hemp",
      "Artichoke": "Artichoke",
      "Carrot": "Carrot",
      "Cauliflower": "Cauliflower",
      "chickpea": "Chickpea",
      "cucumber": "Cucumber",
      "Chicory": "Chicory",
      "Onion": "Onion",
      "Endive": "Endive",
      "Watermelon": "Watermelon",
      "Rapeseed": "Rapeseed",
      "Green_bean_for_industry": "Green bean for industry",
      "Fresh_green_bean": "Fresh green bean",
      "Bean": "Bean",
      "Dry_bean": "Dry bean",
      "Spelt": "Spelt",
      "Grain_fava_bean": "Grain fava bean",
      "Favino": "Favino",
      "Fico": "Fig",
      "Fennel": "Fennel",
      "Strawberry": "Strawberry",
      "winter_wheat": "Winter wheat",
      "Kaki": "Persimmon",
      "Lettuce": "Lettuce",
      "Lentil": "Lentil",
      "Lemon": "Lemon",
      "Linen": "Linen",
      "Lupin": "Lupin",
      "Corn": "Corn",
      "Tangerine": "Tangerine",
      "Almond": "Almond",
      "Eggplant": "Eggplant",
      "Melon": "Melon",
      "Blueberry": "Blueberry",
      "Nectarines": "Nectarines",
      "Kernel": "Kernel",
      "Walnut": "Walnut",
      "Bell_pepper": "Bell pepper",
      "Pear": "Pear",
      "Peach": "Peach",
      "Pea": "Pea",
      "Leek": "Leek",
      "Parsley": "Parsley",
      "Radicchio": "Radicchio",
      "Turnip": "Turnip",
      "Radish": "Radish",
      "Ribes": "Currant",
      "Shallots": "Shallots",
      "Celery": "Celery",
      "Soy": "Soy",
      "Spinach": "Spinach",
      "Plum_tree": "Plum tree",
      "Tabacco": "Tobacco",
      "Vineyard": "Vineyard",
      "Savoy_cabbage": "Savoy cabbage",
      "Pumpkin": "Pumpkin",
      "Zucchini_for_industry": "Zucchini for industry",
      "Fresh_zucchini": "Fresh zucchini",
      "Peanuts": "Peanuts",
      "Chard": "Swiss chard",
      "Leaf_beet": "Beet leaves",
      "Hemp_for_seeds": "Hemp (seeds)",
      "Hemp_for_fiber": "Hemp (fiber)",
      "Cardoon": "Cardoon",
      "Chestnut": "Chestnut",
      "Cherry_tree": "Cherry tree",
      "French_bean": "Fresh green bean",
      "Fava_bean": "Dry bean",
      "Wheat": "Wheat",
      "Raspberry": "Raspberry",
      "Flax": "Linen",
      "Ley_grass": "Lupin",
      "Corn_for_grain": "Eggplant",
      "Hazelnut": "Hazelnut",
      "Pear_tree": "Pear tree",
      "Peach_tree": "Peach tree",
      "Protein_pea": "Protein pea",
      "Processing_tomato": "Processing tomato",
      "Table_tomato": "Table tomato",
      "Table_grape": "Table grape",
      "Gooseberry": "Gooseberry",
      "Grapevine": "Grapevine",
      "Clover": "Clover",
      "Corn_salad": "Corn salad",
      "Grapevine_for_wine_grapes": "Grapevine for wine grapes",
      "Sweet_corn": "Sweet corn",
      "Silage_corn": "Silage corn",
      "Oilseed_rape": "Oilseed rape",
      "Field-grown_bell_pepper": "Field-grown bell pepper",
      "Pea_for_industry": "Pea for industry",
      "Fresh_pea": "Fresh pea",
      "Greenhouse_table_tomato": "Greenhouse table tomato",
      "Clover_grassland": "Clover grassland",
      "Hill_pastures": "Hill pastures",
      "Mixed_grassland_>50_legumes": "Mixed grassland >50% legumes",
      "Artificial_mixed_hill_pastures": "Artificial mixed hill pastures",
      "Stable_plains": "Stable plains",
      "Silage_fodder": "Silage fodder",
      "Arugula_1st_cut": "Arugula, 1st cut",
      "Arugula_2nd_cut": "Arugula, 2nd cut",
      "Shallot": "Shallot",
      "Arugula_in_open_field": "Arugula in open field",
      "Phosphorus": "Phosphorus",
      "Organic vs Conventional:": "Organic vs Conventional:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Are you sure to edit the polygon? if yes press again the Update Field button",
      "Set the Year of analysis": "Set the Year of analysis",
      "Set the nutrient to visualize": "Set the nutrient to visualize",
      "Carbonates": "Carbonates",
      "Soil Electrical Conductivity": "Soil Electrical Conductivity",
      "pH": "pH",
      "Please set the year": "Please set the year",
      "Please set the nutrient": "Please set the nutrient",
      "Please set the farmer": "Please set the farmer",
      "Please set the field": "Please set the field",
      "The map is not ready yet": "The map is not ready yet",
      "Please select the object of your request": "Please select the object of your request",
      "Please report a valid message": "Please report a valid message",
      "Send us your message": "Send us your message",
      "Object of the request:": "Object of the request:",
      "Object:": "Object:",
      "Support Request": "Support Request",
      "Upgrade account": "Upgrade account",
      "Problem with the application": "Problem with the application",
      "Other": "Other",
      "Message of the request:": "Message of the request:",
      "Message:": "Message:",
      "Send message": "Send message",
      "My account": "My account",
      "Do you want to upgrade your account?": "Do you want to upgrade your account?",
      "Send us a message": "Send us a message",
      "Your Message have been sent with success": "Your Message have been sent with success",
      "As soon as possible one of our operator will check your request and answer you": "As soon as possible one of our operator will check your request and answer you",
      "Frumento_tenero": "Winter Wheat",
      "Winter Wheat": "Winter Wheat",
      "Annual percentage increase": "Annual percentage increase",
      "Tons of CO2 Sequestered": "Tons of CO2 Sequestered",
      "Carbon Credits Generated": "Carbon Credits Generated",
      "Soil Map": "Soil Map",
      "Temporal Variation": "Temporal Variation",
      "If you want to access to this module data, you must upgrade your account to PRO": "If you want to access to this module data, you must upgrade your account to PRO",
      "Run Model": "Run Model",
      "Prescription AI Map": "Prescription AI Map",
      "Please set the expeted yield": "Please set the expeted yield",
      "Please set the number of the application of fertilizer during the year": "Please set the number of the application of fertilizer during the year",
      "AI Section": "AI Section",
      "Which crop?": "Which crop?",
      "Which nutrient?": "Which nutrient?",
      "Expected yield quintals per hectares?": "Expected yield quintals per hectares?",
      "Percentage of title of the fertilizer": "Percentage of title of the fertilizer",
      "Number of the application during the year": "Number of the application during the year",
      "Create a farm": "Create a farm",
      "Create a field": "Create a field",
      "Delete Account": "Delete Account",
      "Are you sure to delete your account?": "Are you sure to delete your account?",
      "Are you sure to delete the account?": "Are you sure to delete the account?",
      "Please be carefull cause if you delete your account all the data related will be removed": "Please be carefull cause if you delete your account all the data related will be removed",
      "If you want to proceed, please report your email ": "If you want to proceed, please report your email ",
      " in the following form input": " in the following form input",
      "Deleting the user...": "Deleting the user...",
      "Delete account": "Delete account",
      "In order to register you should accept the Terms And Conditions": "In order to register you should accept the Terms And Conditions",
      "Account Level:": "Account Level:",
      "Farmer company name:": "Farmer company name:",
      "Your account is basic": "Your account is basic",
      "Close": "Close",
      "Select the Farm": "Select the Farm",
      "No Sensors Are Linked to Your Account": "No Sensors Are Linked to Your Account",
      "Table of the Soil Data": "Table of the Soil Data",
      "Depth start (cm)": "Depth start (cm)",
      "Depth end (cm)": "Depth end (cm)",
      "Mean Bulk density (cg/cm3)": "Mean Bulk density (cg/cm3)",
      "Uncertainty Bulk density (cg/cm3)": "Uncertainty Bulk density (cg/cm3)",
      "Mean Cation Exchange Capacity (mmol/kg)": "Mean Cation Exchange Capacity (mmol/kg)",
      "Uncertainty Cation Exchange Capacity (mmol/kg)": "Uncertainty Cation Exchange Capacity (mmol/kg)",
      "Mean Clay (g/kg)": "Mean Clay (g/kg)",
      "Uncertainty Clay (g/kg)": "Uncertainty Clay (g/kg)",
      "Mean Nitrogen (cg/kg)": "Mean Nitrogen (cg/kg)",
      "Uncertainty Nitrogen (cg/kg)": "Uncertainty Nitrogen (cg/kg)",
      "Mean pH": "Mean pH",
      "Uncertainty pH": "Uncertainty pH",
      "Mean Sand (g/kg)": "Mean Sand (g/kg)",
      "Uncertainty Sand (g/kg)": "Uncertainty Sand (g/kg)",
      "Mean Silt (g/kg)": "Mean Silt (g/kg)",
      "Uncertainty Silt (g/kg)": "Uncertainty Silt (g/kg)",
      "Mean SOC (g/kg)": "Mean SOC (g/kg)",
      "Uncertainty SOC (g/kg)": "Uncertainty SOC (g/kg)",
      "Operators": "Operators",
      "Warehouse": "Warehouse",
      "Field Notes": "Field Notes",
      "Yield": "Yield",
      "Seed": "Seed",
      "Fertilizers": "Fertilizers",
      "Pesticide": "Pesticide",
      "Field Activities": "Field Activities",
      "Operator Cost": "Operator Cost",
      "Revenue": "Revenue",
      "Economy": "Economy",
      "Operators Registred": "Operators Registred",
      "Create Operator": "Create Operator",
      "Last Name": "Last Name",
      "Operator Name:": "Operator Name:",
      "Operator Last Name:": "Operator Last Name:",
      "Operator Email:": "Hello",
      "Operator Phone:": "Operator Phone:",
      "Are you sure to delete this operator?": "Are you sure to delete this operator?",
      "Delete Operator": "Delete Operator",
      "Deleting the Operator...": "Deleting the Operator...",
      "Creating the operator...": "Creating the operator...",
      "Update Operator": "Update Operator",
      "Updating Operator...": "Updating Operator...",
      "The name is empty": "The name is empty",
      "The last name is empty": "The last name is empty",
      "The phone is empty": "The phone is empty",
      "You must select a farm to associate to the operators": "You must select a farm to associate to the operators",
      "The phone number isn't correct": "The phone number isn't correct",
      "Create Warehouse": "Create Warehouse",
      "Warehouse Name": "Warehouse Name",
      "Warehouses Registred": "Warehouses Registred",
      "Warehouse Map": "Warehouse Map",
      "Warehouse Name:": "Warehouse Name:",
      "Select warehouse location:": "Select warehouse location:",
      "Search City:": "Search City:",
      "Creating the Warehouse...": "Creating the Warehouse...",
      "Please report the position of the warehouse": "Please report the position of the warehouse",
      "Please insert the name of the warehouse": "Please insert the name of the warehouse",
      "Please insert the farm to relate to the warehouse": "Please insert the farm to relate to the warehouse",
      "Edit Warehouse": "Edit Warehouse",
      "The red point is the old location of the warehouse": "The red point is the old location of the warehouse",
      "Editing the Warehouse...": "Editing the Warehouse...",
      "Are you sure to delete the warehouse?": "Are you sure to delete the warehouse?",
      "Latitude:": "Latitude:",
      "Longitude:": "Longitude:",
      "Warehouse Farm:": "Warehouse Farm:",
      "Delete Warehouse": "Delete Warehouse",
      "Deleting the Warehouse...": "Deleting the Warehouse...",
      "Create Field Note": "Create Field Note",
      "Field Note Registred": "Field Note Registred",
      "Date": "Date",
      "Title of the note": "Title of the note",
      "Field Note Title:": "Field Note Title:",
      "No Field Note Are Registred": "No Field Note Are Registred",
      "No Field exist for your account": "No Field exist for your account",
      "If you want to register an Field Note you should create first an Operator": "If you want to register an Field Note you should create first an Operator",
      "Select field note location:": "Select field note location:",
      "Warehouse location": "Warehouse location",
      "Type of Note": "Type of Note",
      "Generic": "Generic",
      "Pathogens": "Pathogens",
      "Insects": "Insects",
      "Weed": "Weed",
      "Waterlogging": "Waterlogging",
      "Generic Damage": "Generic Damage",
      "Field Survey": "Field Survey",
      "Crop Status": "Crop Status",
      "Description": "Description",
      "Operator": "Operator",
      "Creating the Field Note...": "Creating the Field Note...",
      "Please insert the title of the field note": "Please insert the title of the field note",
      "Please set the farm related to the field note": "Please set the farm related to the field note",
      "Please set the field related to the field note": "Please set the field related to the field note",
      "Please insert the type of the field note": "Please insert the type of the field note",
      "Please insert the date of the field note": "Please insert the date of the field note",
      "Please insert the operator that create the field note": "Please insert the operator that create the field note",
      "type": "type",
      "Title": "Title",
      "Field Note Map": "Field Note Map",
      "Edit Field Note": "Edit Field Note",
      "In red there is the old Field Note": "In red there is the old Field Note",
      "Old Field Note": "Old Field Note",
      "Editing the Field Note...": "Editing the Field Note...",
      "Please report the position of the field note": "Please report the position of the field note",
      "Field Note Date:": "Field Note Date:",
      "Field Note Farm:": "Field Note Farm:",
      "Field Note Field:": "Field Note Field:",
      "Field Note Operator:": "Field Note Operator:",
      "Field Note Type:": "Field Note Type:",
      "Field Note Title:')}": "Field Note Title:')}",
      "Field Note Description:": "Field Note Description:",
      "Delete Field Note": "Delete Field Note",
      "Deleting the Field Note...": "Deleting the Field Note...",
      "Are you sure to delete this field note?": "Are you sure to delete this field note?",
      "Field Note Data": "Field Note Data",
      "Create Yield data": "Create Yield data",
      "Yield Data Registred": "Yield Data Registred",
      "Harvest Date": "Harvest Date",
      "Crop": "Coltura",
      "Total Quantity": "Total Quantity",
      "Price": "Price",
      "Total Cost": "Total Cost",
      "No Yield data are registred": "No Yield data are registred",
      "No Operator exist for your account": "No Operator exist for your account",
      "If you want to register an Yield you should create first a Operator": "If you want to register an Yield you should create first a Operator",
      "If you want to register an Yield you should create first a Field": "If you want to register an Yield you should create first a Field",
      "Please select the farm": "Please select the farm",
      "Please select the field": "Please select the field",
      "Please select the operator": "Please select the operator",
      "Please set the harvest date": "Please set the harvest date",
      "Please set the total yield": "Please set the total yield",
      "Please set the price of the yield": "Please set the price of the yield",
      "Please set the cost of the operation": "Please set the cost of the operation",
      "Register a Yield": "Register a Yield",
      "The crop is: ": "The crop is: ",
      "Total Yield quintals": "Total Yield quintals",
      "Price selled euro per quintal": "Price selled euro per quintal",
      "Did you use a subcontractors?": "Did you use a subcontractors?",
      "Subcontractor Name": "Subcontractor Name",
      "Total cost of the harvest operation": "Total cost of the harvest operation",
      "Create Yield": "Create Yield",
      "Creating the Yield...": "Creating the Yield...",
      "Update Yield": "Update Yield",
      "Updating the Yield...": "Updating the Yield...",
      "Are you sure to delete this yield data?": "Are you sure to delete this yield data?",
      "Delete Yield": "Delete Yield",
      "Deleting the Yield...": "Deleting the Yield...",
      "Create Seed": "Create Seed",
      "Seed Registred": "Seed Registred",
      "Macro Type": "Macro Type",
      "Type": "Type",
      "Commercial Product Name": "Commercial Product Name",
      "Organic Approved": "Organic Approved",
      "Duplicate": "Duplicate",
      "Please select the unit doses": "Please select the unit doses",
      "Please set the name of the seed": "Please set the name of the seed",
      "Please select the type of the seed": "Please select the type of the seed",
      "Please set the productor name": "Please set the productor name",
      "Please set the crop name": "Please set the crop name",
      "Commercial Product Name:": "Commercial Product Name:",
      "Product Type:": "Product Type:",
      "Unit Dose:": "Unit Dose:",
      "Can be used in organic farming production?": "Can be used in organic farming production?",
      "Day Growth Cycle": "Day Growth Cycle",
      "FAO Class": "FAO Class",
      "Productor": "Productor",
      "Creating the Seed...": "Creating the Seed...",
      "Are you sure to delete this seed?": "Are you sure to delete this seed?",
      "Macro Type:": "Macro Type:",
      "Is organic product approved": "Is organic product approved",
      "Unit dose": "Unit dose",
      "Delete seed": "Delete seed",
      "Deleting the seed...": "Deleting the seed...",
      "Duplicate Seed": "Duplicate Seed",
      "Edit Seed": "Edit Seed",
      "Editing the Seed...": "Editing the Seed...",
      "Create Fertilizer": "Create Fertilizer",
      "Fertilizer Registred": "Fertilizer Registred",
      "Please set the name of the fertilizer": "Please set the name of the fertilizer",
      "Please set the type of fertilizer": "Please set the type of fertilizer",
      "Please set the nitrogen": "Please set the nitrogen",
      "Please set the potassium": "Please set the potassium",
      "Please set the phosphorus": "Please set the phosphorus",
      "Nitrogen Percentange": "Nitrogen Percentange",
      "Phosphorus Percentange": "Phosphorus Percentange",
      "Potassium Percentange": "Potassium Percentange",
      "Are you sure to delete this fertilizer?": "Are you sure to delete this fertilizer?",
      "Delete Fertilizer": "Delete Fertilizer",
      "Deleting the Fertilizer...": "Deleting the Fertilizer...",
      "Creating the Fertilizer...": "Creating the Fertilizer...",
      "Duplicate Fertilizer": "Duplicate Fertilizer",
      "Edit Fertilizer": "Edit Fertilizer",
      "Editing the Fertilizer...": "Editing the Fertilizer...",
      "Create Product": "Create Product",
      "Product Registred": "Product Registred",
      "Register Number": "Register Number",
      "Registration Date": "Registration Date",
      "Product Formulation:": "Product Formulation:",
      "Active substances:": "Active substances:",
      "Content per 100 grams": "Content per 100 grams",
      "Danger Indications": "Danger Indications",
      "Phenological Stage": "Phenological Stage",
      "Scientific name adversity": "Scientific name adversity",
      "Common name adversity": "Common name adversity",
      "Maximum dose": "Maximum dose",
      "Minimum dose": "Minimum dose",
      "Shortage time": "Shortage time",
      "Return time": "Return time",
      "Minimum interval between treatments": "Minimum interval between treatments",
      "Maximum interval between treatments": "Maximum interval between treatments",
      "Maximum number of treatments": "Maximum number of treatments",
      "Unit for maximum number of treatment": "Unit for maximum number of treatment",
      "Max Volume of water": "Max Volume of water",
      "Min Volume of water": "Min Volume of water",
      "Creating the Product...": "Creating the Product...",
      "Are you sure to delete this product?": "Are you sure to delete this product?",
      "Organic Product": "Organic Product",
      "Delete Product": "Delete Product",
      "Deleting the Product...": "Deleting the Product...",
      "Duplicate Product": "Duplicate Product",
      "Edit Product": "Edit Product",
      "Editing the Product...": "Editing the Product...",
      "Cost": "Cost",
      "Create Field Activities": "Create Field Activities",
      "Type of Field Activity": "Type of Field Activity",
      "Tillage": "Tillage",
      "Sowing / Planting": "Sowing / Planting",
      "Defence": "Defence",
      "Tillage Type": "Tillage Type",
      "Plowing": "Plowing",
      "Harrowing": "Harrowing",
      "Hoeing": "Hoeing",
      "Weeding": "Weeding",
      "Clod breaking": "Clod breaking",
      "Milling": "Milling",
      "Ripping": "Ripping",
      "Rolling": "Rolling",
      "Compaction": "Compaction",
      "Shredding": "Shredding",
      "Tillage Depht cm": "Tillage Depht cm",
      "You didn't create a seed object": "You didn't create a seed object",
      "Sowing Dose kg / ha": "Sowing Dose kg / ha",
      "Irrigation Volume m3/ha": "Irrigation Volume m3/ha",
      "You didn't create a Fertilizer object": "You didn't create a Fertilizer object",
      "Create a fertilizer": "Create a fertilizer",
      "Fertilization Product": "Fertilization Product",
      "Fertilization Dose kg/ha": "Fertilization Dose kg/ha",
      "You didn't create a Defence object": "You didn't create a Defence object",
      "Create a Defence Product": "Create a Defence Product",
      "Defence Product": "Defence Product",
      "Defence Type": "Defence Type",
      "Dose Used": "Dose Used",
      "Volume Water Used": "Volume Water Used",
      "Level of infestaction": "Level of infestaction",
      "Low": "Low",
      "Medium": "Medium",
      "High": "High",
      "Authorization Technichian?": "Authorization Technichian?",
      "Respect strip": "Respect strip",
      "Exceeding the intervention threshold": "Exceeding the intervention threshold",
      "It is done?": "It is done?",
      "Crop Phenology": "Crop Phenology",
      "Hour of work": "Hour of work",
      "Variety": "Variety",
      "Subcontractors?": "Subcontractors?",
      "Wheater Class": "Wheater Class",
      "Temperature °C": "Temperature °C",
      "Wind Speed km / h": "Wind Speed km / h",
      "Humidity %": "Humidity %",
      "Rainfall mm": "Rainfall mm",
      "Create Field Activity": "Create Field Activity",
      "Creating the Field Activity...": "Creating the Field Activity...",
      "Are you sure to delete this field activity?": "Are you sure to delete this field activity?",
      "Delete Field Activity": "Delete Field Activity",
      "Deleting the Field Activity...": "Deleting the Field Activity...",
      "Edit Field Activities": "Edit Field Activities",
      "Edit Field Activity": "Edit Field Activity",
      "Editing the Field Activity...": "Editing the Field Activity...",
      "Date of Hire": "Date of Hire",
      "Operator Cost Registered": "Operator Cost Registered",
      "Create Operator Cost": "Create Operator Cost",
      "No Operator are available for your account.": "No Operator are available for your account.",
      "First you have to create an operator": "First you have to create an operator",
      "No Farm are available for your account.": "No Farm are available for your account.",
      "First you have to create an Farm": "First you have to create an Farm",
      "Please set the year operotor cost": "Please set the year operotor cost",
      "Create a Operator Cost": "Create a Operator Cost",
      "Operator year salary": "Operator year salary",
      "Creating the Operator Cost...": "Creating the Operator Cost...",
      "Are you sure to delete this operator cost": "Are you sure to delete this operator cost",
      "Operator Cost Detail": "Operator Cost Detail",
      "Delete Operator Cost": "Delete Operator Cost",
      "Deleting the Operator Cost...": "Deleting the Operator Cost...",
      "Duplicate a Operator Cost": "Duplicate a Operator Cost",
      "Duplicate Operator Cost": "Duplicate Operator Cost",
      "Duplicating the Operator Cost...": "Duplicating the Operator Cost...",
      "Edit a Operator Cost": "Edit a Operator Cost",
      "Edit Operator Cost": "Edit Operator Cost",
      "Editing the Operator Cost...": "Editing the Operator Cost...",
      "Create Revenue": "Create Revenue",
      "Revenue Registered": "Revenue Registered",
      "Total Revenue": "Total Revenue",
      "Please set the total revenue": "Please set the total revenue",
      "Please set the type of the revenue": "Please set the type of the revenue",
      "Register a Revenue": "Register a Revenue",
      "Select the type of revenue:": "Select the type of revenue:",
      "Direct Payments": "Direct Payments",
      "pac": "Direct Payments",
      "indinizzi": "Agricultural Compensation",
      "Agricultural compensation": "Agricultural compensation",
      "Creating the Revenue...": "Creating the Revenue...",
      "Are you sure to delete this revenue?": "Are you sure to delete this revenue?",
      "Delete Revenue": "Delete Revenue",
      "Deleting the Revenue...": "Deleting the Revenue...",
      "Duplicate a Revenue": "Duplicate a Revenue",
      "Duplicate Revenue": "Duplicate Revenue",
      "Duplicating the Revenue...": "Duplicating the Revenue...",
      "Edit a Revenue": "Edit a Revenue",
      "Edit Revenue": "Edit Revenue",
      "Editing the Revenue...": "Editing the Revenue...",
      "Farmer Economy Analysis": "Farmer Economy Analysis",
      "Farm Profit": "Farm Profit",
      "Farm  Revenue": "Farm  Revenue",
      "Farm Total Cost": "Farm Total Cost",
      "Economy Data": "Economy Data",
      "Activity": "Activity",
      "Cost/Price": "Cost/Price",
      "Quantity/Hours": "Quantity/Hours",
      "Cost allocation": "Cost allocation",
      "Allocation of Revenues": "Allocation of Revenues",
      "Revenues": "Revenues",
      "Monthly Costs and Revenues": "Monthly Costs and Revenues",
      "Cash Flow": "Cash Flow",
      "Air Temperature": "Air Temperature",
      "Atmospheric Pressure": "Atmospheric Pressure",
      "Rainfall": "Rainfall",
      "Air Humidity": "Air Humidity",
      "Evapotraspiration": "Evapotraspiration",
      "Degree Day": "Degree Day",
      "TWH Index": "TWH Index",
      "Dew Point": "Dew Point",
      "Gust": "Gust",
      "Real time data or Historic data?": "Real time data or Historic data?",
      "Last 24 Hours Data": "Last 24 Hours Data",
      "Degree Days": "Degree Days",
      "Leaf Wetness": "Leaf Wetness",
      "Irrigation Analysis": "Irrigation Analysis",
      "Download Operator - Format Excel": "Download Operator - Format Excel",
      "Download Warehouse - Format Excel": "Download Warehouse - Format Excel",
      "Download Field Note - Format Excel": "Download Field Note - Format Excel",
      "Download Yield - Format Excel": "Download Yield - Format Excel",
      "Download Field Activity - Format Excel": "Download Field Activity - Format Excel",
      "Download Other Revenue - Format Excel": "Download Other Revenue - Format Excel",
      "Download Operator Cost - Format Excel": "Download Operator Cost - Format Excel",
      "generic": "Generic",
      "phatogens": "Phatogens",
      "insect": "Insect",
      "weed": "Weed",
      "waterlogging": "Waterlogging",
      "genericdamage": "Generic Damage",
      "fieldsurvey": "Field Survey",
      "cropstatus": "Crop Status",
      "acaricida": "Acaricide",
      "aficida": "Aphicide",
      "antideriva": "Anti-drift",
      "antidoto": "Antidote",
      "antigermogliante": "Anti-germinating",
      "antiriscaldo": "Anti-heating",
      "attrattivo": "Attractive",
      "bagnante": "Wetter",
      "coaudiuvante": "Adjuvant",
      "diradante": "Thinning",
      "feromone": "Pheromone",
      "fitoregolatore": "Phytoregulator",
      "geodisinfestante": "Geodisinfestant",
      "repellente": "Repellent",
      "sinergizzante": "Synergist",
      "adiuvante": "Adjuvant",
      "fungicida": "Fungicide",
      "diserbante": "Herbicide",
      "insetticida": "Insecticide",
      "molluschicida": "Molluscicide",
      "organic_product": "Organic Product",
      "nematocida": "Nematicide",
      "sostanza_di_crescita": "Substance of growth",
      "biostimolante": "Biostimulant",
      "ritirato": "Retrieved",
      "attivo": "Active",
      "kg_ha": "kg/ha",
      "l_ha": "l/ha",
      "unita_ha": "Unit/ha",
      "anno": "Year",
      "ciclo": "Cylce",
      "stagione": "Season",
      "semente": "Seed",
      "piantine": "Seedlings",
      "libre_acro": "Libre Acre",
      "minerale": "Mineral",
      "organico": "Organic",
      "tillage": "Tillage",
      "sowing": "Sowing",
      "defence": "Defence",
      "irrigation": "Irrigation",
      "nutrition": "Nutrition",
      "sereno": "Clear",
      "nuvoloso": "Cloudy",
      "variabile": "Variable",
      "molto_nuvoloso": "Very cloudy",
      "coperto": "Covered",
      "nebbia": "Fog",
      "coperto_nebbia": "Covered fog",
      "variabile_con_rovesci": "Variable with rain",
      "temporale": "Thunderstorm",
      "coperto_con_neve": "Covered with snow",
      "pioggia_misto_neve": "Rain mixed snow",
      "erbacee_botticella": "Herbaceous small barrel",
      "erbacee_fioritura": "Herbaceous flowering",
      "erbacee_invaiatura": "Herbaceous veraison",
      "erbacee_allegagione": "Herbaceous fruit set",
      "erbacee_ingrossamento": "Herbaceous swelling",
      "erbacee_germinazione": "Herbaceous germination",
      "erbacee_emergenza_infioriscenza": "Herbaceous inflorescence emergence",
      "erbacee_sviluppo_fogliare": "Herbaceous leaf development",
      "erbacee_maturazione": "Herbaceous maturation",
      "erbacee_senescenza": "Herbaceous senescence",
      "erbacee_levata": "Herbaceous stem elongation",
      "erbacee_accestimento": "Herbaceous tillering",
      "arboree_gemme_inverno": "Arboreal winter buds",
      "arboree_apertura_gemme": "Arboreal bud break",
      "arboree_ripresa_vegetativa": "Arboreal vegetative growth resumption",
      "arboree_sviluppo_germogli": "Arboreal shoot development",
      "arboree_foglie_distese": "Arboreal unfolded leaves",
      "arboree_grappoli_visibili": "Arboreal visible clusters",
      "arboree_grappoli_separati": "Arboreal separated clusters",
      "arboree_ingrossamento_frutti": "Arboreal fruit swelling",
      "arboree_pre_chiusura_grappolo": "Arboreal pre-cluster closure",
      "arboree_chiusura_grappolo": "Arboreal cluster closure",
      "arboree_indurimento_nocciolo": "Arboreal stone hardening",
      "arboree_invaitura": "Arboreal veraison",
      "arboree_riposo_vegetativo": "Arboreal vegetative dormancy",
      "arboree_fioritura": "Arboreal flowering",
      "arboree_allegagione": "Arboreal fruit set",
      "arboree_pianta_ferma": "Arboreal plant at rest",
      "arboree_germogliamento": "Arboreal sprouting",
      "arboree_germogli_5_10_cm": "Arboreal 5-10 cm shoots",
      "arboree_comparsa_boccioli_floreali": "Arboreal flower bud emergence",
      "arboree_bottone_bianco": "Arboreal white button",
      "arboree_crescita": "Arboreal growth",
      "arboree_semi_neri": "Arboreal black seeds",
      "arboree_pre_raccolta": "Arboreal pre-harvest",
      "arboree_raccolta": "Arboreal harvest",
      "basso": "Low",
      "medio": "Medium",
      "alto": "High",
      "aratura": "Plowing",
      "erpicatura": "Harrowing",
      "zappatura": "Hoeing",
      "sarchiatura": "Weeding",
      "frangizollatura": "Fringing",
      "fresatura": "Milling",
      "rippatura": "Ripping",
      "rullatura": "Rolling",
      "compattazione": "Compaction",
      "pacciamatura": "Mulching",
      "trinciatura": "Shredding",
      "Seedlings": "Seedlings",
      "Pounds per acre": "Pounds per acre",
      "Mineral Product": "Mineral Product",
      "Nutrition": "Nutrition",
      "Historic Data": "Historic Data",
      "Withdrawn": "Withdrawn",
      "Active": "Active",
      "Year": "Year",
      "Cycle": "Ctcle",
      "Season": "Season",
      "Defense": "Defense",
      "Active or Retrivied:": "Active or Retrivied",
      "Please fill all the field": "Please fill all the field",
      "Please fill all the form to create a field activities": "Please fill all the form to create a field activities",
      "Other Revenue": "Other Revenue",
      "Harvest": "Harvest",
      "Please insert the date of the revenue": "Please insert the date of the revenue",
      "Please enter the start date of the operator cost": "Please enter the start date of the operator cost",
      "Pear_fruit": "Pear",
      "Dry_pulses": "Dry pulses",
      "Strawberries": "Strawberries",
      "Tobacco": "Tobacco",
      "Other_fibre_and_oleaginous_crops": "Other fibre and oleaginous crops",
      "Other_fresh_vegetables": "Other fresh vegetables",
      "Your Account is not PRO": "Your Account is not PRO",
      "Upgrade to PRO": "Upgrade to PRO",
      "Something Went Wrong During the Payment": "Something Went Wrong During the Payment",
      "Please send us an email to info@automaticfarmsolution.com": "Please send us an email to info@automaticfarmsolution.com",
      "An operator will answear you as soon as possible": "An operator will answear you as soon as possible",
      "VAT Excluded": "VAT Excluded",
      "The total price is 832 € (VAT Included)": "The total price is 832 € (VAT Included)",
      "All the modules of free version": "All the modules of free version",
      "Generation of prescription map with Artificial Intelligence": "Generation of prescription map with Artificial Intelligence",
      "Capability to connect IoT Sensors": "Capability to connect IoT Sensors",
      "Set automatic alerts": "Set automatic alerts",
      "Artificial Intelligence Digital Soil Mapping": "Artificial Intelligence Digital Soil Mapping",
      "Advanced Report & Documentation": "Advanced Report & Documentation",
      "Online Live Training of the Platform": "Online Live Training of the Platform",
      "Number of Plant":"Number of Plant",
      "Number of note":"Number of note",
      "Note":"Note",
      "Add Note":"Add Note",
      "Download Note":"Download Note",
      "List of the note":"List of the note",
      "User":"User",
      "Plant":"Plant",
      "Image":"Image",
      "Created at":"Created at",
      "Detail":"Detail",
      "No Note are Registrated":"No Note are Registrated",
      "Add Note":"Add Note",
      "Plant Number":"Plant Number",
      "Upload or Capture Image":"Upload or Capture Image",
      "Save":"Save",
      "Detail Note - ":"Detail Note - ",
      "User - ":"User - ",
      "Plant - ":"Plant - ",
      "Note - ":"Note - ",
      "Image":"Image",
      "Created at - ":"Created at - ",
      "Are you sure to delete the note?":"Are you sure to delete the note?",
      "Update Note":"Update Note",
      "New registration":"New registration",
      "You don't have yet the permission to use this application":"You don't have yet the permission to use this application",
      "Please use the following button to send a request to the wine manager to be activated":"Please use the following button to send a request to the wine manager to be activated",
      "Send the request":"Send the request",
      "The plant cannot be empty":"The plant cannot be empty",
      "The note cannot be empty":"The note cannot be empty",
      "You must upload a image":"You must upload a image"

    }
  },
  "EN-US": {
    "translation": {
      "Hello": "Hello",
      "Yes": "Yes",
      "No": "No",
      "Temporary_grassland": "Grassland",
      "Grassland": "Grassland",
      "Clovers": "Clovers",
      "Olive_groves": "Olive",
      "Olive": "Olive",
      "Oranges": "Oranges",
      "Apple_fruit": "Apple",
      "Apple": "Apple",
      "Cherry_fruit": "Cherry",
      "Cherry": "Cherry",
      "Nuts_trees": "Nuts",
      "Nuts": "Nuts",
      "Rye": "Rye",
      "Potatoes": "Potatoes",
      "Triticale": "Triticale",
      "Sugar_beet": "Sugar beet",
      "Sugar Beet": "Sugar Beet",
      "Soya": "Soya",
      "Cotton": "Cotton",
      "Tomatoes": "Tomatoes",
      "Set the analysis": "Set the analysis",
      "The email is empty": "The email is empty",
      "The password is empty": "The password is empty",
      "The user is not active!": "The user is not active!",
      "The email is used": "The email is used",
      "Forgot password?": "Forgot password?",
      "Create new account": "Create new account",
      "Check your email inbox and follow the instructions to change the password.": "Check your email inbox and follow the instructions to change the password.",
      "Go back to Login": "Go back to Login",
      "The email doesn't exist in our database!": "The email doesn't exist in our database!",
      "Change Password": "Change Password",
      "Send Email": "Send Email",
      "Sending email...": "Sending email...",
      "The first password is empty": "The first password is empty",
      "The confirm password is empty": "The confirm password is empty",
      "The password don't match": "The password don't match",
      "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com",
      "Confirm Password": "Confirm Password",
      "Changing Password...": "Changing Password...",
      "The password has been changed with success. Now use the new password to login.": "The password has been changed with success. Now use the new password to login.",
      "In order to register you should accept the Privacy Policy": "In order to register you should accept the Privacy Policy",
      "In order to register you should provide your name": "In order to register you should provide your name",
      "In order to register you should provide a valid email": "In order to register you should provide a valid email",
      "The current email is used, please use another one!": "The current email is used, please use another one!",
      "Registration": "Registration",
      "Name": "Name",
      "I agree with the": "I agree with the",
      "Register": "Register",
      "Registration...": "Registration...",
      "Already have an account?": "Already have an account?",
      "Check your email inbox and follow the instructions to activate your account.": "Check your email inbox and follow the instructions to activate your account.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Do you want to activate your account?",
      "Activate my account": "Activate my account",
      "Activation...": "Activation...",
      "Your Account has been activated. Now You can login": "Your Account has been activated. Now You can login",
      "Welcome!": "Welcome!",
      "My profile": "My profile",
      "Settings": "Settings",
      "Support": "Support",
      "Logout": "Logout",
      "Farm": "Farm",
      "Field": "Field",
      "Field Notebook": "Field Notebook",
      "Agrometeo": "Agrometeo",
      "Wheater": "Wheater",
      "Climatic Index": "Climatic Index",
      "Satellite": "Satellite",
      "Prescription Map": "Prescription Map",
      "Sensors": "Sensors",
      "Summary": "Summary",
      "Analysis": "Analysis",
      "Forecast": "Forecast",
      "Anomaly Detection": "Anomaly Detection",
      "Comparation": "Comparation",
      "Allert": "Allert",
      "Bio Trap": "Bio Trap",
      "Agronomic tools": "Agronomic tools",
      "Nutrient Balance": "Nutrient Balance",
      "Soil Data": "Soil Data",
      "Irrigation": "Irrigation",
      "Pest Model": "Pest Model",
      "Artificial Intelligence": "Artificial Intelligence",
      "Digital Consulting": "Digital Consulting",
      "Crop Models & simulations": "Crop Models & simulations",
      "Soil Mapping & Carbon Credits": "Soil Mapping & Carbon Credits",
      "Organic vs Convenctional": "Organic vs Convenctional",
      "Connections & Data Sharing": "Connections & Data Sharing",
      "Upgrade your profile": "Upgrade your profile",
      "Register a new farmer": "Register a new farmer",
      "Farmer Company Name:": "Farmer Company Name:",
      "Farmer First Name:": "Farmer First Name:",
      "Farmer Surname:": "Farmer Surname:",
      "Farmer Country:": "Farmer Country:",
      "Farmer City:": "Farmer City:",
      "Farmer Adress:": "Farmer Adress:",
      "Farmer Email:": "Farmer Email:",
      "Farmer Phone Number:": "Farmer Phone Number:",
      "Create Farm": "Create Farm",
      "Go back": "Go back",
      "Restart Creation": "Restart Creation",
      "Creating the farm...": "Creating the farm...",
      "Farm Registered": "Farm Registered",
      "No farmer are registered": "No farmer are registered",
      "If you want to start using this application, you should start by registering a new farmer.": "If you want to start using this application, you should start by registering a new farmer.",
      "Farm Registred": "Farm Registred",
      "Farm Name": "Farm Name",
      "Surname": "Surname",
      "Email": "Email",
      "Phone Number": "Phone Number",
      "Edit": "Edit",
      "Delete": "Delete",
      "Are you sure to remove the following farmer?": "Are you sure to remove the following farmer?",
      "Name of the farmer:": "Name of the farmer:",
      "Surname of the farmer:": "Surname of the farmer:",
      "Country of the farmer:": "Country of the farmer:",
      "City of the farmer:": "City of the farmer:",
      "Adress of the farmer:": "Adress of the farmer:",
      "Email of the farmer:": "Email of the farmer:",
      "Phone Number of the farmer:": "Phone Number of the farmer:",
      "Delete Farmer": "Delete Farmer",
      "Invalid email address": "Invalid email address",
      "Invalid phone number": "Invalid phone number",
      "Add Farm": "Add Farm",
      "Farmer Registred": "Farmer Registred",
      "Farmer": "Farmer",
      "No registered farm": "No registered farm",
      "Loading": "Loading",
      "Update Farm": "Update Farm",
      "Updataing the farm...": "Updataing the farm...",
      "The Name of the farmer is empty": "The Name of the farmer is empty",
      "The Surname of the farmer is empty": "The Surname of the farmer is empty",
      "The CountryCode of the farmer is empty": "The CountryCode of the farmer is empty",
      "The City of the farmer is empty": "The City of the farmer is empty",
      "The Adress of the farmer is empty": "The Adress of the farmer is empty",
      "The Email of the farmer is empty": "The Email of the farmer is empty",
      "The Phone Number of the farmer is empty": "The Phone Number of the farmer is empty",
      "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com": "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com",
      "Create Field": "Create Field",
      "You don't have any farm registred, first you have to set at least one farmer": "You don't have any farm registred, first you have to set at least one farmer",
      "Field name:": "Field name:",
      "Crop Rotation:": "Crop Rotation:",
      "Crop Rotation": "Crop Rotation",
      "Perennial crop": "Perennial crop",
      "Two year crop rotation": "Two year crop rotation",
      "Three year crop rotation": "Three year crop rotation",
      "Actual Crop": "Actual Crop",
      "Alfalfa": "Alfalfa",
      "Barley": "Barley",
      "Maize": "Maize",
      "Durum Wheat": "Durum Wheat",
      "The next year Crop": "The next year Crop",
      "The crop in two years' time": "The crop in two years' time",
      "Organic": "Organic",
      "Convenctional": "Convenctional",
      "Draw the edge of your field:": "Draw the edge of your field:",
      "The field name is empty": "The field name is empty",
      "Please draw the edge of your field in the map": "Please draw the edge of your field in the map",
      "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com",
      "List of the field": "List of the field",
      "Field Name:": "Field Name:",
      "Field Detail": "Field Detail",
      "Crop:": "Crop:",
      "Agronomic Management:": "Agronomic Management:",
      "Are you sure to delete this field?": "Are you sure to delete this field?",
      "Delete Field": "Delete Field",
      "Field:": "Campo:",
      "Loading...": "Loading...",
      "Edit Field": "Edit Field",
      "Draw again the edge of your field:": "Draw again the edge of your field:",
      "In red there is the old field": "In red there is the old field",
      "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com",
      "Try Again": "Try Again",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Select the field to analyze",
      "View Satellite Image": "View Satellite Image",
      "Field Details": "Field Details",
      "Actual Crop:": "Actual Crop:",
      "Time series plot": "Time series plot",
      "Satellite Image": "Satellite Image",
      "Select one field": "Select one field",
      "Field Boundary": "Field Boundary",
      "Select the Vegetation Index": "Select the Vegetation Index",
      "Set the prescription map": "Set the prescription map",
      "Do you want to define the number of zone in Automatically way or Manual?": "Do you want to define the number of zone in Automatically way or Manual?",
      "Automatic": "Automatic",
      "Manual": "Manual",
      "If is set Manual, how many zones do you want to create?": "If is set Manual, how many zones do you want to create?",
      "Max fertilizer that you want to apply?": "Max fertilizer that you want to apply?",
      "Which strategy do you want to use?": "Which strategy do you want to use?",
      "Provide higher amout of fertilizer where the crop is stronger": "Provide higher amout of fertilizer where the crop is stronger",
      "Provide lower amout of fertilizer where the crop is stronger": "Provide lower amout of fertilizer where the crop is stronger",
      "Percentage of difference of fertilizer between zones": "Percentage of difference of fertilizer between zones",
      "Selected Percentage:": "Selected Percentage:",
      "Create": "Create",
      "Send to Jhon Deere Operation Center": "Send to Jhon Deere Operation Center",
      "Download": "Download",
      "Downloading...": "Downloading...",
      "Downloading": "Downloading",
      "Sending": "Sending",
      "Sending...": "Sending...",
      "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com": "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com",
      "Creating the field...": "Creating the field...",
      "Update Field": "Update Field",
      "Updating Field": "Updating Field",
      "Integration done": "Integration done",
      "You have done the integration with jhon deere": "You have done the integration with jhon deere",
      "Start the integration with the Operation Center Jhon Deere": "Start the integration with the Operation Center Jhon Deere",
      "A problem occured during the integration with Jhon Deere": "A problem occured during the integration with Jhon Deere",
      "Start the integration with the Operation Center John Deere": "Start the integration with the Operation Center John Deere",
      "Connect to Operation Center Jhon Deere": "Connect to Operation Center Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center",
      "Satellite Image analysis": "Satellite Image analysis",
      "Please enable the geolocation in order to get the wheater data and visualization": "Please enable the geolocation in order to get the wheater data and visualization",
      "Actual Wheater Data": "Actual Wheater Data",
      "Wheater Map": "Wheater Map",
      "Select the climate variable to see": "Select the climate variable to see",
      "Forecast Temperature": "Forecast Temperature",
      "Wind": "Wind",
      "Pressure": "Pressure",
      "Snow": "Snow",
      "Precipitation": "Precipitation",
      "Clouds": "Clouds",
      "Temperature": "Temperature",
      "Add / Modify / Delete": "Add / Modify / Delete",
      "Field Registred": "Field Registred",
      "Field Name": "Field Name",
      "Select the field to view": "Select the field to view",
      "Number of farmer": "Number of farmer",
      "Number of field": "Number of field",
      "Hectares Monitored": "Hectares Monitored",
      "Anomalies": "Anomalies",
      "IoT Number": "IoT Number",
      "Last Reding": "Last Reding",
      "Soil Temperature": "Soil Temperature",
      "Soil Humidity": "Soil Humidity",
      "Electrical Conductivity": "Electrical Conductivity",
      "Organic Carbon": "Organic Carbon",
      "Nitrogen": "Nitrogen",
      "Phosporous": "Phosporous",
      "Potassium": "Potassium",
      "Battery": "Battery",
      "Select the variable": "Select the variable",
      "Select the IoT Cabin": "Select the IoT Cabin",
      "View data": "View data",
      "Time series Graph": "Time series Graph",
      "Time Series Comparation": "Time Series Comparation",
      "Table": "Table",
      "Add Allert": "Add Allert",
      "Allert Registred": "Allert Registred",
      "Sensor": "Sensor",
      "Variable": "Variable",
      "Condition": "Condition",
      "Value": "Value",
      "No Allert Registrated": "No Allert Registrated",
      "No IoT is linked to your account": "No IoT is linked to your account",
      "No IoT allert is setted": "No IoT allert is setted",
      "Please select the IoT Cabin": "Please select the IoT Cabin",
      "Please select the variable": "Please select the variable",
      "Please select the logic to apply": "Please select the logic to apply",
      "Please set the threshold value": "Please set the threshold value",
      "Select the logic": "Select the logic",
      "Set the threshold value": "Set the threshold value",
      "Adding the allert...": "Adding the allert...",
      "Higher than": "Higher than",
      "Lower than": "Lower than",
      "IoT Position": "IoT Position",
      "Create Allert": "Create Allert",
      "Are you sure to remove this allert?": "Are you sure to remove this allert?",
      "Cabin Number:": "Cabin Number:",
      "Logic - Higher or Lower:": "Logic - Higher or Lower:",
      "Threshold Value:": "Threshold Value:",
      "Variabile": "Variabile",
      "Delete Allert": "Delete Allert",
      "Update Allert": "Update Allert",
      "Updating the allert...": "Updating the allert...",
      "Please the expected yield (q/ha)": "Please the expected yield (q/ha)",
      "Please the fertilizer title (%)": "Please the fertilizer title (%)",
      "Setting of the Calculation": "Setting of the Calculation",
      "Select the Nutrient": "Select the Nutrient",
      "Select the Crop": "Select the Crop",
      "Set the expected yield (q/ha)": "Set the expected yield (q/ha)",
      "Title of fertilizer - range 0-100 %": "Title of fertilizer - range 0-100 %",
      "Calculate": "Calculate",
      "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:": "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:",
      "Set the field to get the soil data": "Set the field to get the soil data",
      "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!": "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!",
      "The digital agronomist consultant is typing": "The digital agronomist consultant is typing",
      "Send a Message": "Send a Message",
      "Digital agronomist consultant": "Digital agronomist consultant",
      "You have done the integration with John Deere": "You have done the integration with John Deere",
      "Please fill all the requests": "Please fill all the requests",
      "Set the analysis parameter": "Set the analysis parameter",
      "Date of sowing": "Date of sowing",
      "Select the soil type": "Select the soil type",
      "Sand": "Sand",
      "Loamy Sand": "Loamy Sand",
      "Sandy Loam": "Sandy Loam",
      "Loam": "Loam",
      "Silty Loam": "Silty Loam",
      "Silt": "Silt",
      "Sandy Clay Loam": "Sandy Clay Loam",
      "Clay Loam": "Clay Loam",
      "Silty Clay Loam": "Silty Clay Loam",
      "Sandy Clay": "Sandy Clay",
      "Clay": "Clay",
      "Sunflower": "Sunflower",
      "Millet": "Millet",
      "Rice": "Rice",
      "Sugarbeet": "Sugarbeet",
      "Sorghum": "Sorghum",
      "Soybean": "Soybean",
      "Canola": "Canola",
      "Tomato": "Tomato",
      "Cabbage": "Cabbage",
      "Potato": "Potato",
      "Sugarcane": "Sugarcane",
      "Select the Variety": "Select the Variety",
      "Do you want to provide a fertilization?": "Do you want to provide a fertilization?",
      "Date of fertilization": "Date of fertilization",
      "Define the amount of Nitrogen provided (kg N / ha)": "Define the amount of Nitrogen provided (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Define the amount of Phosphorus provided (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Define the amount of Potassium provided (kg K / ha)",
      "Do you want to provide a irrigation": "Do you want to provide a irrigation",
      "Date of irrigation": "Date of irrigation",
      "Define the amount of water mm": "Define the amount of water mm",
      "Next": "Next",
      "Submit": "Submit",
      "Temporal creation of the yield": "Temporal creation of the yield",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Before you can run the model you should define the crop type. Go to the Field and update section the information",
      "Set the Field where apply the analysis": "Set the Field where apply the analysis",
      "Set the Farmer": "Set the Farmer",
      "Set the Field": "Set the Field",
      "Run The Analysis": "Run The Analysis",
      "NDVI Comparation": "NDVI Comparation",
      "SAR Comparation": "SAR Comparation",
      "Probabilistic Map": "Probabilistic Map",
      "% Convenctional": "% Convenctional",
      "% Organic": "% Organic",
      "The field was declared by the farmer as": "The field was declared by the farmer as",
      "Select the start year to analyze": "Select the start year to analyze",
      "Select the base temperature for the crop": "Select the base temperature for the crop",
      "Select the max temperature for the crop": "Select the max temperature for the crop",
      "Start analysis": "Start analysis",
      "Upgrade your account": "Upgrade your account",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO",
      "Not Now": "Not Now",
      "Upgrade your account to PRO": "Upgrade your account to PRO",
      "Your Acccount is PRO": "Your Acccount is PRO",
      "You can access to all the service": "You can access to all the service",
      "Your Acccount is not PRO": "Your Acccount is not PRO",
      "Purchase the PRO Service": "Purchase the PRO Service",
      "The total price is": "The total price is",
      "The payment was successful": "The payment was successful",
      "Now you should logout and login again to access all the features just unlocked": "Now you should logout and login again to access all the features just unlocked",
      "By purchasing this service you will access :": "By purchasing this service you will access :",
      "Prescription maps that can be integrated with your display tractors": "Prescription maps that can be integrated with your display tractors",
      "Visualization & Analysis of sensor data": "Visualization & Analysis of sensor data",
      "Set allert": "Set allert",
      "All the agronomic tools": "All the agronomic tools",
      "AI Tools": "AI Tools",
      "Synchronization with Operation Center Jhon Deere": "Synchronization with Operation Center Jhon Deere",
      "Durum_wheat": "Durum Wheat",
      "Vineyards": "Vineyards",
      "perennial": "Perennial",
      "biennial": "Biennal",
      "triennial": "Triennial",
      "Kiwi": "Kiwi",
      "Garlic": "Garlic",
      "Apricot": "Apricot",
      "Orange": "Orange",
      "Asparagus": "Asparagus",
      "Oats": "Oats",
      "Basil": "Basil",
      "Swiss_chard": "Swiss chard",
      "Beet_leaves": "Beet leaves",
      "Broccoli": "Broccoli",
      "Hemp": "Hemp",
      "Artichoke": "Artichoke",
      "Carrot": "Carrot",
      "Cauliflower": "Cauliflower",
      "chickpea": "Chickpea",
      "cucumber": "Cucumber",
      "Chicory": "Chicory",
      "Onion": "Onion",
      "Endive": "Endive",
      "Watermelon": "Watermelon",
      "Rapeseed": "Rapeseed",
      "Green_bean_for_industry": "Green bean for industry",
      "Fresh_green_bean": "Fresh green bean",
      "Bean": "Bean",
      "Dry_bean": "Dry bean",
      "Spelt": "Spelt",
      "Grain_fava_bean": "Grain fava bean",
      "Favino": "Favino",
      "Fico": "Fig",
      "Fennel": "Fennel",
      "Strawberry": "Strawberry",
      "winter_wheat": "Winter wheat",
      "Kaki": "Persimmon",
      "Lettuce": "Lettuce",
      "Lentil": "Lentil",
      "Lemon": "Lemon",
      "Linen": "Linen",
      "Lupin": "Lupin",
      "Corn": "Corn",
      "Tangerine": "Tangerine",
      "Almond": "Almond",
      "Eggplant": "Eggplant",
      "Melon": "Melon",
      "Blueberry": "Blueberry",
      "Nectarines": "Nectarines",
      "Kernel": "Kernel",
      "Walnut": "Walnut",
      "Bell_pepper": "Bell pepper",
      "Pear": "Pear",
      "Peach": "Peach",
      "Pea": "Pea",
      "Leek": "Leek",
      "Parsley": "Parsley",
      "Radicchio": "Radicchio",
      "Turnip": "Turnip",
      "Radish": "Radish",
      "Ribes": "Currant",
      "Shallots": "Shallots",
      "Celery": "Celery",
      "Soy": "Soy",
      "Spinach": "Spinach",
      "Plum_tree": "Plum tree",
      "Tabacco": "Tobacco",
      "Vineyard": "Vineyard",
      "Savoy_cabbage": "Savoy cabbage",
      "Pumpkin": "Pumpkin",
      "Zucchini_for_industry": "Zucchini for industry",
      "Fresh_zucchini": "Fresh zucchini",
      "Peanuts": "Peanuts",
      "Chard": "Swiss chard",
      "Leaf_beet": "Beet leaves",
      "Hemp_for_seeds": "Hemp (seeds)",
      "Hemp_for_fiber": "Hemp (fiber)",
      "Cardoon": "Cardoon",
      "Chestnut": "Chestnut",
      "Cherry_tree": "Cherry tree",
      "French_bean": "Fresh green bean",
      "Fava_bean": "Dry bean",
      "Wheat": "Wheat",
      "Raspberry": "Raspberry",
      "Flax": "Linen",
      "Ley_grass": "Lupin",
      "Corn_for_grain": "Eggplant",
      "Hazelnut": "Hazelnut",
      "Pear_tree": "Pear tree",
      "Peach_tree": "Peach tree",
      "Protein_pea": "Protein pea",
      "Processing_tomato": "Processing tomato",
      "Table_tomato": "Table tomato",
      "Table_grape": "Table grape",
      "Gooseberry": "Gooseberry",
      "Grapevine": "Grapevine",
      "Clover": "Clover",
      "Corn_salad": "Corn salad",
      "Grapevine_for_wine_grapes": "Grapevine for wine grapes",
      "Sweet_corn": "Sweet corn",
      "Silage_corn": "Silage corn",
      "Oilseed_rape": "Oilseed rape",
      "Field-grown_bell_pepper": "Field-grown bell pepper",
      "Pea_for_industry": "Pea for industry",
      "Fresh_pea": "Fresh pea",
      "Greenhouse_table_tomato": "Greenhouse table tomato",
      "Clover_grassland": "Clover grassland",
      "Hill_pastures": "Hill pastures",
      "Mixed_grassland_>50_legumes": "Mixed grassland >50% legumes",
      "Artificial_mixed_hill_pastures": "Artificial mixed hill pastures",
      "Stable_plains": "Stable plains",
      "Silage_fodder": "Silage fodder",
      "Arugula_1st_cut": "Arugula, 1st cut",
      "Arugula_2nd_cut": "Arugula, 2nd cut",
      "Shallot": "Shallot",
      "Arugula_in_open_field": "Arugula in open field",
      "Phosphorus": "Phosphorus",
      "Organic vs Conventional:": "Organic vs Conventional:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Are you sure to edit the polygon? if yes press again the Update Field button",
      "Set the Year of analysis": "Set the Year of analysis",
      "Set the nutrient to visualize": "Set the nutrient to visualize",
      "Carbonates": "Carbonates",
      "Soil Electrical Conductivity": "Soil Electrical Conductivity",
      "pH": "pH",
      "Please set the year": "Please set the year",
      "Please set the nutrient": "Please set the nutrient",
      "Please set the farmer": "Please set the farmer",
      "Please set the field": "Please set the field",
      "The map is not ready yet": "The map is not ready yet",
      "Please select the object of your request": "Please select the object of your request",
      "Please report a valid message": "Please report a valid message",
      "Send us your message": "Send us your message",
      "Object of the request:": "Object of the request:",
      "Object:": "Object:",
      "Support Request": "Support Request",
      "Upgrade account": "Upgrade account",
      "Problem with the application": "Problem with the application",
      "Other": "Other",
      "Message of the request:": "Message of the request:",
      "Message:": "Message:",
      "Send message": "Send message",
      "My account": "My account",
      "Do you want to upgrade your account?": "Do you want to upgrade your account?",
      "Send us a message": "Send us a message",
      "Your Message have been sent with success": "Your Message have been sent with success",
      "As soon as possible one of our operator will check your request and answer you": "As soon as possible one of our operator will check your request and answer you",
      "Frumento_tenero": "Winter Wheat",
      "Winter Wheat": "Winter Wheat",
      "Annual percentage increase": "Annual percentage increase",
      "Tons of CO2 Sequestered": "Tons of CO2 Sequestered",
      "Carbon Credits Generated": "Carbon Credits Generated",
      "Soil Map": "Soil Map",
      "Temporal Variation": "Temporal Variation",
      "If you want to access to this module data, you must upgrade your account to PRO": "If you want to access to this module data, you must upgrade your account to PRO",
      "Run Model": "Run Model",
      "Prescription AI Map": "Prescription AI Map",
      "Please set the expeted yield": "Please set the expeted yield",
      "Please set the number of the application of fertilizer during the year": "Please set the number of the application of fertilizer during the year",
      "AI Section": "AI Section",
      "Which crop?": "Which crop?",
      "Which nutrient?": "Which nutrient?",
      "Expected yield quintals per hectares?": "Expected yield quintals per hectares?",
      "Percentage of title of the fertilizer": "Percentage of title of the fertilizer",
      "Number of the application during the year": "Number of the application during the year",
      "Create a farm": "Create a farm",
      "Create a field": "Create a field",
      "Delete Account": "Delete Account",
      "Are you sure to delete your account?": "Are you sure to delete your account?",
      "Are you sure to delete the account?": "Are you sure to delete the account?",
      "Please be carefull cause if you delete your account all the data related will be removed": "Please be carefull cause if you delete your account all the data related will be removed",
      "If you want to proceed, please report your email ": "If you want to proceed, please report your email ",
      " in the following form input": " in the following form input",
      "Deleting the user...": "Deleting the user...",
      "Delete account": "Delete account",
      "In order to register you should accept the Terms And Conditions": "In order to register you should accept the Terms And Conditions",
      "Account Level:": "Account Level:",
      "Farmer company name:": "Farmer company name:",
      "Your account is basic": "Your account is basic",
      "Close": "Close",
      "Select the Farm": "Select the Farm",
      "No Sensors Are Linked to Your Account": "No Sensors Are Linked to Your Account",
      "Table of the Soil Data": "Table of the Soil Data",
      "Depth start (cm)": "Depth start (cm)",
      "Depth end (cm)": "Depth end (cm)",
      "Mean Bulk density (cg/cm3)": "Mean Bulk density (cg/cm3)",
      "Uncertainty Bulk density (cg/cm3)": "Uncertainty Bulk density (cg/cm3)",
      "Mean Cation Exchange Capacity (mmol/kg)": "Mean Cation Exchange Capacity (mmol/kg)",
      "Uncertainty Cation Exchange Capacity (mmol/kg)": "Uncertainty Cation Exchange Capacity (mmol/kg)",
      "Mean Clay (g/kg)": "Mean Clay (g/kg)",
      "Uncertainty Clay (g/kg)": "Uncertainty Clay (g/kg)",
      "Mean Nitrogen (cg/kg)": "Mean Nitrogen (cg/kg)",
      "Uncertainty Nitrogen (cg/kg)": "Uncertainty Nitrogen (cg/kg)",
      "Mean pH": "Mean pH",
      "Uncertainty pH": "Uncertainty pH",
      "Mean Sand (g/kg)": "Mean Sand (g/kg)",
      "Uncertainty Sand (g/kg)": "Uncertainty Sand (g/kg)",
      "Mean Silt (g/kg)": "Mean Silt (g/kg)",
      "Uncertainty Silt (g/kg)": "Uncertainty Silt (g/kg)",
      "Mean SOC (g/kg)": "Mean SOC (g/kg)",
      "Uncertainty SOC (g/kg)": "Uncertainty SOC (g/kg)",
      "Operators": "Operators",
      "Warehouse": "Warehouse",
      "Field Notes": "Field Notes",
      "Yield": "Yield",
      "Seed": "Seed",
      "Fertilizers": "Fertilizers",
      "Pesticide": "Pesticide",
      "Field Activities": "Field Activities",
      "Operator Cost": "Operator Cost",
      "Revenue": "Revenue",
      "Economy": "Economy",
      "Operators Registred": "Operators Registred",
      "Create Operator": "Create Operator",
      "Last Name": "Last Name",
      "Operator Name:": "Operator Name:",
      "Operator Last Name:": "Operator Last Name:",
      "Operator Email:": "Hello",
      "Operator Phone:": "Operator Phone:",
      "Are you sure to delete this operator?": "Are you sure to delete this operator?",
      "Delete Operator": "Delete Operator",
      "Deleting the Operator...": "Deleting the Operator...",
      "Creating the operator...": "Creating the operator...",
      "Update Operator": "Update Operator",
      "Updating Operator...": "Updating Operator...",
      "The name is empty": "The name is empty",
      "The last name is empty": "The last name is empty",
      "The phone is empty": "The phone is empty",
      "You must select a farm to associate to the operators": "You must select a farm to associate to the operators",
      "The phone number isn't correct": "The phone number isn't correct",
      "Create Warehouse": "Create Warehouse",
      "Warehouse Name": "Warehouse Name",
      "Warehouses Registred": "Warehouses Registred",
      "Warehouse Map": "Warehouse Map",
      "Warehouse Name:": "Warehouse Name:",
      "Select warehouse location:": "Select warehouse location:",
      "Search City:": "Search City:",
      "Creating the Warehouse...": "Creating the Warehouse...",
      "Please report the position of the warehouse": "Please report the position of the warehouse",
      "Please insert the name of the warehouse": "Please insert the name of the warehouse",
      "Please insert the farm to relate to the warehouse": "Please insert the farm to relate to the warehouse",
      "Edit Warehouse": "Edit Warehouse",
      "The red point is the old location of the warehouse": "The red point is the old location of the warehouse",
      "Editing the Warehouse...": "Editing the Warehouse...",
      "Are you sure to delete the warehouse?": "Are you sure to delete the warehouse?",
      "Latitude:": "Latitude:",
      "Longitude:": "Longitude:",
      "Warehouse Farm:": "Warehouse Farm:",
      "Delete Warehouse": "Delete Warehouse",
      "Deleting the Warehouse...": "Deleting the Warehouse...",
      "Create Field Note": "Create Field Note",
      "Field Note Registred": "Field Note Registred",
      "Date": "Date",
      "Title of the note": "Title of the note",
      "Field Note Title:": "Field Note Title:",
      "No Field Note Are Registred": "No Field Note Are Registred",
      "No Field exist for your account": "No Field exist for your account",
      "If you want to register an Field Note you should create first an Operator": "If you want to register an Field Note you should create first an Operator",
      "Select field note location:": "Select field note location:",
      "Warehouse location": "Warehouse location",
      "Type of Note": "Type of Note",
      "Generic": "Generic",
      "Pathogens": "Pathogens",
      "Insects": "Insects",
      "Weed": "Weed",
      "Waterlogging": "Waterlogging",
      "Generic Damage": "Generic Damage",
      "Field Survey": "Field Survey",
      "Crop Status": "Crop Status",
      "Description": "Description",
      "Operator": "Operator",
      "Creating the Field Note...": "Creating the Field Note...",
      "Please insert the title of the field note": "Please insert the title of the field note",
      "Please set the farm related to the field note": "Please set the farm related to the field note",
      "Please set the field related to the field note": "Please set the field related to the field note",
      "Please insert the type of the field note": "Please insert the type of the field note",
      "Please insert the date of the field note": "Please insert the date of the field note",
      "Please insert the operator that create the field note": "Please insert the operator that create the field note",
      "type": "type",
      "Title": "Title",
      "Field Note Map": "Field Note Map",
      "Edit Field Note": "Edit Field Note",
      "In red there is the old Field Note": "In red there is the old Field Note",
      "Old Field Note": "Old Field Note",
      "Editing the Field Note...": "Editing the Field Note...",
      "Please report the position of the field note": "Please report the position of the field note",
      "Field Note Date:": "Field Note Date:",
      "Field Note Farm:": "Field Note Farm:",
      "Field Note Field:": "Field Note Field:",
      "Field Note Operator:": "Field Note Operator:",
      "Field Note Type:": "Field Note Type:",
      "Field Note Title:')}": "Field Note Title:')}",
      "Field Note Description:": "Field Note Description:",
      "Delete Field Note": "Delete Field Note",
      "Deleting the Field Note...": "Deleting the Field Note...",
      "Are you sure to delete this field note?": "Are you sure to delete this field note?",
      "Field Note Data": "Field Note Data",
      "Create Yield data": "Create Yield data",
      "Yield Data Registred": "Yield Data Registred",
      "Harvest Date": "Harvest Date",
      "Crop": "Coltura",
      "Total Quantity": "Total Quantity",
      "Price": "Price",
      "Total Cost": "Total Cost",
      "No Yield data are registred": "No Yield data are registred",
      "No Operator exist for your account": "No Operator exist for your account",
      "If you want to register an Yield you should create first a Operator": "If you want to register an Yield you should create first a Operator",
      "If you want to register an Yield you should create first a Field": "If you want to register an Yield you should create first a Field",
      "Please select the farm": "Please select the farm",
      "Please select the field": "Please select the field",
      "Please select the operator": "Please select the operator",
      "Please set the harvest date": "Please set the harvest date",
      "Please set the total yield": "Please set the total yield",
      "Please set the price of the yield": "Please set the price of the yield",
      "Please set the cost of the operation": "Please set the cost of the operation",
      "Register a Yield": "Register a Yield",
      "The crop is: ": "The crop is: ",
      "Total Yield quintals": "Total Yield quintals",
      "Price selled euro per quintal": "Price selled euro per quintal",
      "Did you use a subcontractors?": "Did you use a subcontractors?",
      "Subcontractor Name": "Subcontractor Name",
      "Total cost of the harvest operation": "Total cost of the harvest operation",
      "Create Yield": "Create Yield",
      "Creating the Yield...": "Creating the Yield...",
      "Update Yield": "Update Yield",
      "Updating the Yield...": "Updating the Yield...",
      "Are you sure to delete this yield data?": "Are you sure to delete this yield data?",
      "Delete Yield": "Delete Yield",
      "Deleting the Yield...": "Deleting the Yield...",
      "Create Seed": "Create Seed",
      "Seed Registred": "Seed Registred",
      "Macro Type": "Macro Type",
      "Type": "Type",
      "Commercial Product Name": "Commercial Product Name",
      "Organic Approved": "Organic Approved",
      "Duplicate": "Duplicate",
      "Please select the unit doses": "Please select the unit doses",
      "Please set the name of the seed": "Please set the name of the seed",
      "Please select the type of the seed": "Please select the type of the seed",
      "Please set the productor name": "Please set the productor name",
      "Please set the crop name": "Please set the crop name",
      "Commercial Product Name:": "Commercial Product Name:",
      "Product Type:": "Product Type:",
      "Unit Dose:": "Unit Dose:",
      "Can be used in organic farming production?": "Can be used in organic farming production?",
      "Day Growth Cycle": "Day Growth Cycle",
      "FAO Class": "FAO Class",
      "Productor": "Productor",
      "Creating the Seed...": "Creating the Seed...",
      "Are you sure to delete this seed?": "Are you sure to delete this seed?",
      "Macro Type:": "Macro Type:",
      "Is organic product approved": "Is organic product approved",
      "Unit dose": "Unit dose",
      "Delete seed": "Delete seed",
      "Deleting the seed...": "Deleting the seed...",
      "Duplicate Seed": "Duplicate Seed",
      "Edit Seed": "Edit Seed",
      "Editing the Seed...": "Editing the Seed...",
      "Create Fertilizer": "Create Fertilizer",
      "Fertilizer Registred": "Fertilizer Registred",
      "Please set the name of the fertilizer": "Please set the name of the fertilizer",
      "Please set the type of fertilizer": "Please set the type of fertilizer",
      "Please set the nitrogen": "Please set the nitrogen",
      "Please set the potassium": "Please set the potassium",
      "Please set the phosphorus": "Please set the phosphorus",
      "Nitrogen Percentange": "Nitrogen Percentange",
      "Phosphorus Percentange": "Phosphorus Percentange",
      "Potassium Percentange": "Potassium Percentange",
      "Are you sure to delete this fertilizer?": "Are you sure to delete this fertilizer?",
      "Delete Fertilizer": "Delete Fertilizer",
      "Deleting the Fertilizer...": "Deleting the Fertilizer...",
      "Creating the Fertilizer...": "Creating the Fertilizer...",
      "Duplicate Fertilizer": "Duplicate Fertilizer",
      "Edit Fertilizer": "Edit Fertilizer",
      "Editing the Fertilizer...": "Editing the Fertilizer...",
      "Create Product": "Create Product",
      "Product Registred": "Product Registred",
      "Register Number": "Register Number",
      "Registration Date": "Registration Date",
      "Product Formulation:": "Product Formulation:",
      "Active substances:": "Active substances:",
      "Content per 100 grams": "Content per 100 grams",
      "Danger Indications": "Danger Indications",
      "Phenological Stage": "Phenological Stage",
      "Scientific name adversity": "Scientific name adversity",
      "Common name adversity": "Common name adversity",
      "Maximum dose": "Maximum dose",
      "Minimum dose": "Minimum dose",
      "Shortage time": "Shortage time",
      "Return time": "Return time",
      "Minimum interval between treatments": "Minimum interval between treatments",
      "Maximum interval between treatments": "Maximum interval between treatments",
      "Maximum number of treatments": "Maximum number of treatments",
      "Unit for maximum number of treatment": "Unit for maximum number of treatment",
      "Max Volume of water": "Max Volume of water",
      "Min Volume of water": "Min Volume of water",
      "Creating the Product...": "Creating the Product...",
      "Are you sure to delete this product?": "Are you sure to delete this product?",
      "Organic Product": "Organic Product",
      "Delete Product": "Delete Product",
      "Deleting the Product...": "Deleting the Product...",
      "Duplicate Product": "Duplicate Product",
      "Edit Product": "Edit Product",
      "Editing the Product...": "Editing the Product...",
      "Cost": "Cost",
      "Create Field Activities": "Create Field Activities",
      "Type of Field Activity": "Type of Field Activity",
      "Tillage": "Tillage",
      "Sowing / Planting": "Sowing / Planting",
      "Defence": "Defence",
      "Tillage Type": "Tillage Type",
      "Plowing": "Plowing",
      "Harrowing": "Harrowing",
      "Hoeing": "Hoeing",
      "Weeding": "Weeding",
      "Clod breaking": "Clod breaking",
      "Milling": "Milling",
      "Ripping": "Ripping",
      "Rolling": "Rolling",
      "Compaction": "Compaction",
      "Shredding": "Shredding",
      "Tillage Depht cm": "Tillage Depht cm",
      "You didn't create a seed object": "You didn't create a seed object",
      "Sowing Dose kg / ha": "Sowing Dose kg / ha",
      "Irrigation Volume m3/ha": "Irrigation Volume m3/ha",
      "You didn't create a Fertilizer object": "You didn't create a Fertilizer object",
      "Create a fertilizer": "Create a fertilizer",
      "Fertilization Product": "Fertilization Product",
      "Fertilization Dose kg/ha": "Fertilization Dose kg/ha",
      "You didn't create a Defence object": "You didn't create a Defence object",
      "Create a Defence Product": "Create a Defence Product",
      "Defence Product": "Defence Product",
      "Defence Type": "Defence Type",
      "Dose Used": "Dose Used",
      "Volume Water Used": "Volume Water Used",
      "Level of infestaction": "Level of infestaction",
      "Low": "Low",
      "Medium": "Medium",
      "High": "High",
      "Authorization Technichian?": "Authorization Technichian?",
      "Respect strip": "Respect strip",
      "Exceeding the intervention threshold": "Exceeding the intervention threshold",
      "It is done?": "It is done?",
      "Crop Phenology": "Crop Phenology",
      "Hour of work": "Hour of work",
      "Variety": "Variety",
      "Subcontractors?": "Subcontractors?",
      "Wheater Class": "Wheater Class",
      "Temperature °C": "Temperature °C",
      "Wind Speed km / h": "Wind Speed km / h",
      "Humidity %": "Humidity %",
      "Rainfall mm": "Rainfall mm",
      "Create Field Activity": "Create Field Activity",
      "Creating the Field Activity...": "Creating the Field Activity...",
      "Are you sure to delete this field activity?": "Are you sure to delete this field activity?",
      "Delete Field Activity": "Delete Field Activity",
      "Deleting the Field Activity...": "Deleting the Field Activity...",
      "Edit Field Activities": "Edit Field Activities",
      "Edit Field Activity": "Edit Field Activity",
      "Editing the Field Activity...": "Editing the Field Activity...",
      "Date of Hire": "Date of Hire",
      "Operator Cost Registered": "Operator Cost Registered",
      "Create Operator Cost": "Create Operator Cost",
      "No Operator are available for your account.": "No Operator are available for your account.",
      "First you have to create an operator": "First you have to create an operator",
      "No Farm are available for your account.": "No Farm are available for your account.",
      "First you have to create an Farm": "First you have to create an Farm",
      "Please set the year operotor cost": "Please set the year operotor cost",
      "Create a Operator Cost": "Create a Operator Cost",
      "Operator year salary": "Operator year salary",
      "Creating the Operator Cost...": "Creating the Operator Cost...",
      "Are you sure to delete this operator cost": "Are you sure to delete this operator cost",
      "Operator Cost Detail": "Operator Cost Detail",
      "Delete Operator Cost": "Delete Operator Cost",
      "Deleting the Operator Cost...": "Deleting the Operator Cost...",
      "Duplicate a Operator Cost": "Duplicate a Operator Cost",
      "Duplicate Operator Cost": "Duplicate Operator Cost",
      "Duplicating the Operator Cost...": "Duplicating the Operator Cost...",
      "Edit a Operator Cost": "Edit a Operator Cost",
      "Edit Operator Cost": "Edit Operator Cost",
      "Editing the Operator Cost...": "Editing the Operator Cost...",
      "Create Revenue": "Create Revenue",
      "Revenue Registered": "Revenue Registered",
      "Total Revenue": "Total Revenue",
      "Please set the total revenue": "Please set the total revenue",
      "Please set the type of the revenue": "Please set the type of the revenue",
      "Register a Revenue": "Register a Revenue",
      "Select the type of revenue:": "Select the type of revenue:",
      "Direct Payments": "Direct Payments",
      "pac": "Direct Payments",
      "indinizzi": "Agricultural Compensation",
      "Agricultural compensation": "Agricultural compensation",
      "Creating the Revenue...": "Creating the Revenue...",
      "Are you sure to delete this revenue?": "Are you sure to delete this revenue?",
      "Delete Revenue": "Delete Revenue",
      "Deleting the Revenue...": "Deleting the Revenue...",
      "Duplicate a Revenue": "Duplicate a Revenue",
      "Duplicate Revenue": "Duplicate Revenue",
      "Duplicating the Revenue...": "Duplicating the Revenue...",
      "Edit a Revenue": "Edit a Revenue",
      "Edit Revenue": "Edit Revenue",
      "Editing the Revenue...": "Editing the Revenue...",
      "Farmer Economy Analysis": "Farmer Economy Analysis",
      "Farm Profit": "Farm Profit",
      "Farm  Revenue": "Farm  Revenue",
      "Farm Total Cost": "Farm Total Cost",
      "Economy Data": "Economy Data",
      "Activity": "Activity",
      "Cost/Price": "Cost/Price",
      "Quantity/Hours": "Quantity/Hours",
      "Cost allocation": "Cost allocation",
      "Allocation of Revenues": "Allocation of Revenues",
      "Revenues": "Revenues",
      "Monthly Costs and Revenues": "Monthly Costs and Revenues",
      "Cash Flow": "Cash Flow",
      "Air Temperature": "Air Temperature",
      "Atmospheric Pressure": "Atmospheric Pressure",
      "Rainfall": "Rainfall",
      "Air Humidity": "Air Humidity",
      "Evapotraspiration": "Evapotraspiration",
      "Degree Day": "Degree Day",
      "TWH Index": "TWH Index",
      "Dew Point": "Dew Point",
      "Gust": "Gust",
      "Real time data or Historic data?": "Real time data or Historic data?",
      "Last 24 Hours Data": "Last 24 Hours Data",
      "Degree Days": "Degree Days",
      "Leaf Wetness": "Leaf Wetness",
      "Irrigation Analysis": "Irrigation Analysis",
      "Download Operator - Format Excel": "Download Operator - Format Excel",
      "Download Warehouse - Format Excel": "Download Warehouse - Format Excel",
      "Download Field Note - Format Excel": "Download Field Note - Format Excel",
      "Download Yield - Format Excel": "Download Yield - Format Excel",
      "Download Field Activity - Format Excel": "Download Field Activity - Format Excel",
      "Download Other Revenue - Format Excel": "Download Other Revenue - Format Excel",
      "Download Operator Cost - Format Excel": "Download Operator Cost - Format Excel",
      "generic": "Generic",
      "phatogens": "Phatogens",
      "insect": "Insect",
      "weed": "Weed",
      "waterlogging": "Waterlogging",
      "genericdamage": "Generic Damage",
      "fieldsurvey": "Field Survey",
      "cropstatus": "Crop Status",
      "acaricida": "Acaricide",
      "aficida": "Aphicide",
      "antideriva": "Anti-drift",
      "antidoto": "Antidote",
      "antigermogliante": "Anti-germinating",
      "antiriscaldo": "Anti-heating",
      "attrattivo": "Attractive",
      "bagnante": "Wetter",
      "coaudiuvante": "Adjuvant",
      "diradante": "Thinning",
      "feromone": "Pheromone",
      "fitoregolatore": "Phytoregulator",
      "geodisinfestante": "Geodisinfestant",
      "repellente": "Repellent",
      "sinergizzante": "Synergist",
      "adiuvante": "Adjuvant",
      "fungicida": "Fungicide",
      "diserbante": "Herbicide",
      "insetticida": "Insecticide",
      "molluschicida": "Molluscicide",
      "organic_product": "Organic Product",
      "nematocida": "Nematicide",
      "sostanza_di_crescita": "Substance of growth",
      "biostimolante": "Biostimulant",
      "ritirato": "Retrieved",
      "attivo": "Active",
      "kg_ha": "kg/ha",
      "l_ha": "l/ha",
      "unita_ha": "Unit/ha",
      "anno": "Year",
      "ciclo": "Cylce",
      "stagione": "Season",
      "semente": "Seed",
      "piantine": "Seedlings",
      "libre_acro": "Libre Acre",
      "minerale": "Mineral",
      "organico": "Organic",
      "tillage": "Tillage",
      "sowing": "Sowing",
      "defence": "Defence",
      "irrigation": "Irrigation",
      "nutrition": "Nutrition",
      "sereno": "Clear",
      "nuvoloso": "Cloudy",
      "variabile": "Variable",
      "molto_nuvoloso": "Very cloudy",
      "coperto": "Covered",
      "nebbia": "Fog",
      "coperto_nebbia": "Covered fog",
      "variabile_con_rovesci": "Variable with rain",
      "temporale": "Thunderstorm",
      "coperto_con_neve": "Covered with snow",
      "pioggia_misto_neve": "Rain mixed snow",
      "erbacee_botticella": "Herbaceous small barrel",
      "erbacee_fioritura": "Herbaceous flowering",
      "erbacee_invaiatura": "Herbaceous veraison",
      "erbacee_allegagione": "Herbaceous fruit set",
      "erbacee_ingrossamento": "Herbaceous swelling",
      "erbacee_germinazione": "Herbaceous germination",
      "erbacee_emergenza_infioriscenza": "Herbaceous inflorescence emergence",
      "erbacee_sviluppo_fogliare": "Herbaceous leaf development",
      "erbacee_maturazione": "Herbaceous maturation",
      "erbacee_senescenza": "Herbaceous senescence",
      "erbacee_levata": "Herbaceous stem elongation",
      "erbacee_accestimento": "Herbaceous tillering",
      "arboree_gemme_inverno": "Arboreal winter buds",
      "arboree_apertura_gemme": "Arboreal bud break",
      "arboree_ripresa_vegetativa": "Arboreal vegetative growth resumption",
      "arboree_sviluppo_germogli": "Arboreal shoot development",
      "arboree_foglie_distese": "Arboreal unfolded leaves",
      "arboree_grappoli_visibili": "Arboreal visible clusters",
      "arboree_grappoli_separati": "Arboreal separated clusters",
      "arboree_ingrossamento_frutti": "Arboreal fruit swelling",
      "arboree_pre_chiusura_grappolo": "Arboreal pre-cluster closure",
      "arboree_chiusura_grappolo": "Arboreal cluster closure",
      "arboree_indurimento_nocciolo": "Arboreal stone hardening",
      "arboree_invaitura": "Arboreal veraison",
      "arboree_riposo_vegetativo": "Arboreal vegetative dormancy",
      "arboree_fioritura": "Arboreal flowering",
      "arboree_allegagione": "Arboreal fruit set",
      "arboree_pianta_ferma": "Arboreal plant at rest",
      "arboree_germogliamento": "Arboreal sprouting",
      "arboree_germogli_5_10_cm": "Arboreal 5-10 cm shoots",
      "arboree_comparsa_boccioli_floreali": "Arboreal flower bud emergence",
      "arboree_bottone_bianco": "Arboreal white button",
      "arboree_crescita": "Arboreal growth",
      "arboree_semi_neri": "Arboreal black seeds",
      "arboree_pre_raccolta": "Arboreal pre-harvest",
      "arboree_raccolta": "Arboreal harvest",
      "basso": "Low",
      "medio": "Medium",
      "alto": "High",
      "aratura": "Plowing",
      "erpicatura": "Harrowing",
      "zappatura": "Hoeing",
      "sarchiatura": "Weeding",
      "frangizollatura": "Fringing",
      "fresatura": "Milling",
      "rippatura": "Ripping",
      "rullatura": "Rolling",
      "compattazione": "Compaction",
      "pacciamatura": "Mulching",
      "trinciatura": "Shredding",
      "Seedlings": "Seedlings",
      "Pounds per acre": "Pounds per acre",
      "Mineral Product": "Mineral Product",
      "Nutrition": "Nutrition",
      "Historic Data": "Historic Data",
      "Withdrawn": "Withdrawn",
      "Active": "Active",
      "Year": "Year",
      "Cycle": "Ctcle",
      "Season": "Season",
      "Defense": "Defense",
      "Active or Retrivied:": "Active or Retrivied",
      "Please fill all the field": "Please fill all the field",
      "Please fill all the form to create a field activities": "Please fill all the form to create a field activities",
      "Other Revenue": "Other Revenue",
      "Harvest": "Harvest",
      "Please insert the date of the revenue": "Please insert the date of the revenue",
      "Please enter the start date of the operator cost": "Please enter the start date of the operator cost",
      "Pear_fruit": "Pear",
      "Dry_pulses": "Dry pulses",
      "Strawberries": "Strawberries",
      "Tobacco": "Tobacco",
      "Other_fibre_and_oleaginous_crops": "Other fibre and oleaginous crops",
      "Other_fresh_vegetables": "Other fresh vegetables",
      "Your Account is not PRO": "Your Account is not PRO",
      "Upgrade to PRO": "Upgrade to PRO",
      "Something Went Wrong During the Payment": "Something Went Wrong During the Payment",
      "Please send us an email to info@automaticfarmsolution.com": "Please send us an email to info@automaticfarmsolution.com",
      "An operator will answear you as soon as possible": "An operator will answear you as soon as possible",
      "VAT Excluded": "VAT Excluded",
      "The total price is 832 € (VAT Included)": "The total price is 832 € (VAT Included)",
      "All the modules of free version": "All the modules of free version",
      "Generation of prescription map with Artificial Intelligence": "Generation of prescription map with Artificial Intelligence",
      "Capability to connect IoT Sensors": "Capability to connect IoT Sensors",
      "Set automatic alerts": "Set automatic alerts",
      "Artificial Intelligence Digital Soil Mapping": "Artificial Intelligence Digital Soil Mapping",
      "Advanced Report & Documentation": "Advanced Report & Documentation",
      "Online Live Training of the Platform": "Online Live Training of the Platform",
      "Number of Plant":"Number of Plant",
      "Number of note":"Number of note",
      "Note":"Note",
      "Add Note":"Add Note",
      "Download Note":"Download Note",
      "List of the note":"List of the note",
      "User":"User",
      "Plant":"Plant",
      "Image":"Image",
      "Created at":"Created at",
      "Detail":"Detail",
      "No Note are Registrated":"No Note are Registrated",
      "Add Note":"Add Note",
      "Plant Number":"Plant Number",
      "Upload or Capture Image":"Upload or Capture Image",
      "Save":"Save",
      "Detail Note - ":"Detail Note - ",
      "User - ":"User - ",
      "Plant - ":"Plant - ",
      "Note - ":"Note - ",
      "Image":"Image",
      "Created at - ":"Created at - ",
      "Are you sure to delete the note?":"Are you sure to delete the note?",
      "Update Note":"Update Note",
      "New registration":"New registration",
      "You don't have yet the permission to use this application":"You don't have yet the permission to use this application",
      "Please use the following button to send a request to the wine manager to be activated":"Please use the following button to send a request to the wine manager to be activated",
      "Send the request":"Send the request",
      "The plant cannot be empty":"The plant cannot be empty",
      "The note cannot be empty":"The note cannot be empty",
      "You must upload a image":"You must upload a image"
    }
  },
  "IT": {
    "translation": {
      "Hello": "Ciao",
      "Yes": "Si",
      "No": "No",
      "Temporary_grassland": "Pascolo",
      "Grassland": "Pascolo",
      "Clovers": "Trifoglio",
      "Olive_groves": "Olive",
      "Olive": "Oliva",
      "Oranges": "Arancio",
      "Apple_fruit": "Mela",
      "Apple": "Mela",
      "Cherry_fruit": "Ciliegio",
      "Cherry": "Ciliegia",
      "Nuts_trees": "Noci",
      "Nuts": "Noci",
      "Rye": "Segale",
      "Potatoes": "Patate",
      "Triticale": "Triticale",
      "Sugar_beet": "Barbabietola da zucchero",
      "Sugar Beet": "Barbabietola da zucchero",
      "Soya": "Soia",
      "Cotton": "Cotone",
      "Tomatoes": "Pomodoro",
      "Set the analysis": "Setta le impostazione delle analisi",
      "Select the Pest Model To Use": "Seleziona il modello da usare",
      "The email is empty": "Il campo email è vuoto",
      "The password is empty": "Il campo password è vuoto",
      "The user is not active!": "L'utente non è attivo",
      "The email is used": "L'email è già in uso",
      "Forgot password?": "Password dimenticata",
      "Create new account": "Crea un nuovo account",
      "Check your email inbox and follow the instructions to change the password.": "Ti abbiamo appena inviato una email, segui le istruzione in essa per poter cambiare la password",
      "Go back to Login": "Torna al login",
      "The email doesn't exist in our database!": "L'email non esiste nel nostro databases",
      "Change Password": "Cambia la password",
      "Send Email": "Invia Email",
      "Sending email...": "Ti stiamo inviando una email",
      "The first password is empty": "La prima password è vuota",
      "The confirm password is empty": "La password di conferma è vuota",
      "The password don't match": "Le due password non combaciano",
      "Password change failed. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "Cambio password fallito. Se il problema persiste, contattaci al automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persist, please send us an email at automaticfarmsolution@gmail.com": "Un errore si è manifestando durante il cambio della password. Se il problema persiste, contattaci al automaticfarmsolution@gmail.com",
      "Confirm Password": "Conferma Password",
      "Changing Password...": "Cambiando la password",
      "The password has been changed with success. Now use the new password to login.": "La password è stata modificata con successo, ora puoi fare login con le nuove credenziali",
      "In order to register you should accept the Privacy Policy": "Per poterti registrare devi accettare la nostra privacy policy",
      "In order to register you should provide your name": "Per poterti registrare devi fornire il tuo nome",
      "In order to register you should provide a valid email": "Per poterti registrare devi fornire una email valida",
      "The current email is used, please use another one!": "L'email inserita è già presente nel nostro database. Usa una email diversa",
      "Registration": "Registrazione",
      "Name": "Nome",
      "I agree with the": "Accetto la",
      "Register": "Registrami",
      "Registration...": "Registrazione in corso",
      "Already have an account?": "Hai già un account?",
      "Check your email inbox and follow the instructions to activate your account.": "Guarda la tua email inbox e segui le istruzioni per attivare il tuo account",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Attivazione del tuo account è fallito. Se il problema persiste, per favore contattaci ad automaticfarmsolution@gmail.com",
      "An error occurred during the activation fo your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Un errore si è mostrato durante l'attivazione del tuo account. Se il problema persiste, contattaci al automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Vuoi attivare il tuo account?",
      "Activate my account": "Attiva il mio account",
      "Activation...": "Attivazione in corso...",
      "Your Account has been activated. Now You can login": "Il tuo account è stato attivato con successo. Ora puoi fare il login.",
      "Welcome!": "Benvenuto!",
      "My profile": "Profilo",
      "Settings": "Impostazioni",
      "Support": "Supporto",
      "Logout": "Logout",
      "Farm": "Azienda agricola",
      "Field": "Campi",
      "Field Notebook": "Quaderno di campagna",
      "Agrometeo": "Agrometeo",
      "Wheater": "Meteo",
      "Climatic Index": "Indici Climatici",
      "Satellite": "Satellite",
      "Prescription Map": "Mappa di Prescrizione",
      "Sensors": "Sensori",
      "Summary": "Sommario",
      "Analysis": "Analisi",
      "Forecast": "Previsioni",
      "Anomaly Detection": "Individuazione di anomalie",
      "Comparation": "Comparazione",
      "Allert": "Allerte",
      "Bio Trap": "Trappole biologiche",
      "Agronomic tools": "Strumenti agronomici",
      "Nutrient Balance": "Bilancio dei nutrienti",
      "Irrigation": "Irrigazione",
      "Pest Model": "Modelli previsionali",
      "Artificial Intelligence": "Intelligenza Artificiale",
      "Digital Consulting": "Consulenza digitale",
      "Crop Models & simulations": "Modelli colturali & Simulazioni",
      "Soil Mapping & Carbon Credits": "Mappatura del suolo & Crediti di carbonio",
      "Connections & Data Sharing": "Connesioni & Condivisione dei dati",
      "Upgrade your profile": "Migliora il tuo abbonamento",
      "Register a new farmer": "Registra una nuova azienda",
      "Farmer Company Name:": "Nome dell'Azienda:",
      "Farmer First Name:": "Nome del proprietario",
      "Farmer Surname:": "Cognome del proprietario",
      "Farmer Country:": "Stato",
      "Farmer City:": "Comune",
      "Farmer Adress:": "Indirizzo",
      "Farmer Email:": "Email",
      "Farmer Phone Number:": "Numero telefonico",
      "Create Farm": "Crea l'azienda",
      "Go back": "Torna indietro",
      "Restart Creation": "Ricomincia",
      "Creating the farm...": "Creazione dell'azienda",
      "Farm Registered": "Aziende registrate",
      "No farmer are registered": "Nessuna azienda è stata registrata",
      "If you want to start using this application, you should start by registering a new farmer.": "Se vuoi inziare ad usare l'applicazione, dovresti registrare una azienda agricola",
      "Farm Registred": "Aziende Registrate",
      "Farm Name": "Nome dell'azienda",
      "Surname": "Cognome",
      "Email": "Email",
      "Phone Number": "Numero telefonico",
      "Edit": "Modifica",
      "Delete": "Elimina",
      "Are you sure to remove the following farmer?": "Sei sicuro di vole eliminare l'azienda?",
      "Name of the farmer:": "Nome del proprietario",
      "Surname of the farmer:": "Cognome del proprietario",
      "Country of the farmer:": "Stato",
      "City of the farmer:": "Comune dell'azienda",
      "Adress of the farmer:": "Indirizzo dell'azienda",
      "Email of the farmer:": "Email dell'azienda",
      "Phone Number of the farmer:": "Numero dell'azienda",
      "Delete Farmer": "Elimina l'azienda",
      "Invalid email address": "Email non valida",
      "Invalid phone number": "Numero di telefono non valido",
      "Add Farm": "Aggiungi Azienda Agricola",
      "Farmer Registred": "Aziende Agricole Registrate",
      "Farmer": "Azienda agricola",
      "No registered farm": "Nessuna azienda agricola registrata",
      "Loading": "Caricamento",
      "Update Farm": "Modifica azienda agricola",
      "Updataing the farm...": "Modificando l'azienda agricola",
      "The farmer company name is empty": "Il nome dell'azienda è vuoto",
      "The Name of the farmer is empty": "Il nome del proprietario dell'azienda agricola è vuoto",
      "The Surname of the farmer is empty": "Il cognome del proprietario dell'azienda agricola è vuoto",
      "The CountryCode of the farmer is empty": "Lo stato è vuoto",
      "The City of the farmer is empty": "Il comune è vuoto",
      "The Adress of the farmer is empty": "L'indirizzo è vuoto",
      "The Email of the farmer is empty": "L'email è vuota",
      "The Phone Number of the farmer is empty": "Il numero telefonico è vuoto",
      "An error occured while updating the farm. if the problem persist please contact us at automaticfarmsolution@gmail.com": "Un errore si è evidenziato mentre stavamo aggiornando l'azienda. Se il problema persiste, per favore contattaci al automaticfarmsolution@gmail.com",
      "Create Field": "Crea Campo",
      "You don't have any farm registred, first you have to set at least one farmer": "Non hai nessuna azienda agricola registrata, prima di tutto crea almeno una azienda agricola",
      "Field name:": "Nome del campo",
      "Crop Rotation:": "Rotazione delle colture",
      "Perennial crop": "Coltura perenne",
      "Two year crop rotation": "Rotazione Biennale",
      "Three year crop rotation": "Rotazione triennale",
      "Actual Crop": "Coltura attuale",
      "Alfalfa": "Erba medica",
      "Vineyards": "Vigneto",
      "Barley": "Orzo",
      "Sunflower": "Girasole",
      "Durum Wheat": "Frumento Duro",
      "The next year Crop": "La coltura nell'anno successivo",
      "The crop in two years' time": "La coltura fra 2 anni",
      "Organic vs Convenctional": "Biologico o Convenzionale",
      "Organic": "Biologico",
      "Convenctional": "Convenzionale",
      "Draw the edge of your field:": "Disegna i confini del campo",
      "The field name is empty": "Il nome del campo è vuoto",
      "Please draw the edge of your field in the map": "Disegna il confine del tuo campo sulla mappa",
      "An error occured while creating the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "Un errore si è manifestato durante la creazione del campo. Se il problema persiste, invaci una email al automaticfarmsolution@gmail.com",
      "List of the field": "Lista dai campi",
      "Field Name:": "Nome del campo",
      "Field Detail": "Dettaglio del campo",
      "Crop:": "Coltura:",
      "Agronomic Management:": "Gestione agronomica:",
      "Are you sure to delete this field?": "Sei sicuro di voler eliminare il campo?",
      "Delete Field": "Elimina il campo",
      "Field:": "Campo:",
      "Edit Field": "Modifica il campo",
      "Draw again the edge of your field:": "Disegna di nuovi il contorno del tuo campo",
      "In red there is the old field": "In rosso c'è il vecchio campo",
      "An error occured while editing the field. If the problem persiste please send us an email automaticfarmsolution@gmail.com": "Un errore si è manifestato durante la modificazione del campo. Se il problema persiste, invaci una email al automaticfarmsolution@gmail.com",
      "Try Again": "Prova ancora",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Un errore si è manifestato durante la richiesta dei dati. Per favore fai il refresh della pagina e se il problema persiste per favore contattaci al automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Seleziona il campo da analizzare",
      "View Satellite Image": "Guarda l'immagine satellitare",
      "Field Details": "Dettaglio del campo",
      "Actual Crop:": "Coltura attuale",
      "Time series plot": "Grafico Temporale",
      "Satellite Image": "Immagine satellitare",
      "Select one field": "Seleziona un campo",
      "Field Boundary": "Confine del campo",
      "Set the prescription map": "Imposta la mappa di prescrizione",
      "Do you want to define the number of zone in Automatically way or Manual?": "Vuoi definire il numero delle zone in maniera automatica o manuale?",
      "Automatic": "Automaticamente",
      "Manual": "Manualmente",
      "If is set Manual, how many zones do you want to create?": "Se in manuale, quante zone vorresti creare?",
      "Max fertilizer that you want to apply?": "Quantità massima di fertilizzante da somministrare",
      "Which strategy do you want to use?": "Quale strategia vuoi usare?",
      "Provide higher amout of fertilizer where the crop is stronger": "Fornire maggiore concime dove la coltura è più vigorosa",
      "Provide lower amout of fertilizer where the crop is stronger": "Fornire minore concime dove la coltura è meno vigorosa",
      "Percentage of difference of fertilizer between zones": "Differenza percentuale di concime fra le zone",
      "Selected Percentage:": "Seleziona la percentuale",
      "Create": "Crea",
      "Loading...": "Caricamento in corso",
      "Send to Jhon Deere Operation Center": "Invia la mappa su Jhon Deere Operation Center",
      "Download": "Scarica",
      "Downloading...": "Scaricamento in corso",
      "Downloading": "Scaricamento in corso",
      "Sending": "Invio in corso",
      "Sending...": "Invio in corso",
      "Something went wrong. If the problem persist please send us an email at automaticfarmsolution@gmail.com": "Un errore si è manifestato, se il problema persiste per favore contattaci al automaticfarmsolution@gmail.com",
      "Creating the field...": "Stiamo creando il campo... attendere",
      "Update Field": "Aggiorna il campo",
      "Updating Field": "Stiamo salvando il campo... Attendere",
      "Integration done": "Integrazione eseguita con successo",
      "You have done the integration with jhon deere": "Hai completato l'integrazione con jhon deere",
      "Start the integration with the Operation Center Jhon Deere": "Inizia l'integrazione con Jhon Deere",
      "Select the Vegetation Index": "Seleziona l'indice vegetazionale",
      "A problem occured during the integration with Jhon deere": "Un problema si è manifestato con l'integrazione con Jhon Deere",
      "Please try again, and if the problem persists please send us an email to automaticfarmsolution@gmail.com": "Prova ancora ad eseguire l'integrazione. Se il problema persiste contattaci al automaticfarmsolution@gmail.com",
      "You don't have any farm registered, first you have to set at least one farmer": "Non hai registrato alcuna azienda agricola, per poter sincronizzare Jhon Deere prima devi avere almeno una azienda sincronizzata",
      "Start the integration with the Operation Center John Deere": "Inizia l'integrazione con Jhon Deere",
      "Connect to Operation Center Jhon Deere": "Connetti a Operation Center Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "Se vuoi inviare le mappe di prescrizione all'operation center, puoi connettere questa piattaforma ad Operation Center",
      "Satellite Image analysis": "Analisi delle immagini satellitari",
      "Please enable the geolocation in order to get the wheater data and visualization": "Per poter visualizzare i dati meteo devi attivare la geolocalizzazione",
      "Actual Wheater Data": "Dati meteo attuali",
      "Wheater Map": "Mappa del meteo",
      "Select the climate variable to see": "Seleziona la variabile climatica da visualizzare",
      "Forecast Temperature": "Previsioni della temperatura",
      "Wind": "Vento",
      "Pressure": "Pressione atmosferica",
      "Snow": "Neve",
      "Precipitation": "Precipitazioni",
      "Clouds": "Nuvolosità",
      "Temperature": "Temperatura",
      "Add / Modify / Delete": "Aggiungi / Modfica / Elimina",
      "Field Registred": "Campi Registrati",
      "Field Name": "Nome del campo",
      "Crop Rotation": "Rotazione Colturale",
      "Select the field to view": "Seleziona il campo da visualizzare",
      "Number of farmer": "Numero delle aziende agricole",
      "Number of field": "Numero di campi",
      "Hectares Monitored": "Ettari Monitorati",
      "Anomalies": "Anomalie",
      "IoT Number": "Numero IoT",
      "Last Reding": "Ultima Lettura",
      "Soil Temperature": "Temperatura del suolo",
      "Soil Humidity": "Umidità del suolo",
      "Electrical Conductivity": "Condubilità Elettrica",
      "Organic Carbon": "Carbonio Organico",
      "Nitrogen": "Azoto",
      "Phosporous": "Fosforo",
      "Potassium": "Potassio",
      "Battery": "Batteria",
      "Select the variable": "Seleziona la variabile",
      "Select the IoT Cabin": "Seleziona la cabina da analizzare",
      "View data": "Visualizza i dati",
      "Time series Graph": "Grafico Temporale",
      "Time Series Comparation": "Comparazione",
      "Table": "Tabella",
      "Add Allert": "Aggiungi Allerta",
      "Allert Registred": "Allerta Registrate",
      "Sensor": "Sensore",
      "Variable": "Variabile",
      "Condition": "Condizione",
      "Value": "Valore",
      "No Allert Registrated": "Nessuna allerta registrata",
      "No IoT is linked to your account": "Nessun IoT è legato al tuo account",
      "No IoT allert is setted": "Nessuna allerta è stata impostata",
      "Please select the IoT Cabin": "Seleziona una cabina",
      "Please select the variable": "Seleziona una variabile",
      "Please select the logic to apply": "Seleziona la logica da impostare",
      "Please set the threshold value": "Definisci il valore soglia",
      "Select the logic": "Seleziona la logica",
      "Set the threshold value": "Setta il valore soglia",
      "Adding the allert...": "Stiamo aggiungendo l'allerta...",
      "Higher than": "Maggiore di",
      "Lower than": "Minore di",
      "IoT Position": "Posizione dell'IoT",
      "Create Allert": "Crea allerta",
      "Are you sure to remove this allert?": "Sei sicuro di voler eliminare questa allerta?",
      "Cabin Number:": "Cabina IoT Numero:",
      "Logic - Higher or Lower:": "Logico - Maggior di o Minore di:",
      "Threshold Value:": "Valore Soglia",
      "Variabile": "Variabile",
      "Delete Allert": "Elimin allerta",
      "Update Allert": "Modifica l'allerta",
      "Updating the allert...": "Stiamo aggiornando l'allerta",
      "Please the expected yield (q/ha)": "Per favore setta la resa attesa (q/ha)",
      "Please the fertilizer title (%)": "Per favore setta il titolo del fertilizzante (%)",
      "Setting of the Calculation": "Setta i dati di calcolo",
      "Select the Nutrient": "Seleziona il nutriente",
      "Select the Crop": "Seleziona la coltura",
      "Set the expected yield (q/ha)": "Seleziona la resa attesa (q/ha)",
      "Title of fertilizer - range 0-100 %": "Titolo del fertilizzante - Range 0-100%",
      "Calculate": "Calcola",
      "The amount of fertilizer that you have provide to the crop for the entire crop growth cycle is:": "La quantità totale di concime da fornire alla coltura per l'intero ciclo di crescita è:",
      "Set the field to get the soil data": "Seleziona il campo dal quale vuoi avere i dati del suolo",
      "Soil Data": "Dati del suolo",
      "Hello, I'm your Digital Agronomist Consultant ! Ask me anything!": "Ciao, sono il tuo agronomo digitale ! Chiedemi qualcosa!",
      "The digital agronomist consultant is typing": "L'agronomo digitale sta scrivendo!",
      "Send a Message": "Invia il messaggio",
      "Digital agronomist consultant": "Consulente digitale agronomico",
      "You have done the integration with John Deere": "Hai già completato la sincronizzazione con Jhon Deere",
      "Please fill all the requests": "Per favore compila ogni elemento",
      "Set the analysis parameter": "Definisci i parametri dell'analisi",
      "Date of sowing": "Data di semina",
      "Select the soil type": "Seleziona il tipo di suolo",
      "Sand": "Sabbia",
      "Loamy Sand": "Limoso Sabbioso",
      "Sandy Loam": "Sabbioso Limoso",
      "Loam": "Limoso",
      "Silty Loam": "Molto limoso",
      "Silt": "Limoso",
      "Sandy Clay Loam": "Sabbiso argilloso",
      "Clay Loam": "Argilloso Limoso",
      "Silty Clay Loam": "Franco",
      "Sandy Clay": "Sabbioso Argilloso",
      "Clay": "Argilloso",
      "Maize": "Mais",
      "Millet": "Miglio",
      "Rice": "Riso",
      "Sugarbeet": "Barbabietola da zucchero",
      "Sorghum": "Sorgo",
      "Soybean": "Soia",
      "Canola": "Colza",
      "Tomato": "Pomodoro",
      "Cabbage": "Cavolo",
      "Potato": "Patata",
      "Sugarcane": "Canna da zucchero",
      "Select the Variety": "Seleziona la varietà",
      "Do you want to provide a fertilization?": "Vuoi fornire del fertilizzante alla coltura?",
      "Date of fertilization": "Data della fertilizzazione",
      "Define the amount of Nitrogen provided (kg N / ha)": "Determina l'ammontare di azoto da fornire alla coltura (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Determina l'ammontate di fosforo da fornire alla coltura (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Determina l'ammontare di potassio da fornire alla coltura (kg K/ ha)",
      "Do you want to provide a irrigation": "Vuoi fornire dell'acqua tramite l'irrigazione",
      "Date of irrigation": "Data dell'apporto dell'irrigazione",
      "Define the amount of water mm": "Determina l'ammontare di acqua da fornire mm",
      "Next": "Avanti",
      "Submit": "Invia",
      "Temporal creation of the yield": "Creazione temporale della resa",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Prima di poter lanciare i modelli di AI, devi determinare la tipologia di coltura. Vai nella sezione Campi e aggiorna le informazioni dei campi",
      "Set the Field where apply the analysis": "Definisci il campo sul quale eseguire l'analisi",
      "Set the Farmer": "Azienda Agricola",
      "Set the Field": "Campo",
      "Run The Analysis": "Esegui l'analisi",
      "NDVI Comparation": "Comparazione NDVI",
      "SAR Comparation": "Comparazione SAR",
      "Probabilistic Map": "Mappa Probabilistica",
      "% Convenctional": "% Convenzionale",
      "% Organic": "% Biologico",
      "The field was declared by the farmer as": "Il campo era stato dichiarato dall'agricoltore come",
      "Select the start year to analyze": "Seleziona l'anno dal quale iniziare per eseguire l'analisi",
      "Select the base temperature for the crop": "Seleziona la temperatura di base della tua coltura",
      "Select the max temperature for the crop": "Seleziona la temperatura massima della coltura",
      "Start analysis": "Inizia l'analisi",
      "Upgrade your account": "Aggiorna il tuo account",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "Se vuoi accedere alle immagini satellitari e generare le mappe di prescrizione devi prima aggiornare il tuo account",
      "Not Now": "Non Ora",
      "Upgrade your account to PRO": "Sali di livello",
      "Your Acccount is PRO": "Il tuo account è PRO",
      "You can access to all the service": "Puoi accedere a tutti i servizi dell'applicazione",
      "Your Acccount is not PRO": "Il tuo account non è PRO",
      "Purchase the PRO Service": "Acquista il servizio PRO",
      "The total price is": "Il prezzo totale è di:",
      "The payment was successful": "Il pagamento è avvenuto con successo",
      "Now you should logout and login again to access all the features just unlocked": "Ora dovresti fare il logout dall'applicazione e rintrare nuovamente per poter accedere a tutti i nuovi servizi sbloccati per te",
      "By purchasing this service you will access :": "Acquistando questo servizio potrai accedere a:",
      "Prescription maps that can be integrated with your display tractors": "Mappe di prescrizione compatibili con tutti i display dei trattori",
      "Visualization & Analysis of sensor data": "Visuallizzare & Analizzare i dati dei sensori",
      "Set allert": "Definire delle allerte",
      "All the agronomic tools": "Tutti i strumenti agronomici",
      "AI Tools": "Strumenti di intelligenza artificiale",
      "Synchronization with Operation Center Jhon Deere": "Sincronizzazione con l'operation center di Jhon Deere",
      "Durum_wheat": "Grano duro",
      "perennial": "Perenne",
      "biennial": "Biennale",
      "triennial": "Triennale",
      "Kiwi": "Kiwi",
      "Garlic": "Aglio",
      "Apricot": "Albicocca",
      "Orange": "Arancia",
      "Asparagus": "Asparago",
      "Oats": "Avena",
      "Basil": "Basilico",
      "Swiss_chard": "Bietola",
      "Beet_leaves": "Foglie di barbabietola",
      "Broccoli": "Broccoli",
      "Hemp": "Canapa",
      "Artichoke": "Carciofo",
      "Carrot": "Carota",
      "Cauliflower": "Cavolfiore",
      "chickpea": "Ceci",
      "cucumber": "Cetriolo",
      "Chicory": "Cicoria",
      "Onion": "Cipolla",
      "Endive": "Cicoria scarola",
      "Watermelon": "Anguria",
      "Rapeseed": "Colza",
      "Green_bean_for_industry": "Fagiolino per l'industria",
      "Fresh_green_bean": "Fagiolino fresco",
      "Bean": "Fagiolo",
      "Dry_bean": "Fagiolo secco",
      "Spelt": "Farro",
      "Grain_fava_bean": "Fava",
      "Favino": "Favino",
      "Fico": "Fico",
      "Fennel": "Finocchio",
      "Strawberry": "Fragola",
      "winter_wheat": "Grano invernale",
      "Kaki": "Kaki",
      "Lettuce": "Lattuga",
      "Lentil": "Lenticchia",
      "Lemon": "Limone",
      "Linen": "Lino",
      "Lupin": "Lupino",
      "Corn": "Mais",
      "Tangerine": "Mandarino",
      "Almond": "Mandorla",
      "Eggplant": "Melanzana",
      "Melon": "Melone",
      "Blueberry": "Mirtillo",
      "Nectarines": "Nettarine",
      "Kernel": "Nocciola",
      "Walnut": "Noce",
      "Bell_pepper": "Peperone",
      "Pear": "Pera",
      "Peach": "Pesca",
      "Pea": "Pisello",
      "Leek": "Porro",
      "Parsley": "Prezzemolo",
      "Radicchio": "Radicchio",
      "Turnip": "Rapa",
      "Radish": "Ravanello",
      "Ribes": "Ribes",
      "Shallots": "Scalogno",
      "Celery": "Sedano",
      "Soy": "Soia",
      "Spinach": "Spinacio",
      "Plum_tree": "Susino",
      "Tabacco": "Tabacco",
      "Vineyard": "Vigneto",
      "Savoy_cabbage": "Verza",
      "Pumpkin": "Zucca",
      "Zucchini_for_industry": "Zucchina per l'industria",
      "Fresh_zucchini": "Zucchina fresca",
      "Peanuts": "Arachidi",
      "Chard": "Bietola",
      "Leaf_beet": "Foglie di barbabietola",
      "Hemp_for_seeds": "Canapa (semi)",
      "Hemp_for_fiber": "Canapa (fibra)",
      "Cardoon": "Cardo",
      "Chestnut": "Castagna",
      "Chickpea": "Ceci",
      "Cucumber": "Cetriolo",
      "Cherry_tree": "Albero di ciliegio",
      "French_bean": "Fagiolo verde",
      "Fava_bean": "Fava",
      "Wheat": "Grano",
      "Raspberry": "Lampone",
      "Flax": "Lino",
      "Ley_grass": "Erba medica perenne",
      "Corn_for_grain": "Mais da cereali",
      "Hazelnut": "Nocciola",
      "Pear_tree": "Albero di pero",
      "Peach_tree": "Albero di pesco",
      "Protein_pea": "Pisello proteico",
      "Processing_tomato": "Pomodoro da industria",
      "Table_tomato": "Pomodoro da tavola",
      "Table_grape": "Uva da tavola",
      "Gooseberry": "Uva spina",
      "Grapevine": "Vite",
      "Clover": "Trifoglio",
      "Corn_salad": "Valeriana",
      "Grapevine_for_wine_grapes": "Vite per uva da vino",
      "Sweet_corn": "Mais dolce",
      "Silage_corn": "Mais insilato",
      "Oilseed_rape": "Colza da olio",
      "Field-grown_bell_pepper": "Peperone coltivato in campo",
      "Pea_for_industry": "Pisello per l'industria",
      "Fresh_pea": "Pisello fresco",
      "Greenhouse_table_tomato": "Pomodoro da tavola in serra",
      "Clover_grassland": "Prato di trifoglio",
      "Hill_pastures": "Pascoli di collina",
      "Mixed_grassland_>50_legumes": "Pascolo misto con >50% leguminose",
      "Artificial_mixed_hill_pastures": "Pascoli artificiali misti di collina",
      "Stable_plains": "Pianure stabili",
      "Silage_fodder": "Fieno insilato",
      "Arugula_2nd_cut": "Rucola, 2° taglio",
      "Shallot": "Scalogno",
      "Arugula_in_open_field": "Rucola in campo aperto",
      "Arugula_1st_cut": "Rucola, 1° taglio",
      "Phosphorus": "Fosforo",
      "Organic vs Conventional:": "Biologico vs Convenzionale:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Sei sicuro di voler aggiornare questo campo? Se sei sicuro premi di nuovo il pulsante verde aggiorna il campo",
      "Set the Year of analysis": "Anno di analisi",
      "Set the nutrient to visualize": "Nutriente da visualizzare",
      "Carbonates": "Carbonati",
      "Soil Electrical Conductivity": "Conducibilità elettrica",
      "pH": "pH",
      "Please set the year": "Prima di eseguire l'analisi definisci l'anno di analisi",
      "Please set the nutrient": "Prima di eseguire l'analisi definisci il nutriente di analisi",
      "Please set the farmer": "Prima di eseguire l'analisi definisci l'azienda agricola di analisi",
      "Please set the field": "Prima di eseguire l'analisi definisci il campo",
      "The map is not ready yet": "La mappa non è ancora pronta",
      "Please select the object of your request": "Seleziona l'oggetto della richiesta",
      "Please report a valid message": "Scrivi un messaggio valido",
      "Send us your message": "Inviaci un messaggio",
      "Object of the request:": "Oggetto della richiesta:",
      "Object:": "Oggetto:",
      "Support Request": "Richiesta di supporto",
      "Upgrade account": "Sali di livello",
      "Problem with the application": "Problema con l'applicazione",
      "Other": "Altro",
      "Message of the request:": "Messaggio della richiesta:",
      "Message:": "Messaggio:",
      "Send message": "Invia il messaggio",
      "My account": "Il mio account",
      "Do you want to upgrade your account?": "Vuoi salire di livello?",
      "Send us a message": "Inviaci un messaggio",
      "Your Message have been sent with success": "Il tuo messaggio è stato inviato con successo",
      "As soon as possible one of our operator will check your request and answer you": "Presto un nostro operatore prenderà in carico la richiesta e ti risponderà il prima possibile",
      "Frumento_tenero": "Frumento Tenero",
      "Winter Wheat": "Frumento Tenero",
      "Annual percentage increase": "Aumento Percentuale Annuo",
      "Tons of CO2 Sequestered": "Tonnelate di CO2 sequestrate",
      "Carbon Credits Generated": "Crediti di carbonio generati",
      "Soil Map": "Mappa del suolo",
      "Temporal Variation": "Variazione Temporale",
      "If you want to access to this module data, you must upgrade your account to PRO": "Se vuoi accedere a questo modulo, devi fare l'Upgrade del tuo account",
      "Run Model": "Esegui il modello",
      "Prescription AI Map": "Mappa di prescrizione - Intelligenza Artificiale",
      "Please set the expeted yield": "Seleziona la potenziale resa ottenibile",
      "Please set the number of the application of fertilizer during the year": "Seleziona il numero di applicazioni del fertilizzante durante tutta la crescita colturale",
      "AI Section": "Sezione di intelligenza artificiale",
      "Which crop?": "Quale Coltura?",
      "Which nutrient?": "Quale nutrient?",
      "Expected yield quintals per hectares?": "Resa ottenibile in quintali ettaro?",
      "Percentage of title of the fertilizer": "Titolo del fertilizzante",
      "Number of the application during the year": "Numero di applicazioni durante l'anno",
      "Search City:": "Cerca la città:",
      "Export Document": "Esportazione Dati",
      "Download Section": "Sezione download",
      "Download Field - Format ShapeFile": "Download Campi - Formato ShapeFile",
      "Download Field - Format Excel": "Download Campi - Formato Excel",
      "Download Farmer Data - Format Excel": "Download Dati Aziende Agricole - Formato Excel",
      "Welcome to AIBIOSAT": "Benvenuti in AIBIOSAT",
      "The 1st AI-based application that support the organic certification": "La prima applicazione basata sull'IA che supporta la certificazione biologica",
      "Before to start to use the application you should follow this step by step welcome on boarding procedure": "Prima di iniziare a utilizzare l'applicazione, dovresti seguire questa procedura passo dopo passo di benvenuto",
      "1) Setting up a Farm": "1) Crea una azienda agricola",
      "2) Create a Field to be associated with the Farm": "2) Creare un Campo da associare all'azienda agricola",
      "3) You are done": "3) Hai finito",
      "Let's start": "Iniziamo",
      "Skip the procedure": "Salta la procedura",
      "Start Using the application": "Inizia a utilizzare l'applicazione",
      "Hectares Deleted": "Ettari eliminati",
      "Create a farm": "Crea una azienda",
      "Create a field": "Crea un campo",
      "Delete Account": "Elimina l'account",
      "Are you sure to delete your account?": "Sei sicuro di eliminare l'account?",
      "Are you sure to delete the account?": "Sei sicuro di eliminare l'account?",
      "Please be carefull cause if you delete your account all the data related will be removed": "Perfavore presta attenzione, se elimini il tuo account, tutti i dati relativi al tuo account saranno eliminati per sempre",
      "If you want to proceed, please report your email ": "Se vuoi procedere, riporta la tua email ",
      " in the following form input": " nel seguente form",
      "Deleting the user...": "Eliminando l'utente...",
      "Delete account": "Elimina l'account",
      "In order to register you should accept the Terms And Conditions": "Per poterti registrare dei prima accettare i termini e condizioni",
      "Account Level:": "Livello del tuo account:",
      "Farmer company name:": "Azienda Agricola:",
      "Your account is basic": "Il tuo account è Basic",
      "Close": "Chiudi",
      "Select the Farm": "Seleziona l'azienda agricola",
      "No Sensors Are Linked to Your Account": "Nessun sensore è collegato al tuo account",
      "Table of the Soil Data": "Tabella dei dati del suolo",
      "Depth start (cm)": "Profondità iniziale (cm)",
      "Depth end (cm)": "Profondità Finale (cm)",
      "Mean Bulk density (cg/cm3)": "Densità del suolo Medio (cg/cm3)",
      "Uncertainty Bulk density (cg/cm3)": "Densità del suolo Incertezza (cg/cm3)",
      "Mean Cation Exchange Capacity (mmol/kg)": "Capacità di scambio cationico Medio (mmol/kg)",
      "Uncertainty Cation Exchange Capacity (mmol/kg)": "Capacità di scambio cationico Incertezza (mmol/kg)",
      "Mean Clay (g/kg)": "Argilla Media (g/kg)",
      "Uncertainty Clay (g/kg)": "Argilla Incertezza (g/kg)",
      "Mean Nitrogen (cg/kg)": "Azoto Medio (cg/kg)",
      "Uncertainty Nitrogen (cg/kg)": "Azoto Incertezza (cg/kg)",
      "Mean pH": "pH Medio",
      "Uncertainty pH": "pH Incertezza",
      "Mean Sand (g/kg)": "Sabbia Medio (g/kg)",
      "Uncertainty Sand (g/kg)": "Sabbia Incertezza (g/kg)",
      "Mean Silt (g/kg)": "Limo Medio (g/kg)",
      "Uncertainty Silt (g/kg)": "Limo incertezza (g/kg)",
      "Mean SOC (g/kg)": "Contenuto di carbonio organico nel suolo medio (g/kg)",
      "Uncertainty SOC (g/kg)": "Contenuto di carbonio organico nel suolo Incertezza (g/kg)",
      "Operators": "Operatori",
      "Warehouse": "Magazzino",
      "Field Notes": "Note di campo",
      "Yield": "Resa",
      "Seed": "Semente",
      "Fertilizers": "Fertilizzanti",
      "Pesticide": "AgroFarmaci",
      "Field Activities": "Attività di campo",
      "Operator Cost": "Costo dell'operatore",
      "Revenue": "Ricavi",
      "Economy": "Economia",
      "Operators Registred": "Operatori Registrati",
      "Create Operator": "Crea Operatore",
      "Create operator": "Crea Operatore",
      "Last Name": "Cognome",
      "Operator Name:": "Nome Operatore:",
      "Operator Last Name:": "Cognome Operatore:",
      "Operator Email:": "Email dell'operatore:",
      "Operator Phone:": "Cellulare dell'operatore:",
      "Are you sure to delete this operator?": "Sei sicuro di voler eliminare questo operatore?",
      "Delete Operator": "Elimina operatore",
      "Deleting the Operator...": "Eliminando l'operatore...",
      "Creating the operator...": "Creando l'operatore...",
      "Update Operator": "Modifica Operatore",
      "Updating Operator...": "Modificando l'operatore...",
      "The name is empty": "Il nome è vuoto",
      "The last name is empty": "Il cognome è vuoto",
      "The phone is empty": "Il cellulare è vuoto",
      "You must select a farm to associate to the operators": "Devi selezionare una azienda agricola a cui associare l'operatore",
      "The phone number isn't correct": "Il numero di telefono non è corretto",
      "Create Warehouse": "Crea Magazzino",
      "Warehouse Name": "Nome del magazzino",
      "Warehouses Registred": "Magazzini Registrati",
      "Warehouse Map": "Mappa dei magazzini",
      "Warehouse Name:": "Nome del magazzino:",
      "Select warehouse location:": "Seleziona la posizione del magazzino:",
      "Creating the Warehouse...": "Creazione del magazzino...",
      "Please report the position of the warehouse": "Per favore riporta la posizione su mappa della nota di campo",
      "Please insert the name of the warehouse": "Per favore inserisci il nome del magazzino",
      "Please insert the farm to relate to the warehouse": "Per favore indicaci a quale azienda agricola dobbiamo associare il magazzino",
      "Edit Warehouse": "Modifica magazzino",
      "The red point is the old location of the warehouse": "Il punto rosso è la vecchia posizione del magazzino",
      "Editing the Warehouse...": "Modificando il magazzino...",
      "Are you sure to delete the warehouse?": "Sei sicuro di voler eliminare il magazzino?",
      "Latitude:": "Latitudine:",
      "Longitude:": "Longitudine:",
      "Warehouse Farm:": "Azienda agricola a qui associare il magazzino:",
      "Delete Warehouse": "Elimina il magazzino",
      "Deleting the Warehouse...": "Eliminando il magazzino...",
      "Create Field Note": "Crea la nota di campo",
      "Field Note Registred": "Note di campo registrate",
      "Date": "Data",
      "Title of the note": "Titolo della nota di campo",
      "Field Note Title:": "Titolo della nota di campo:",
      "No Field Note Are Registred": "Nessuna nota di campo è stata registrata",
      "No Field exist for your account": "Nessun campo esiste per il tuo account",
      "If you want to register an Field Note you should create first an Operator": "Se vuoi registrare una nota di campo dovresti creare prima un operatore",
      "Select field note location:": "Seleziona il punto nella mappa della nota di campo:",
      "Warehouse location": "Posizione del magazzino",
      "Type of Note": "Tipo di nota",
      "Generic": "Generica",
      "Pathogens": "Patogeni",
      "Insects": "Insetti",
      "Weed": "Infestanti",
      "Waterlogging": "Ristagno idrico",
      "Generic Damage": "Danno Generico",
      "Field Survey": "Visita di campo",
      "Crop Status": "Status della coltura",
      "Description": "Descrizione",
      "Operator": "Operatore",
      "Creating the Field Note...": "Creazione della nota di campo...",
      "Please insert the title of the field note": "Per favore inserisci il titolo della nota di campo",
      "Please set the farm related to the field note": "Per favore indica l'azienda agricola a cui associare la nota di campo",
      "Please set the field related to the field note": "Per favore indica il campo a cui associare la nota di campo",
      "Please insert the type of the field note": "Per favore inserisci la tipologia di nota di campo",
      "Please insert the date of the field note": "Per favore inserisci la data della nota di campo",
      "Please insert the operator that create the field note": "Per favore inserisci l'operatore relazionata alla nota di campo",
      "type": "Tipo",
      "Title": "Titolo",
      "Field Note Map": "Mappa delle note di campo",
      "Edit Field Note": "Modifica la nota di campo",
      "In red there is the old Field Note": "In rosso c'è la vecchia posizione della nota di campo",
      "Old Field Note": "Vecchia nota di campo",
      "Editing the Field Note...": "Modificando la nota di campo...",
      "Please report the position of the field note": "Per favore riporta la posizione della nota di campo",
      "Field Note Date:": "Data della nota di campo:",
      "Field Note Farm:": "Appezzamento della nota di campo:",
      "Field Note Field:": "Appezzamento della nota di campo:",
      "Field Note Operator:": "Operatore della nota di campo:",
      "Field Note Type:": "Tipologia di nota di campo:",
      "Field Note Description:": "Descrizione della nota di campo:",
      "Delete Field Note": "Elimina la nota di campo",
      "Deleting the Field Note...": "Eliminando la nota di campo...",
      "Are you sure to delete this field note?": "Sei sicuro di voler eliminare questa nota di campo?",
      "Field Note Data": "Dati delle note di campo",
      "Create Yield data": "Crea dato di resa",
      "Yield Data Registred": "Dati di resa registrati",
      "Harvest Date": "Data di raccolta",
      "Crop": "Coltura",
      "Total Quantity": "Quantità Totale",
      "Price": "Prezzo",
      "Total Cost": "Costo totale",
      "No Yield data are registred": "Nessun dato di resa registrato",
      "No Operator exist for your account": "Nessun operatore è registrato per il tuo account",
      "If you want to register an Yield you should create first a Operator": "Se vuoi registrare un dato di resa prima devi creare un operatore",
      "If you want to register an Yield you should create first a Field": "Se vuoi registrare un dato di resa, prima devi creare un campo",
      "Please select the farm": "Per favore seleziona l'azienda agricola",
      "Please select the field": "Per favore seleziona un campo",
      "Please select the operator": "Per favore seleziona l'operatore",
      "Please set the harvest date": "Per favore seleziona una data di raccolto",
      "Please set the total yield": "Per favore seleziona la resa totale",
      "Please set the price of the yield": "Per favore seleziona un prezzo di vendita della resa",
      "Please set the cost of the operation": "Per favore inserisci il costo dell'operazione di raccolta",
      "Register a Yield": "Registra un dato di resa",
      "The crop is: ": "La coltura è: ",
      "Total Yield quintals": "Resa totale in quintali",
      "Price selled euro per quintal": "Prezzo di vendita al quintale",
      "Did you use a subcontractors?": "Hai usato dei terzisti?",
      "Subcontractor Name": "Nome del terzista",
      "Total cost of the harvest operation": "Costo totale dell'operazione di raccolta",
      "Create Yield": "Crea rea",
      "Creating the Yield...": "Creando il dato di resa...",
      "Update Yield": "Aggiorna la resa",
      "Updating the Yield...": "Aggrionando la resa...",
      "Are you sure to delete this yield data?": "Sei sicuro di voler eliminare il dato di resa?",
      "Delete Yield": "Elimina il dato di resa",
      "Deleting the Yield...": "Eliminando il dato di resa...",
      "Total cost to harvest": "Costo totale dell'operazione di raccolta",
      "Create Seed": "Crea Semente",
      "Seed Registred": "Semente Registrata",
      "Macro Type": "Macro tipologia",
      "Type": "Tipo",
      "Commercial Product Name": "Nome prodotto commerciale",
      "Organic Approved": "Prodotto approvato in regime biologico?",
      "Duplicate": "Duplica",
      "Please select the unit doses": "Per favore inserisci la dose",
      "Please set the name of the seed": "Per favore inserisci il nome del prodotto commerciale",
      "Please select the type of the seed": "Per favore seleziona la tipologia di semente/piantine",
      "Please set the productor name": "Per favore seleziona il nome del produttore",
      "Please set the crop name": "Per favore inserisci il nome della coltura",
      "Commercial Product Name:": "Nome prodotto commerciale:",
      "Product Type:": "Tipologia di prodotto:",
      "Unit Dose:": "Dose:",
      "Can be used in organic farming production?": "Prodotto approvato in regime biologico?",
      "Day Growth Cycle": "Giorni Ciclo Vegetativo",
      "FAO Class": "Classe FAO",
      "Productor": "Produttore",
      "Creating the Seed...": "Creando la semente...",
      "Are you sure to delete this seed?": "Sei sicuro di voler eliminare questa semente?",
      "Macro Type:": "Macro Tipologia:",
      "Is organic product approved": "Prodotto approvato in regime biologico",
      "Unit dose": "Dose",
      "Delete seed": "Elimina semente",
      "Deleting the seed...": "Eliminando la semente...",
      "Duplicate Seed": "Duplica la semente",
      "Edit Seed": "Modifica la semente",
      "Editing the Seed...": "Aggiornando la semente...",
      "Create Fertilizer": "Crea Fertilizzante",
      "Fertilizer Registred": "Fertilizzante Registrati",
      "Please set the name of the fertilizer": "Per favore inserisci il nome commerciale del fertilizzante",
      "Please set the type of fertilizer": "Per favore seleziona la tipologia di fertilizzante",
      "Please set the nitrogen": "Per favore inserisci la percentuale di azoto del fertilizzante",
      "Please set the potassium": "Per favore inserisci la percentuale di potassio del fertilizzante",
      "Please set the phosphorus": "Per favore inserisci la percentuale di fosforo del fertilizzante",
      "Nitrogen Percentange": "Titolo di azoto in %",
      "Phosphorus Percentange": "Titolo di fosforo in %",
      "Potassium Percentange": "Titolo di potassio in %",
      "Are you sure to delete this fertilizer?": "Sei sicuro di eliminare il fertilizzante?",
      "Delete Fertilizer": "Elimina il fertilizzante",
      "Deleting the Fertilizer...": "Elimando il fertilizzante...",
      "Creating the Fertilizer...": "Creando il fertilizzante...",
      "Duplicate Fertilizer": "Duplica il fertilizzante",
      "Edit Fertilizer": "Modifica il fertilizzante",
      "Editing the Fertilizer...": "Modificando il fertilizzante...",
      "Create Product": "Crea un prodotto",
      "Product Registred": "Prodotti Registrati",
      "Register Number": "Numero di registro",
      "Registration Date": "Data di registrazione",
      "Product Formulation:": "Formulazione prodotto:",
      "Active substances:": "Principi Attivi:",
      "Content per 100 grams": "Contenuto per 100 gr",
      "Danger Indications": "Indiciazioni di pericolo",
      "Phenological Stage": "Stadio fenologico",
      "Scientific name adversity": "Nome scientifico dell'avversità",
      "Common name adversity": "Nome comune dell'avversità",
      "Maximum dose": "Dose massima",
      "Minimum dose": "Dose minima",
      "Shortage time": "Tempo di carenza - Giorni prima che il prodotto può essere raccolto/commercializzato",
      "Return time": "Tempo di rientro - Giorni minimi prima di far rientrare in campo gli operatori",
      "Minimum interval between treatments": "Giorni minimi di intervallo fra i trattamenti",
      "Maximum interval between treatments": "Giorni massimi di intervallo fra i trattamenti",
      "Maximum number of treatments": "Numero massimi di trattamenti",
      "Unit for maximum number of treatment": "Unità per numero massimi di trattamenti",
      "Max Volume of water": "Massimo volume di acqua",
      "Min Volume of water": "Minimo volume di acqua",
      "Creating the Product...": "Creando il prodotto...",
      "Are you sure to delete this product?": "Sei sicuro di voler eliminare il prodotto?",
      "Organic Product": "Prodotto biologico",
      "Delete Product": "Elimina il prodotto",
      "Deleting the Product...": "Eliminando il prodotto...",
      "Duplicate Product": "Duplica il prodotto",
      "Edit Product": "Modifica il prodotto",
      "Editing the Product...": "Modificando il prodotto...",
      "Cost": "Costi",
      "Create Field Activities": "Crea Attività di campo",
      "Type of Field Activity": "Tipologia di attività di campo",
      "Tillage": "Lavorazione",
      "Sowing / Planting": "Semina / Piantumazione",
      "Defence": "Difesa",
      "Tillage Type": "Tipologia di lavorazione",
      "Plowing": "Aratura",
      "Harrowing": "Erpicatura",
      "Hoeing": "Zappatura",
      "Weeding": "Sarchiatura",
      "Clod breaking": "Frangizollatura",
      "Milling": "Fresatura",
      "Ripping": "Rippatura",
      "Rolling": "Rullatura",
      "Compaction": "Compattazione",
      "Mulching": "Pacciamatura",
      "Shredding": "Trinciatura",
      "Tillage Depht cm": "Profondità di lavorazione cm",
      "You didn't create a seed object": "Non hai creato alcuna semente",
      "Sowing Dose kg / ha": "Dose di semina kg / ha",
      "Irrigation Volume m3/ha": "Volume di irrigazione m3/ha",
      "You didn't create a Fertilizer object": "Non hai creato alcun Fertilizzante",
      "Create a fertilizer": "Crea Fertilizzante",
      "Fertilization Product": "Prodotto Fertilizzante",
      "Fertilization Dose kg/ha": "Dose di fertilizzazione kg/ha",
      "You didn't create a Defence object": "Non hai creato alcun prodotto di difesa",
      "Create a Defence Product": "Crea prodotto di difesa",
      "Defence Product": "Prodotto",
      "Defence Type": "Tipologia di prodotto",
      "Dose Used": "Dose usata",
      "Volume Water Used": "Volume di acqua usata",
      "Level of infestaction": "Livello di infestazione",
      "Low": "Basso",
      "Medium": "Medio",
      "High": "Alto",
      "Authorization Technichian?": "Autorizzazione da parte del tecnico?",
      "Respect strip": "Rispetto della fascia di rispetto",
      "Exceeding the intervention threshold": "Superata la soglia di intervento",
      "It is done?": "E' stata fatta l'attività?",
      "Crop Phenology": "Fenologia della coltura",
      "Hour of work": "Ore di lavoro",
      "Variety": "Varietà",
      "Subcontractors?": "Terzisiti?",
      "Wheater Class": "Meteo",
      "Temperature °C": "Temperatura °C",
      "Wind Speed km / h": "Velocità del vento km / h",
      "Humidity %": "Umidità %",
      "Rainfall mm": "Precipitazione mm",
      "Create Field Activity": "Crea attività di campo",
      "Creating the Field Activity...": "Creando l'attività di campo...",
      "Are you sure to delete this field activity?": "Sei sicuro di voler eliminare questa attività di campo?",
      "Delete Field Activity": "Elimina attività di campo",
      "Deleting the Field Activity...": "Eliminando attività di campo...",
      "Edit Field Activities": "Modifica attività di campo",
      "Edit Field Activity": "Modifica attività di campo",
      "Editing the Field Activity...": "Aggiornando l'attività di campo...",
      "Seed / Planting": "Semina / Piantumazione",
      "Field Activity": "Attività di campo",
      "Date of Hire": "Data di assunzione",
      "Operator Cost Registered": "Registrazione dei costi degli operatori",
      "Create Operator Cost": "Crea costo operatore",
      "No Operator are available for your account.": "Nessun operatore è disponibile per il tuo account.",
      "First you have to create an operator": "Prima devi creare un operatore",
      "No Farm are available for your account.": "Nessuna azienda agricola è disponibile per il tuo account.",
      "First you have to create an Farm": "Prima devi creare una azienda agricola",
      "Please set the year operotor cost": "Per favore inserisci il costo annuo dell'operatore",
      "Create a Operator Cost": "Crea il costo dell'operatore",
      "Operator year salary": "Salario annuale dell'operatore",
      "Creating the Operator Cost...": "Creano il costo dell'operatore...",
      "Are you sure to delete this operator cost": "Sei sicuro di voler eliminare il costo dell'operatore?",
      "Operator Cost Detail": "Dettagli del costo dell'operatore",
      "Delete Operator Cost": "Elimina costo operatore",
      "Deleting the Operator Cost...": "Eliminazione del costo operatore...",
      "Duplicate a Operator Cost": "Duplica il costo dell'operatore",
      "Duplicate Operator Cost": "Duplica il costo dell'operatore",
      "Duplicating the Operator Cost...": "Duplicando il costo dell'operatore...",
      "Edit a Operator Cost": "Modifica il costo dell'operatore",
      "Edit Operator Cost": "Modifica il costo dell'operatore",
      "Editing the Operator Cost...": "Modificando il costo dell'operatore...",
      "Create Revenue": "Crea Ricavo",
      "Revenue Registered": "Ricavi registrati",
      "Total Revenue": "Ricavo totale",
      "Please set the total revenue": "Per favore inserisci il valore del ricavo totale",
      "Please set the type of the revenue": "Per favore inserisci la tipologia di ricavo",
      "Register a Revenue": "Registra un ricavo",
      "Select the type of revenue:": "Seleziona una tipologia di ricavo:",
      "Direct Payments": "Pagamenti diretti",
      "pac": "PAC",
      "indinizzi": "Indinizzi",
      "Agricultural compensation": "Compensazioni agricole",
      "Creating the Revenue...": "Creando il ricavo...",
      "Are you sure to delete this revenue?": "Sei sicuro di voler eliminare questo ricavo?",
      "Delete Revenue": "Elimina ricavo",
      "Deleting the Revenue...": "Eliminando il ricavo...",
      "Duplicate a Revenue": "Duplica un ricavo",
      "Duplicate Revenue": "Duplica il revenue",
      "Duplicating the Revenue...": "Duplicando il ricavo...",
      "Edit a Revenue": "Modifica il ricavo",
      "Edit Revenue": "Modifica il ricavo",
      "Editing the Revenue...": "Modificando il ricavo...",
      "Farmer Economy Analysis": "Analisi Economica dell'azienda agricola",
      "Farm Profit": "Profitto dell'azienda",
      "Farm  Revenue": "Ricavo dell'azienda",
      "Farm Total Cost": "Costi totali dell'azienda",
      "Economy Data": "Dati economici",
      "Activity": "Attività",
      "Cost/Price": "Costo/Prezzo",
      "Quantity/Hours": "Quantità/Ore",
      "Cost allocation": "Ripartizione dei costi",
      "Allocation of Revenues": "Ripartizione dei ricavi",
      "Revenues": "Ricavi",
      "Monthly Costs and Revenues": "Costi e ricavi mensili",
      "Cash Flow": "Flusso di cassa",
      "Air Temperature": "Temperatura dell'aria",
      "Atmospheric Pressure": "Pressione atmosferica",
      "Rainfall": "Precipitazione",
      "Air Humidity": "Umidità dell'aria",
      "Evapotraspiration": "Evapotraspirazione",
      "Degree Day": "Gradi giorno",
      "TWH Index": "Indice TWH",
      "Dew Point": "Punto di rugiada",
      "Gust": "Raffica",
      "Real time data or Historic data?": "Dati real time o Dati storici?",
      "Last 24 Hours Data": "Dati delle ultime 24 ore",
      "Degree Days": "Gradi giorno",
      "Leaf Wetness": "Bagnatura Fogliare",
      "Irrigation Analysis": "Analisi di irrigazione",
      "Download Operator - Format Excel": "Download Operatori - Formato Excel",
      "Download Warehouse - Format Excel": "Download Magazzini - Formato Excel",
      "Download Field Note - Format Excel": "Download Note di Campo - Formato Excel",
      "Download Yield - Format Excel": "Download Dati di resa - Formato Excel",
      "Download Field Activity - Format Excel": "Download Attività di campo - Formati Excel",
      "Download Other Revenue - Format Excel": "Download Ricavi - Formato Excel",
      "Download Operator Cost - Format Excel": "Download Costo Operatori - Formato Excel",
      "generic": "Generico",
      "phatogens": "Patogeni",
      "insect": "Insetti",
      "weed": "Infestanti",
      "waterlogging": "Ristagno Idrico",
      "genericdamage": "Danno Generico",
      "fieldsurvey": "Indagine sul campo",
      "cropstatus": "Stato della coltura",
      "acaricida": "Acaricida",
      "aficida": "Aficida",
      "antideriva": "Anti deriva",
      "antidoto": "Antidoto",
      "antigermogliante": "Anti Germogliante",
      "antiriscaldo": "Anti Riscaldo",
      "attrattivo": "Attrattivo",
      "bagnante": "Bagnante",
      "coaudiuvante": "Coaudiuvante",
      "diradante": "Diradante",
      "feromone": "Feromone",
      "fitoregolatore": "Fitoregolatore",
      "geodisinfestante": "Geodisinfestante",
      "repellente": "Repellente",
      "sinergizzante": "Sinergizzante",
      "adiuvante": "Adiuvante",
      "fungicida": "Fungicida",
      "diserbante": "Diserbante",
      "insetticida": "Insetticida",
      "molluschicida": "Molluschicida",
      "organic_product": "Prodotto Organico",
      "nematocida": "Nematocida",
      "sostanza_di_crescita": "Sostanza di crescita",
      "biostimolante": "Biostimolante",
      "ritirato": "Ritirato",
      "attivo": "Attivo",
      "kg_ha": "kg/ha",
      "l_ha": "l/ha",
      "unita_ha": "Unità/ha",
      "anno": "Anno",
      "ciclo": "Ciclo",
      "stagione": "Stagione",
      "semente": "Semente",
      "piantine": "Piantine",
      "libre_acro": "Libre/acro",
      "minerale": "Minerale",
      "organico": "Organico",
      "tillage": "Lavorazione",
      "sowing": "Semina",
      "defence": "Difesa",
      "irrigation": "Irrigazione",
      "nutrition": "Nutrizione",
      "sereno": "Sereno",
      "nuvoloso": "Nuvoloso",
      "variabile": "Variabile",
      "molto_nuvoloso": "Molto nuvoloso",
      "coperto": "Coperto",
      "nebbia": "Nebbia",
      "coperto_nebbia": "Coperto nebbia",
      "variabile_con_rovesci": "Variabile con rovesci",
      "temporale": "Temporale",
      "coperto_con_neve": "Coperto con neve",
      "pioggia_misto_neve": "Pioggia misto neve",
      "erbacee_botticella": "Erbacee botticella",
      "erbacee_fioritura": "Erbacee fioritura",
      "erbacee_invaiatura": "Erbacee invaiatura",
      "erbacee_allegagione": "Erbacee allegagione",
      "erbacee_ingrossamento": "Erbacee ingrossamento",
      "erbacee_germinazione": "Erbacee germinazione",
      "erbacee_emergenza_infioriscenza": "Erbacee emergenza infioriscenza",
      "erbacee_sviluppo_fogliare": "Erbacee sviluppo fogliare",
      "erbacee_maturazione": "Erbacee maturazione",
      "erbacee_senescenza": "Erbacee senescenza",
      "erbacee_levata": "Erbacee levata",
      "erbacee_accestimento": "Erbacee accestimento",
      "arboree_gemme_inverno": "Arboree gemme inverno",
      "arboree_apertura_gemme": "Arboree apertura gemme",
      "arboree_ripresa_vegetativa": "Arboree ripresavegetativa",
      "arboree_sviluppo_germogli": "Arboree sviluppo germogli",
      "arboree_foglie_distese": "Arboree foglie distese",
      "arboree_grappoli_visibili": "Arboree grappoli visibili",
      "arboree_grappoli_separati": "Arboree grappoli separati",
      "arboree_ingrossamento_frutti": "Arboree ingrossamento frutti",
      "arboree_pre_chiusura_grappolo": "Arboree pre-chiusura grappolo",
      "arboree_chiusura_grappolo": "Arboree chiusura grappolo",
      "arboree_indurimento_nocciolo": "Arboree indurimento nocciolo",
      "arboree_invaitura": "Arboree invaitura",
      "arboree_riposo_vegetativo": "Arboree riposo vegetativo",
      "arboree_fioritura": "Arboree fioritura",
      "arboree_allegagione": "Arboree allegagione",
      "arboree_pianta_ferma": "Arboree pianta ferma",
      "arboree_germogliamento": "Arboree germogliamento",
      "arboree_germogli_5_10_cm": "Arboree germogli 5-10 cm",
      "arboree_comparsa_boccioli_floreali": "Arboree comparsa boccioli floreali",
      "arboree_bottone_bianco": "Arboree bottone bianco",
      "arboree_crescita": "Arboree crescita",
      "arboree_semi_neri": "Arboree semi neri",
      "arboree_pre_raccolta": "Arboree pre-raccolta",
      "arboree_raccolta": "Arboree raccolta",
      "basso": "Basso",
      "medio": "Medio",
      "alto": "Alto",
      "aratura": "Aratura",
      "erpicatura": "Erpicatura",
      "zappatura": "Zappatura",
      "sarchiatura": "Sarchiatura",
      "frangizollatura": "Frangizollatura",
      "fresatura": "Fresatura",
      "rippatura": "Rippatura",
      "rullatura": "Rullatura",
      "compattazione": "Compattazione",
      "pacciamatura": "Pacciamatura",
      "trinciatura": "Trinciatura",
      "Seedlings": "Semente",
      "Pounds per acre": "Pound ad acro",
      "Mineral Product": "Prodotto Minerale",
      "Nutrition": "Nutrizione",
      "Historic Data": "Dati Storici",
      "Withdrawn": "Ritirato",
      "Active": "Attivo",
      "Year": "Annuale",
      "Cycle": "Ciclo",
      "Season": "Stagione",
      "Defense": "Difesa",
      "Active or Retrivied:": "Attivo o ritirato",
      "Please fill all the field": "Per favore completa ogni sezione",
      "Please fill all the form to create a field activities": "Per favore completa ogni sezione per creare una attività di campo",
      "Other Revenue": "Altri Ricavi",
      "Harvest": "Raccolta",
      "Please insert the date of the revenue": "Per favore inserisci la data relativa al ricavo",
      "Please enter the start date of the operator cost": "Per favore inserisci la data di inizio del salario dell'operatore",
      "Pear_fruit": "Pera",
      "Dry_pulses": "Legumi secchi",
      "Strawberries": "Fragola",
      "Tobacco": "Tabacco",
      "Other_fibre_and_oleaginous_crops": "Altre corlture fibrose e oleoginose",
      "Other_fresh_vegetables": "Orticole",
      "Your Account is not PRO": "Il tuo account non è PRO",
      "Upgrade to PRO": "Fai l'upgrade a PRO",
      "Something Went Wrong During the Payment": "Qualcosa è andato storto durante la fase di pagamento",
      "Please send us an email to info@automaticfarmsolution.com": "Per favore inviaci una email a info@automaticfarmsolution.com",
      "An operator will answear you as soon as possible": "Un operatore ti risponderà il più rapidamente possibile",
      "VAT Excluded": "IVA esclusa",
      "The total price is 832 € (VAT Included)": "Il prezzo totale è di 832 € (IVA Inclusa)",
      "All the modules of free version": "Tutti i moduli gratuiti",
      "Generation of prescription map with Artificial Intelligence": "Generare la mappa di prescrizione con l'intelligenza artificiale",
      "Capability to connect IoT Sensors": "Possibilità di collegare oltre 15 sensori",
      "Set automatic alerts": "Impostare delle allerte automatiche",
      "Artificial Intelligence Digital Soil Mapping": "Fare le mappe digitali del suolo con l'intelligenza artificiale",
      "Advanced Report & Documentation": "Report avanzati & Documentazione",
      "Online Live Training of the Platform": "Corso Online per l'uso corretto della piattaforma",
      "Number of Plant":"Numero delle piante",
      "Number of note":"Numero delle note",
      "Note":"Note",
      "Add Note":"Aggiungi Note",
      "Download Note":"Scaricata Note",
      "List of the note":"Lista delle note registrate",
      "User":"Utente",
      "Plant":"Pianta",
      "Image":"Immagine",
      "Created at":"Creata il",
      "Detail":"Dettagli",
      "No Note are Registrated":"Nessuna nota è state registrata",
      "Plant Number":"Numero della pianta",
      "Upload or Capture Image":"Carica o scatta una immagine",
      "Save":"Salva",
      "Detail Note - ":"Dettaglio Nota - ",
      "User - ":"Utente che ha creato la nota - ",
      "Plant - ":"Pianta - ",
      "Note - ":"Nota - ",
      "Image":"Immagine",
      "Created at - ":"Creata il - ",
      "Are you sure to delete the note?":"Sei sicuro di voler eliminare la nota?",
      "Update Note":"Aggiorna Nota",
      "New registration":"Nuova registrazione",
      "You don't have yet the permission to use this application":"Tu non possiedi ancora i permessi per utilizzare questa applicazione",
      "Please use the following button to send a request to the wine manager to be activated":"per poter iniziare ad usare l'applicazione, usa il pulsante qua di seguito per inviare la richiesta al wine manager per la sua approvazione",
      "Send the request":"Invia la richiesta",
      "The plant cannot be empty":"Il numero della pianta non può essere vuota",
      "The note cannot be empty":"La nota non può essere vuota",
      "You must upload a image":"Devi caricare una immagine"
    }
  },
  "ES": {
    "translation": {
      "Hello": "Hola",
      "Yes": "Si",
      "No": "No",
      "Temporary_grassland": "Pastizal temporal",
      "Grassland": "Pastizal",
      "Clovers": "Tréboles",
      "Olive_groves": "Olivares",
      "Olive": "Oliva",
      "Oranges": "Naranjas",
      "Apple_fruit": "Manzana",
      "Apple": "Manzana",
      "Cherry_fruit": "Cereza",
      "Cherry": "Cereza",
      "Nuts_trees": "Árboles de frutos secos",
      "Nuts": "Frutos secos",
      "Rye": "Centeno",
      "Potatoes": "Patatas",
      "Triticale": "Triticale",
      "Sugar_beet": "Remolacha azucarera",
      "Sugar Beet": "Remolacha azucarera",
      "Soya": "Soja",
      "Cotton": "Algodón",
      "Tomatoes": "Tomates",
      "Set the analysis": "Establecer el anali",
      "Do you want to define the number of zone in Automatically way or Manual?": "¿Quieres definir el número de zona de forma automática o manual?",
      "If is set Manual, how many zones do you want to create?": "Si se establece de forma manual, ¿cuántas zonas deseas crear?",
      "Select the Pest Model To Use": "Selecciona el modelo de plagas a utilizar",
      "Farmer Registred": "Agricultor registrado",
      "Phosphorus": "Fósforo",
      "Field Registred": "Campo registrado",
      "Durum_wheat": "Trigo duro",
      "Download data": "Descargar datos",
      "The email is empty": "El correo electrónico está vacío",
      "The password is empty": "La contraseña está vacía",
      "The user is not active!": "¡El usuario no está activo!",
      "The email is used": "El correo electrónico ya está en uso",
      "Forgot password?": "¿Olvidaste la contraseña?",
      "Create new account": "Crear nueva cuenta",
      "Check your email inbox and follow the instructions to change the password.": "Verifica tu bandeja de entrada de correo electrónico y sigue las instrucciones para cambiar la contraseña.",
      "Go back to Login": "Volver al inicio de sesión",
      "The email doesn't exist in our database!": "¡El correo electrónico no existe en nuestra base de datos!",
      "Change Password": "Cambiar contraseña",
      "Send Email": "Enviar correo electrónico",
      "Sending email...": "Enviando correo electrónico...",
      "The first password is empty": "La primera contraseña está vacía",
      "The confirm password is empty": "La confirmación de la contraseña está vacía",
      "The password don't match": "Las contraseñas no coinciden",
      "Password change failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Error al cambiar la contraseña. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Se produjo un error al cambiar la contraseña. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "Confirm Password": "Confirmar contraseña",
      "Changing Password...": "Cambiando contraseña...",
      "The password has been changed with success. Now use the new password to login.": "La contraseña se ha cambiado con éxito. Ahora utiliza la nueva contraseña para iniciar sesión.",
      "In order to register you should accept the Privacy Policy": "Para registrarte, debes aceptar la Política de privacidad",
      "In order to register you should provide your name": "Para registrarte, debes proporcionar tu nombre",
      "In order to register you should provide a valid email": "Para registrarte, debes proporcionar un correo electrónico válido",
      "The current email is used, please use another one!": "El correo electrónico actual ya está en uso, por favor usa otro",
      "Registration": "Registro",
      "Name": "Nombre",
      "I agree with the": "Estoy de acuerdo con la",
      "Register": "Registrarse",
      "Registration...": "Registrando...",
      "Already have an account?": "¿Ya tienes una cuenta?",
      "Check your email inbox and follow the instructions to activate your account.": "Verifica tu bandeja de entrada de correo electrónico y sigue las instrucciones para activar tu cuenta.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "La activación de tu cuenta falló. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "An error occurred during the activation of your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Se produjo un error durante la activación de tu cuenta. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "¿Quieres activar tu cuenta?",
      "Activate my account": "Activar mi cuenta",
      "Activation...": "Activando...",
      "Your Account has been activated. Now you can login": "Tu cuenta ha sido activada. Ahora puedes iniciar sesión",
      "Welcome!": "¡Bienvenido!",
      "My profile": "Mi perfil",
      "Settings": "Configuraciones",
      "Support": "Soporte",
      "Logout": "Cerrar sesión",
      "Farm": "Granja",
      "Field": "Campo",
      "Field Notebook": "Cuaderno de campo",
      "Agrometeo": "Agrometeo",
      "Weather": "Clima",
      "Climatic Index": "Índice climático",
      "Satellite": "Satélite",
      "Prescription Map": "Mapa de prescripción",
      "Sensors": "Sensores",
      "Summary": "Resumen",
      "Analysis": "Análisis",
      "Forecast": "Pronóstico",
      "Anomaly Detection": "Detección de Anomalías",
      "Comparation": "Comparación",
      "Allert": "Alerta",
      "Bio Trap": "Trampa Biológica",
      "Agronomic tools": "Herramientas Agronómicas",
      "Nutrient Balance": "Balance de Nutrientes",
      "Soil Data": "Datos del Suelo",
      "Irrigation": "Riego",
      "Pest Model": "Modelo de Plagas",
      "Artificial Intelligence": "Inteligencia Artificial",
      "Digital Consulting": "Asesoramiento Digital",
      "Crop Models & simulations": "Modelos y Simulaciones de Cultivos",
      "Soil Mapping & Carbon Credits": "Mapeo de Suelos y Créditos de Carbono",
      "Organic vs Conventional": "Orgánico vs Convencional",
      "Connections & Data Sharing": "Conexiones y Compartir Datos",
      "Upgrade your profile": "Mejora tu perfil",
      "Register a new farmer": "Registrar un nuevo agricultor",
      "Farmer Company Name:": "Nombre de la empresa agrícola",
      "Farmer First Name:": "Nombre del Agricultor:",
      "Farmer Surname:": "Apellido del Agricultor:",
      "Farmer Country:": "País del Agricultor:",
      "Farmer City:": "Ciudad del Agricultor:",
      "Farmer Address:": "Dirección del Agricultor:",
      "Farmer Email:": "Correo Electrónico del Agricultor:",
      "Farmer Phone Number:": "Número de Teléfono del Agricultor:",
      "Create Farm": "Crear Granja",
      "Go back": "Volver",
      "Restart Creation": "Reiniciar Creación",
      "Creating the farm...": "Creando la granja...",
      "Farm Registered": "Granja Registrada",
      "No farmer are registered": "No hay agricultores registrados",
      "If you want to start using this application, you should start by registering a new farmer.": "Si deseas comenzar a utilizar esta aplicación, debes empezar registrando a un nuevo agricultor.",
      "Farm Name": "Nombre de la Granja",
      "Surname": "Apellido",
      "Email": "Correo Electrónico",
      "Phone Number": "Número de Teléfono",
      "Edit": "Editar",
      "Delete": "Eliminar",
      "Are you sure to remove the following farmer?": "¿Estás seguro de eliminar al siguiente agricultor?",
      "Name of the farmer:": "Nombre del agricultor:",
      "Surname of the farmer:": "Apellido del agricultor:",
      "Country of the farmer:": "País del agricultor:",
      "City of the farmer:": "Ciudad del agricultor:",
      "Address of the farmer:": "Dirección del agricultor:",
      "Email of the farmer:": "Correo Electrónico del agricultor:",
      "Phone Number of the farmer:": "Número de Teléfono del agricultor:",
      "Delete Farmer": "Eliminar Agricultor",
      "Invalid email address": "Dirección de correo electrónico no válida",
      "Invalid phone number": "Número de teléfono no válido",
      "Add Farm": "Agregar Granja",
      "Farmer Registered": "Agricultor Registrado",
      "Farmer": "Agricultor",
      "No registered farm": "No hay granjas registradas",
      "Loading": "Cargando",
      "Update Farm": "Actualizar Granja",
      "Updating the farm...": "Actualizando la granja...",
      "The Name of the farmer is empty": "El nombre del agricultor está vacío",
      "The Surname of the farmer is empty": "El apellido del agricultor está vacío",
      "The CountryCode of the farmer is empty": "El código de país del agricultor está vacío",
      "The City of the farmer is empty": "La ciudad del agricultor está vacía",
      "The Address of the farmer is empty": "La dirección del agricultor está vacía",
      "The Email of the farmer is empty": "El correo electrónico del agricultor está vacío",
      "The Phone Number of the farmer is empty": "El número de teléfono del agricultor está vacío",
      "An error occurred while updating the farm. If the problem persists, please contact us at automaticfarmsolution@gmail.com": "Se produjo un error al actualizar la granja. Si el problema persiste, contáctanos en automaticfarmsolution@gmail.com",
      "Create Field": "Crear Campo",
      "You don't have any farm registred, first you have to set at least one farmer": "No tienes ninguna granja registrada, primero debes configurar al menos un agricultor",
      "Field name:": "Nombre del campo:",
      "Crop Rotation:": "Rotación de cultivos:",
      "Crop Rotation": "Rotación de cultivos",
      "Perennial crop": "Cultivo perenne",
      "Two year crop rotation": "Rotación de cultivos de dos años",
      "Three year crop rotation": "Rotación de cultivos de tres años",
      "Actual Crop": "Cultivo actual",
      "Alfalfa": "Alfalfa",
      "Barley": "Cebada",
      "Maize": "Maíz",
      "Durum Wheat": "Trigo duro",
      "The next year Crop": "Cultivo del próximo año",
      "The crop in two years' time": "Cultivo en dos años",
      "Organic": "Orgánico",
      "Conventional": "Convencional",
      "Draw the edge of your field:": "Dibuja el borde de tu campo:",
      "The field name is empty": "El nombre del campo está vacío",
      "Please draw the edge of your field in the map": "Por favor, dibuja el borde de tu campo en el mapa",
      "An error occurred while creating the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Se produjo un error al crear el campo. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "List of the field": "Lista de campos",
      "Field Name:": "Nombre del campo:",
      "Field Detail": "Detalles del campo",
      "Crop:": "Cultivo:",
      "Agronomic Management:": "Gestión agronómica:",
      "Are you sure to delete this field?": "¿Estás seguro de eliminar este campo?",
      "Delete Field": "Eliminar Campo",
      "Field:": "Campo:",
      "Loading...": "Cargando...",
      "Edit Field": "Editar Campo",
      "Draw again the edge of your field:": "Dibuja nuevamente el borde de tu campo:",
      "In red there is the old field": "En rojo está el campo antiguo",
      "An error occurred while editing the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Se produjo un error al editar el campo. Si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "Try Again": "Intentar de nuevo",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Algo salió mal. Por favor, actualiza la página y si el problema persiste, por favor envíanos un correo electrónico a automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Selecciona el campo para analizar",
      "View Satellite Image": "Ver imagen satelital",
      "Field Details": "Detalles del Campo",
      "Actual Crop:": "Cultivo actual:",
      "Time series plot": "Gráfico de series temporales",
      "Satellite Image": "Imagen satelital",
      "Select one field": "Selecciona un campo",
      "Field Boundary": "Límite del campo",
      "Select the Vegetation Index": "Selecciona el Índice de Vegetación",
      "Set the prescription map": "Configura el mapa de prescripción",
      "Do you want to define the number of zones in an Automatic way or Manual?": "¿Quieres definir el número de zonas de manera automática o manual?",
      "Automatic": "Automático",
      "Manual": "Manual",
      "If it is set to Manual, how many zones do you want to create?": "Si está configurado como Manual, ¿cuántas zonas deseas crear?",
      "Max fertilizer that you want to apply?": "¿Máxima cantidad de fertilizante que deseas aplicar?",
      "Which strategy do you want to use?": "¿Qué estrategia deseas utilizar?",
      "Provide higher amount of fertilizer where the crop is stronger": "Proporcionar una mayor cantidad de fertilizante donde el cultivo es más fuerte",
      "Provide lower amount of fertilizer where the crop is stronger": "Proporcionar una menor cantidad de fertilizante donde el cultivo es más fuerte",
      "Percentage of difference of fertilizer between zones": "Porcentaje de diferencia de fertilizante entre zonas",
      "Selected Percentage:": "Porcentaje seleccionado:",
      "Create": "Crear",
      "Send to Jhon Deere Operation Center": "Enviar al Centro de Operaciones Jhon Deere",
      "Download": "Descargar",
      "Downloading...": "Descargando...",
      "Downloading": "Descargando",
      "Sending": "Enviando",
      "Sending...": "Enviando...",
      "Something went wrong. If the problem persists please send us an email at automaticfarmsolution@gmail.com": "Algo salió mal. Si el problema persiste, por favor envíenos un correo electrónico a automaticfarmsolution@gmail.com",
      "Creating the field...": "Creando el campo...",
      "Update Field": "Actualizar Campo",
      "Updating Field": "Actualizando Campo",
      "Integration done": "Integración completada",
      "You have done the integration with Jhon Deere": "Ha completado la integración con Jhon Deere",
      "Start the integration with the Operation Center Jhon Deere": "Iniciar la integración con el Centro de Operaciones Jhon Deere",
      "A problem occurred during the integration with Jhon Deere": "Ocurrió un problema durante la integración con Jhon Deere",
      "Start the integration with the Operation Center John Deere": "Iniciar la integración con el Centro de Operaciones John Deere",
      "Connect to Operation Center Jhon Deere": "Conectar al Centro de Operaciones Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "Si desea enviar el mapa de prescripción al centro de operaciones, puede conectar esta plataforma directamente a su Centro de Operaciones",
      "Satellite Image analysis": "Análisis de imagen por satélite",
      "Please enable the geolocation in order to get the weather data and visualization": "Por favor, habilite la geolocalización para obtener datos meteorológicos y visualización",
      "Actual Weather Data": "Datos meteorológicos actuales",
      "Weather Map": "Mapa meteorológico",
      "Select the climate variable to see": "Seleccione la variable climática para ver",
      "Forecast Temperature": "Temperatura Pronosticada",
      "Wind": "Viento",
      "Pressure": "Presión",
      "Snow": "Nieve",
      "Precipitation": "Precipitación",
      "Clouds": "Nubes",
      "Temperature": "Temperatura",
      "Add / Modify / Delete": "Agregar / Modificar / Eliminar",
      "Field Registered": "Campo Registrado",
      "Field Name": "Nombre del Campo",
      "Select the field to view": "Seleccione el campo para ver",
      "Number of farmer": "Número de agricultores",
      "Number of field": "Número de campos",
      "Hectares Monitored": "Hectáreas Monitoreadas",
      "Anomalies": "Anomalías",
      "IoT Number": "Número de IoT",
      "Last Reading": "Última Lectura",
      "Soil Temperature": "Temperatura del Suelo",
      "Soil Humidity": "Humedad del suelo",
      "Electrical Conductivity": "Conductividad Eléctrica",
      "Organic Carbon": "Carbono Orgánico",
      "Nitrogen": "Nitrógeno",
      "Potassium": "Potasio",
      "Battery": "Batería",
      "Select the variable": "Seleccione la variable",
      "Select the IoT Cabin": "Seleccione la Cabina IoT",
      "View data": "Ver datos",
      "Time series Graph": "Gráfico de series temporales",
      "Time Series Comparison": "Comparación de series temporales",
      "Table": "Tabla",
      "Add Alert": "Agregar Alerta",
      "Alert Registered": "Alerta Registrada",
      "Sensor": "Sensor",
      "Variable": "Variable",
      "Condition": "Condición",
      "Value": "Valor",
      "Number of farmers": "Número de agricultores",
      "Number of fields": "Número de campos",
      "No Alert Registered": "Ninguna Alerta Registrada",
      "No IoT is linked to your account": "No hay IoT vinculado a su cuenta",
      "No IoT alert is set": "No hay alerta de IoT configurada",
      "Please select the IoT Cabin": "Por favor, seleccione la Cabina IoT",
      "Please select the variable": "Por favor, seleccione la variable",
      "Please select the logic to apply": "Seleccione la lógica a aplicar",
      "Please set the threshold value": "Por favor, establezca el valor umbral",
      "Select the logic": "Seleccionar la lógica",
      "Set the threshold value": "Establecer el valor umbral",
      "Adding the alert...": "Añadiendo la alerta...",
      "Higher than": "Mayor que",
      "Lower than": "Menor que",
      "IoT Position": "Posición del IoT",
      "Create Alert": "Crear alerta",
      "Are you sure to remove this alert?": "¿Está seguro de eliminar esta alerta?",
      "Cabin Number:": "Número de cabina:",
      "Logic - Higher or Lower:": "Lógica - Mayor o menor:",
      "Threshold Value:": "Valor umbral:",
      "Delete Alert": "Eliminar alerta",
      "Update Alert": "Actualizar alerta",
      "Updating the alert...": "Actualizando la alerta...",
      "Please the expected yield (q/ha)": "Por favor, introduzca el rendimiento esperado (q/ha)",
      "Please the fertilizer title (%)": "Por favor, introduzca el porcentaje de fertilizante",
      "Setting of the Calculation": "Configuración del cálculo",
      "Select the Nutrient": "Seleccione el nutriente",
      "Select the Crop": "Seleccione el cultivo",
      "Set the expected yield (q/ha)": "Establecer el rendimiento esperado (q/ha)",
      "Title of fertilizer - range 0-100 %": "Título del fertilizante - rango 0-100 %",
      "Calculate": "Calcular",
      "The amount of fertilizer that you have provided to the crop for the entire crop growth cycle is:": "La cantidad de fertilizante que ha proporcionado al cultivo durante todo el ciclo de crecimiento del cultivo es:",
      "Set the field to get the soil data": "Establecer el campo para obtener datos del suelo",
      "Hello, I'm your Digital Agronomist Consultant! Ask me anything!": "¡Hola, soy tu Consultor Agrónomo Digital! ¡Pregúntame lo que quieras!",
      "The digital agronomist consultant is typing": "El consultor agrónomo digital está escribiendo",
      "Send a Message": "Enviar un mensaje",
      "Digital agronomist consultant": "Consultor agrónomo digital",
      "You have done the integration with John Deere": "Has completado la integración con John Deere",
      "Please fill all the requests": "Por favor, complete todas las solicitudes",
      "Set the analysis parameter": "Establecer el parámetro de análisis",
      "Date of sowing": "Fecha de siembra",
      "Select the soil type": "Seleccione el tipo de suelo",
      "Sand": "Arena",
      "Loamy Sand": "Arena limosa",
      "Sandy Loam": "Franco arenoso",
      "Loam": "Franco",
      "Silty Loam": "Franco limoso",
      "Silt": "Limo",
      "Sandy Clay Loam": "Franco arcilloso arenoso",
      "Clay Loam": "Franco arcilloso",
      "Silty Clay Loam": "Franco arcilloso limoso",
      "Sandy Clay": "Arcilla arenosa",
      "Clay": "Arcilla",
      "Sunflower": "Girasol",
      "Millet": "Mijo",
      "Rice": "Arroz",
      "Sugarbeet": "Remolacha azucarera",
      "Sorghum": "Sorgo",
      "Soybean": "Soja",
      "Canola": "Colza",
      "Tomato": "Tomate",
      "Cabbage": "Col",
      "Potato": "Patata",
      "Sugarcane": "Caña de azúcar",
      "Select the Variety": "Seleccione la variedad",
      "Do you want to provide fertilization?": "¿Desea proporcionar fertilización?",
      "Date of fertilization": "Fecha de fertilización",
      "Define the amount of Nitrogen provided (kg N / ha)": "Defina la cantidad de nitrógeno proporcionada (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Defina la cantidad de fósforo proporcionada (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Defina la cantidad de potasio proporcionada (kg K / ha)",
      "Do you want to provide irrigation": "¿Desea proporcionar riego?",
      "Date of irrigation": "Fecha de riego",
      "Define the amount of water mm": "Defina la cantidad de agua en mm",
      "Next": "Siguiente",
      "Submit": "Enviar",
      "Temporal creation of the yield": "Creación temporal del rendimiento",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Antes de ejecutar el modelo, debes definir el tipo de cultivo. Ve a la sección 'Campo' y actualiza la información.",
      "Set the Field where apply the analysis": "Establecer el campo donde aplicar el análisis",
      "Set the Farmer": "Establecer el agricultor",
      "Set the Field": "Establecer el campo",
      "Run The Analysis": "Ejecutar el análisis",
      "NDVI Comparation": "Comparación de NDVI",
      "SAR Comparation": "Comparación de SAR",
      "Probabilistic Map": "Mapa Probabilístico",
      "% Convenctional": "% Convencional",
      "% Organic": "% Orgánico",
      "The field was declared by the farmer as": "El agricultor declaró el campo como",
      "Select the start year to analyze": "Selecciona el año de inicio para analizar",
      "Select the base temperature for the crop": "Selecciona la temperatura base para el cultivo",
      "Select the max temperature for the crop": "Selecciona la temperatura máxima para el cultivo",
      "Start analysis": "Iniciar el análisis",
      "Upgrade your account": "Mejora tu cuenta",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "Si deseas acceder a los datos satelitales y generar el mapa de prescripción, debes mejorar tu cuenta a PRO",
      "Not Now": "Ahora no",
      "Upgrade your account to PRO": "Mejora tu cuenta a PRO",
      "Your Account is PRO": "Tu cuenta es PRO",
      "You can access to all the service": "Puedes acceder a todos los servicios",
      "Your Account is not PRO": "Tu cuenta no es PRO",
      "Purchase the PRO Service": "Compra el servicio PRO",
      "The total price is": "El precio total es",
      "The payment was successful": "El pago se realizó con éxito",
      "Now you should logout and login again to access all the features just unlocked": "Ahora debes cerrar sesión e iniciarla nuevamente para acceder a todas las funciones desbloqueadas",
      "By purchasing this service you will access:": "Al comprar este servicio, accederás a:",
      "Prescription maps that can be integrated with your display tractors": "Mapas de prescripción que se pueden integrar con tus tractores con pantalla",
      "Visualization & Analysis of sensor data": "Visualización y análisis de datos del sensor",
      "Set alert": "Configurar alerta",
      "All the agronomic tools": "Todas las herramientas agronómicas",
      "AI Tools": "Herramientas de IA",
      "Synchronization with Operation Center John Deere": "Sincronización con el Centro de Operaciones de John Deere",
      "Vineyards": "Viñedos",
      "perennial": "Perenne",
      "biennial": "Bienal",
      "triennial": "Trienal",
      "Kiwi": "Kiwi",
      "Garlic": "Ajo",
      "Apricot": "Albaricoque",
      "Orange": "Naranja",
      "Asparagus": "Espárragos",
      "Oats": "Avena",
      "Basil": "Albahaca",
      "Swiss_chard": "Acelga",
      "Beet_leaves": "Hojas de remolacha",
      "Broccoli": "Brócoli",
      "Hemp": "Cáñamo",
      "Artichoke": "Alcachofa",
      "Carrot": "Zanahoria",
      "Cauliflower": "Coliflor",
      "chickpea": "Garbanzo",
      "cucumber": "Pepino",
      "Chicory": "Achicoria",
      "Onion": "Cebolla",
      "Endive": "Endibia",
      "Watermelon": "Sandía",
      "Rapeseed": "Colza",
      "Green_bean_for_industry": "Judía verde para la industria",
      "Fresh_green_bean": "Judía verde fresca",
      "Bean": "Judía",
      "Dry_bean": "Judía seca",
      "Spelt": "Espelta",
      "Grain_fava_bean": "Haba de grano",
      "Favino": "Favino",
      "Fico": "Higo",
      "Fennel": "Hinojo",
      "Strawberry": "Fresa",
      "winter_wheat": "Trigo de invierno",
      "Kaki": "Caqui",
      "Lettuce": "Lechuga",
      "Lentil": "Lentejas",
      "Lemon": "Limón",
      "Linen": "Lino",
      "Lupin": "Altramuz",
      "Corn": "Maíz",
      "Tangerine": "Mandarina",
      "Almond": "Almendra",
      "Eggplant": "Berenjena",
      "Melon": "Melón",
      "Blueberry": "Arándano",
      "Nectarines": "Nectarina",
      "Kernel": "Nuez",
      "Walnut": "Nuez",
      "Bell_pepper": "Pimiento",
      "Pear": "Pera",
      "Peach": "Durazno",
      "Pea": "Chícharo",
      "Leek": "Puerro",
      "Parsley": "Perejil",
      "Radicchio": "Radicchio",
      "Turnip": "Nabo",
      "Radish": "Rábano",
      "Ribes": "Grosella",
      "Shallots": "Chalote",
      "Celery": "Apio",
      "Soy": "Soja",
      "Spinach": "Espinaca",
      "Plum_tree": "Ciruelo",
      "Tabacco": "Tabaco",
      "Vineyard": "Viñedo",
      "Savoy_cabbage": "Col rizada de Saboya",
      "Pumpkin": "Calabaza",
      "Zucchini_for_industry": "Calabacín para la industria",
      "Fresh_zucchini": "Calabacín fresco",
      "Peanuts": "Cacahuetes",
      "Chard": "Acelga",
      "Leaf_beet": "Hojas de remolacha",
      "Hemp_for_seeds": "Cáñamo (semillas)",
      "Hemp_for_fiber": "Cáñamo (fibra)",
      "Cardoon": "Cardo",
      "Chestnut": "Castaño",
      "Cherry_tree": "Cerezo",
      "French_bean": "Judía verde fresca",
      "Fava_bean": "Haba seca",
      "Wheat": "Trigo",
      "Raspberry": " Frambuesa",
      "Flax": "Lino",
      "Ley_grass": "Lupino",
      "Corn_for_grain": "Berenjena",
      "Hazelnut": "Avellana",
      "Pear_tree": "Peral",
      "Peach_tree": "Duraznero",
      "Protein_pea": "Guisante proteico",
      "Processing_tomato": "Tomate para procesar",
      "Table_tomato": "Tomate de mesa",
      "Table_grape": "Uva de mesa",
      "Gooseberry": "Grosella espinosa",
      "Grapevine": "Viña",
      "Clover": "Trébol",
      "Corn_salad": "Ensalada de maíz",
      "Grapevine_for_wine_grapes": "Viña para uvas de vino",
      "Sweet_corn": "Maíz dulce",
      "Silage_corn": "Maíz para ensilaje",
      "Oilseed_rape": "Colza oleaginosa",
      "Field-grown_bell_pepper": "Pimiento cultivado en campo",
      "Pea_for_industry": "Guisante para la industria",
      "Fresh_pea": "Guisante fresco",
      "Greenhouse_table_tomato": "Tomate de mesa de invernadero",
      "Clover_grassland": "Pastizal de trébol",
      "Hill_pastures": "Pastizales de colina",
      "Mixed_grassland_>50_legumes": "Pastizal mixto >50% leguminosas",
      "Artificial_mixed_hill_pastures": "Pastizales mixtos artificiales de colina",
      "Stable_plains": "Llanuras estables",
      "Silage_fodder": "Forraje para ensilaje",
      "Arugula_1st_cut": "Rúcula, 1ª cosecha",
      "Arugula_2nd_cut": "Rúcula, 2ª cosecha",
      "Shallot": "Chalota",
      "Arugula_in_open_field": "Rúcula en campo abierto",
      "Organic vs Conventional:": "Orgánico vs Convencional:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "¿Está seguro de editar el polígono? Si es así, presione nuevamente el botón Actualizar campo",
      "Set the Year of analysis": "Establecer el año de análisis",
      "Set the nutrient to visualize": "Establecer el nutriente a visualizar",
      "Carbonates": "Carbonatos",
      "Soil Electrical Conductivity": "Conductividad eléctrica del suelo",
      "pH": "pH",
      "Please set the year": "Por favor, establezca el año",
      "Please set the nutrient": "Por favor, establezca el nutriente",
      "Please set the farmer": "Por favor, establezca el agricultor",
      "Please set the field": "Por favor, establezca el campo",
      "The map is not ready yet": "El mapa aún no está listo",
      "Please select the object of your request": "Seleccione el objeto de su solicitud",
      "Please report a valid message": "Informe un mensaje válido",
      "Send us your message": "Envíenos su mensaje",
      "Object of the request:": "Objeto de la solicitud:",
      "Object:": "Objeto:",
      "Support Request": "Solicitud de soporte",
      "Upgrade account": "Actualizar cuenta",
      "Problem with the application": "Problema con la aplicación",
      "Other": "Otro",
      "Message of the request:": "Mensaje de la solicitud:",
      "Message:": "Mensaje:",
      "Send message": "Enviar mensaje",
      "My account": "Mi cuenta",
      "Do you want to upgrade your account?": "¿Desea actualizar su cuenta?",
      "Send us a message": "Envíenos un mensaje",
      "Your Message have been sent with success": "Su mensaje se ha enviado con éxito",
      "As soon as possible one of our operators will check your request and answer you": "Tan pronto como sea posible, uno de nuestros operadores verificará su solicitud y le responderá",
      "Frumento_tenero": "Trigo de invierno",
      "Winter Wheat": "Trigo de invierno",
      "Annual percentage increase": "Aumento porcentual anual",
      "Tons of CO2 Sequestered": "Toneladas de CO2 secuestradas",
      "Carbon Credits Generated": "Créditos de carbono generados",
      "Soil Map": "Mapa de suelo",
      "Temporal Variation": "Variación temporal",
      "If you want to access to this module data, you must upgrade your account to PRO": "Si desea acceder a los datos de este módulo, debe actualizar su cuenta a PRO",
      "Run Model": "Ejecución de Modelo",
      "Prescription AI Map": "Mapa de prescripción de IA",
      "Please set the expeted yield": "Por favor, establezca el rendimiento esperado",
      "Please set the number of the application of fertilizer during the year": "Por favor, establezca el número de aplicaciones de fertilizante durante el año",
      "AI Section": "Sección de IA",
      "Which crop?": "¿Qué cultivo?",
      "Which nutrient?": "¿Qué nutriente?",
      "Expected yield quintals per hectares?": "¿Rendimiento esperado en quintales por hectárea?",
      "Percentage of title of the fertilizer": "Porcentaje del título del fertilizante",
      "Number of the application during the year": "Número de aplicaciones durante el año",
      "Search City:": "Buscar ciudad:",
      "Export Document": "Exportar documento",
      "Download Section": "Sección de descarga",
      "Download Field - Format ShapeFile": "Descargar campo - Formato ShapeFile",
      "Download Field - Format Excel": "Descargar campo - Formato Excel",
      "Download Farmer Data - Format Excel": "Descargar datos de agricultores - Formato Excel",
      "Welcome to AIBIOSAT": "Bienvenido a AIBIOSAT",
      "The 1st AI-based application that support the organic certification": "La primera aplicación basada en IA que apoya la certificación orgánica",
      "Before to start to use the application you should follow this step by step welcome on boarding procedure": "Antes de comenzar a usar la aplicación, debes seguir este procedimiento paso a paso de bienvenida",
      "1) Setting up a Farm": "1) Configuración de una Granja",
      "2) Create a Field to be associated with the Farm": "2) Crear un Campo para asociarlo con la Granja",
      "3) You are done": "3) Has terminado",
      "Let's start": "Comencemos",
      "Skip the procedure": "Saltar el procedimiento",
      "Start Using the application": "Comenzar a usar la aplicación",
      "Hectares Deleted": "Hectáreas Eliminadas",
      "Create a farm": "Crear una granja",
      "Create a field": "Crear un campo",
      "Delete Account": "Eliminar cuenta",
      "Are you sure to delete your account?": "¿Estás seguro de eliminar tu cuenta?",
      "Are you sure to delete the account?": "¿Estás seguro de eliminar la cuenta?",
      "Please be carefull cause if you delete your account all the data related will be removed": "Por favor, ten cuidado porque si eliminas tu cuenta, todos los datos relacionados serán eliminados",
      "If you want to proceed, please report your email ": "Si deseas continuar, por favor, reporta tu correo electrónico ",
      " in the following form input": " en el siguiente campo de formulario",
      "Deleting the user...": "Eliminando el usuario...",
      "Delete account": "Eliminar el usario",
      "In order to register you should accept the Terms And Conditions": "Para registrarse debe aceptar los Términos y Condiciones",
      "Account Level:": "Nivel de tu cuenta:",
      "Farmer company name:": "Nombre de la empresa agrícola:",
      "Your account is basic": "Tu cuenta es básica",
      "Close": "Cerrar",
      "Select the Farm": "Selecciona la granja",
      "No Sensors Are Linked to Your Account": "No hay sensores vinculados a tu cuenta",
      "Table of the Soil Data": "Tabla de datos del suelo",
      "Depth start (cm)": "Profundidad inicial (cm)",
      "Depth end (cm)": "Profundidad final (cm)",
      "Mean Bulk density (cg/cm3)": "Densidad a granel media (cg/cm3)",
      "Uncertainty Bulk density (cg/cm3)": "Incertidumbre de densidad a granel (cg/cm3)",
      "Mean Cation Exchange Capacity (mmol/kg)": "Capacidad de intercambio catiónico media (mmol/kg)",
      "Uncertainty Cation Exchange Capacity (mmol/kg)": "Incertidumbre de capacidad de intercambio catiónico (mmol/kg)",
      "Mean Clay (g/kg)": "Arcilla media (g/kg)",
      "Uncertainty Clay (g/kg)": "Incertidumbre de arcilla (g/kg)",
      "Mean Nitrogen (cg/kg)": "Nitrógeno medio (cg/kg)",
      "Uncertainty Nitrogen (cg/kg)": "Incertidumbre de nitrógeno (cg/kg)",
      "Mean pH": "pH medio",
      "Uncertainty pH": "Incertidumbre de pH",
      "Mean Sand (g/kg)": "Arena media (g/kg)",
      "Uncertainty Sand (g/kg)": "Incertidumbre de arena (g/kg)",
      "Mean Silt (g/kg)": "Limo medio (g/kg)",
      "Uncertainty Silt (g/kg)": "Incertidumbre de limo (g/kg)",
      "Mean SOC (g/kg)": "Carbono orgánico medio del suelo (g/kg)",
      "Uncertainty SOC (g/kg)": "Incertidumbre del carbono orgánico del suelo (g/kg)",
      "Operators": "Operadores",
      "Warehouse": "Almacén",
      "Field Notes": "Notas de campo",
      "Yield": "Rendimiento",
      "Seed": "Semilla",
      "Fertilizers": "Fertilizantes",
      "Pesticide": "Pesticida",
      "Field Activities": "Actividades de campo",
      "Operator Cost": "Costo del operador",
      "Revenue": "Ingreso",
      "Economy": "Economía",
      "Operators Registred": "Operadores Registrados",
      "Create Operator": "Crear operador",
      "Create operator": "Crear operador",
      "Last Name": "Apellido",
      "Operator Name:": "Nombre del operador:",
      "Operator Last Name:": "Apellido del operador:",
      "Operator Email:": "Correo electrónico del operador:",
      "Operator Phone:": "Teléfono del operador:",
      "Are you sure to delete this operator?": "¿Estás seguro de eliminar este operador?",
      "Delete Operator": "Eliminar operador",
      "Deleting the Operator...": "Eliminando el operador...",
      "Creating the operator...": "Creando el operador...",
      "Update Operator": "Actualizar operador",
      "Updating Operator...": "Actualizando el operador...",
      "The name is empty": "El nombre está vacío",
      "The last name is empty": "El apellido está vacío",
      "The phone is empty": "El teléfono está vacío",
      "You must select a farm to associate to the operators": "Debes seleccionar una granja para asociar a los operadores",
      "The phone number isn't correct": " El número de teléfono no es correcto",
      "Create Warehouse": "Crear Almacén",
      "Warehouse Name": "Nombre del almacén",
      "Warehouses Registred": "Almacenes Registrados",
      "Warehouse Map": "Mapa de los almacenes",
      "Warehouse Name:": "Nombre del almacén:",
      "Select warehouse location:": "Seleccione la ubicación del almacén:",
      "Creating the Warehouse...": "Creando el almacén...",
      "Please report the position of the warehouse": "Por favor, indica la posición del almacén",
      "Please insert the name of the warehouse": "Por favor ingrese el nombre del almacén",
      "Please insert the farm to relate to the warehouse": "Por favor indique la granja a asociar con el almacén",
      "Edit Warehouse": "Editar almacén",
      "The red point is the old location of the warehouse": "El punto rojo es la ubicación antigua del almacén",
      "Editing the Warehouse...": "Editando el almacén...",
      "Are you sure to delete the warehouse?": "¿Está seguro de que desea eliminar el almacén?",
      "Latitude:": "Latitud:",
      "Longitude:": "Longitud:",
      "Warehouse Farm:": "Granja asociada al almacén:",
      "Delete Warehouse": "Eliminar almacén",
      "Deleting the Warehouse...": "Eliminando el almacén...",
      "Actual Wheater Data": "Datos meteorológicos actuales",
      "Wheater Map": "Mapa meteorológico",
      "Create Field Note": "Crear Nota de Campo",
      "Field Note Registred": "Notas de Campo Registradas",
      "Date": "Fecha",
      "Title of the note": "Título de la nota de campo",
      "Field Note Title:": "Título de la nota de campo:",
      "No Field Note Are Registred": "No hay notas de campo registradas",
      "No Field exist for your account": "No existe campo para tu cuenta",
      "If you want to register an Field Note you should create first an Operator": "Si quieres registrar una nota de campo, primero debes crear un operador",
      "Select field note location:": "Selecciona la ubicación de la nota de campo:",
      "Warehouse location": "Ubicación del almacén",
      "Type of Note": "Tipo de nota",
      "Generic": "Genérica",
      "Pathogens": "Patógenos",
      "Insects": "Insectos",
      "Weed": "Malezas",
      "Waterlogging": "Inundación",
      "Generic Damage": "Daño Genérico",
      "Field Survey": "Encuesta de Campo",
      "Crop Status": "Estado del cultivo",
      "Description": "Descripción",
      "Operator": "Operador",
      "Creating the Field Note...": "Creando la Nota de Campo...",
      "Please insert the title of the field note": "Por favor, inserta el título de la nota de campo",
      "Please set the farm related to the field note": "Por favor, indica la granja relacionada con la nota de campo",
      "Please set the field related to the field note": "Por favor, indica el campo relacionado con la nota de campo",
      "Please insert the type of the field note": "Por favor, inserta el tipo de nota de campo",
      "Please insert the date of the field note": "Por favor, inserta la fecha de la nota de campo",
      "Please insert the operator that create the field note": "Por favor, inserta el operador que crea la nota de campo",
      "type": "Tipo",
      "Title": "Título",
      "Field Note Map": "Mapa de Notas de Campo",
      "Edit Field Note": "Editar Nota de Campo",
      "In red there is the old Field Note": "En rojo está la vieja posición de la nota de campo",
      "Old Field Note": "Vieja nota de campo",
      "Editing the Field Note...": "Editando la Nota de Campo...",
      "Please report the position of the field note": "Por favor, indica la posición de la nota de campo",
      "Field Note Date:": "Fecha de la nota de campo:",
      "Field Note Farm:": "Granja de la nota de campo:",
      "Field Note Field:": "Campo de la nota de campo:",
      "Field Note Operator:": "Operador de la nota de campo:",
      "Field Note Type:": "Tipo de nota de campo:",
      "Field Note Description:": "Descripción de la nota de campo:",
      "Delete Field Note": "Eliminar Nota de Campo",
      "Deleting the Field Note...": "Eliminando la Nota de Campo...",
      "Are you sure to delete this field note?": "¿Está seguro de eliminar esta nota de campo?",
      "Field Note Data": "Datos de las notas de campo",
      "Create Yield data": "Crear datos de rendimiento",
      "Yield Data Registred": "Datos de rendimiento registrados",
      "Harvest Date": "Fecha de cosecha",
      "Crop": "Cultivo",
      "Total Quantity": "Cantidad total",
      "Price": "Precio",
      "Total Cost": "Costo total",
      "No Yield data are registred": "No se han registrado datos de rendimiento",
      "No Operator exist for your account": "No hay ningún operador registrado para su cuenta",
      "If you want to register an Yield you should create first a Operator": "Si desea registrar un rendimiento, primero debe crear un operador",
      "If you want to register an Yield you should create first a Field": "Si desea registrar un rendimiento, primero debe crear un campo",
      "Please select the farm": "Por favor, selecciona la finca",
      "Please select the field": "Por favor seleccione un campo",
      "Please select the operator": "Por favor, selecciona el operador",
      "Please set the harvest date": "Por favor seleccione una fecha de cosecha",
      "Please set the total yield": "Por favor ingrese el rendimiento total",
      "Please set the price of the yield": "Por favor ingrese el precio de venta del rendimiento",
      "Please set the cost of the operation": "Por favor ingrese el costo de la operación de cosecha",
      "Register a Yield": "Registrar un dato de rendimiento",
      "The crop is: ": "El cultivo es: ",
      "Total Yield quintals": "Rendimiento total en quintales",
      "Price selled euro per quintal": "Precio de venta por quintal",
      "Did you use a subcontractors?": "¿Usó subcontratistas?",
      "Subcontractor Name": "Nombre del subcontratista",
      "Total cost of the harvest operation": "Costo total de la operación de cosecha",
      "Create Yield": "Crear rendimiento",
      "Creating the Yield...": "Creando el dato de rendimiento...",
      "Update Yield": "Actualizar rendimiento",
      "Updating the Yield...": "Actualizando el rendimiento...",
      "Are you sure to delete this yield data?": "¿Está seguro de que desea eliminar los datos de rendimiento?",
      "Delete Yield": "Eliminar el dato de rendimiento",
      "Deleting the Yield...": "Eliminando el dato de rendimiento...",
      "Total cost to harvest": "Costo total de la operación de cosecha",
      "Create Seed": "Crear Semilla",
      "Seed Registred": "Semilla Registrada",
      "Macro Type": "Tipo Macro",
      "Type": "Tipo",
      "Commercial Product Name": "Nombre del Producto Comercial",
      "Organic Approved": "¿Producto Aprobado para Agricultura Orgánica?",
      "Duplicate": "Duplicar",
      "Please select the unit doses": "Por favor seleccione la dosis",
      "Please set the name of the seed": "Por favor ingrese el nombre del producto comercial",
      "Please select the type of the seed": "Por favor seleccione el tipo de semilla/plantines",
      "Please set the productor name": "Por favor, seleccione el nombre del productor",
      "Please set the crop name": "Por favor, ingrese el nombre del cultivo",
      "Commercial Product Name:": "Nombre del Producto Comercial:",
      "Product Type:": "Tipo de Producto:",
      "Unit Dose:": "Dosis:",
      "Can be used in organic farming production?": "¿Puede ser utilizado en la producción de agricultura orgánica?",
      "Day Growth Cycle": "Días del Ciclo de Crecimiento",
      "FAO Class": "Clase FAO",
      "Productor": "Productor",
      "Creating the Seed...": "Creando la semilla...",
      "Are you sure to delete this seed?": "¿Estás seguro de que deseas eliminar esta semilla?",
      "Macro Type:": "Tipo Macro:",
      "Is organic product approved": "¿Producto aprobado para agricultura orgánica?",
      "Unit dose": "Dosis",
      "Delete seed": "Eliminar semilla",
      "Deleting the seed...": "Eliminando la semilla...",
      "Duplicate Seed": "Duplicar la semilla",
      "Edit Seed": "Editar la semilla",
      "Editing the Seed...": "Actualizando la semilla...",
      "Create Fertilizer": "Crear Fertilizante",
      "Fertilizer Registred": "Fertilizante Registrado",
      "Please set the name of the fertilizer": "Por favor, ingrese el nombre comercial del fertilizante",
      "Please set the type of fertilizer": "Por favor, seleccione el tipo de fertilizante",
      "Please set the nitrogen": "Por favor, ingrese el porcentaje de nitrógeno del fertilizante",
      "Please set the potassium": "Por favor, ingrese el porcentaje de potasio del fertilizante",
      "Please set the phosphorus": "Por favor, ingrese el porcentaje de fósforo del fertilizante",
      "Nitrogen Percentange": "Porcentaje de nitrógeno",
      "Phosphorus Percentange": "Porcentaje de fósforo",
      "Potassium Percentange": "Porcentaje de potasio",
      "Are you sure to delete this fertilizer?": "¿Está seguro de eliminar este fertilizante?",
      "Delete Fertilizer": "Eliminar el fertilizante",
      "Deleting the Fertilizer...": "Eliminando el fertilizante...",
      "Creating the Fertilizer...": "Creando el fertilizante...",
      "Duplicate Fertilizer": "Duplicar el fertilizante",
      "Edit Fertilizer": "Editar el fertilizante",
      "Editing the Fertilizer...": "Editando el fertilizante...",
      "Create Product": "Crear Producto",
      "Product Registred": "Producto Registrado",
      "Register Number": "Número de registro",
      "Registration Date": "Fecha de registro",
      "Product Formulation:": "Formulación del producto:",
      "Active substances:": "Sustancias activas:",
      "Content per 100 grams": "Contenido por 100 gramos",
      "Danger Indications": "Indicaciones de peligro",
      "Phenological Stage": "Etapa fenológica",
      "Scientific name adversity": "Nombre científico de la adversidad",
      "Common name adversity": "Nombre común de la adversidad",
      "Maximum dose": "Dosis máxima",
      "Minimum dose": "Dosis mínima",
      "Shortage time": "Tiempo de escasez - Días antes de que el producto pueda ser cosechado/comercializado",
      "Return time": "Tiempo de retorno - Días mínimos antes de permitir el regreso de los operarios al campo",
      "Minimum interval between treatments": "Intervalo mínimo entre tratamientos",
      "Maximum interval between treatments": "Intervalo máximo entre tratamientos",
      "Maximum number of treatments": "Número máximo de tratamientos",
      "Unit for maximum number of treatment": "Unidad para el número máximo de tratamientos",
      "Max Volume of water": "Volumen máximo de agua",
      "Min Volume of water": "Volumen mínimo de agua",
      "Creating the Product...": "Creando el producto...",
      "Are you sure to delete this product?": "¿Estás seguro de que quieres eliminar este producto?",
      "Organic Product": "Producto orgánico",
      "Delete Product": "Eliminar producto",
      "Deleting the Product...": "Eliminando el producto...",
      "Duplicate Product": "Duplicar producto",
      "Edit Product": "Editar producto",
      "Editing the Product...": "Editando el producto...",
      "Cost": "Coste",
      "Create Field Activities": "Crear Actividades de Campo",
      "Type of Field Activity": "Tipo de Actividad de Campo",
      "Tillage": "Labranza",
      "Sowing / Planting": "Siembra / Plantación",
      "Defence": "Defensa",
      "Tillage Type": "Tipo de Labranza",
      "Plowing": "Arado",
      "Harrowing": "Grada",
      "Hoeing": "Escarda",
      "Weeding": "Deshierbe",
      "Clod breaking": "Rompimiento de terrones",
      "Milling": "Molienda",
      "Ripping": "Rasgado",
      "Rolling": "Rodillo",
      "Compaction": "Compactación",
      "Mulching": "Acolchado",
      "Shredding": "Trituración",
      "Tillage Depht cm": "Profundidad de Labranza cm",
      "You didn't create a seed object": "No creaste ninguna semilla",
      "Sowing Dose kg / ha": "Dosis de Siembra kg / ha",
      "Irrigation Volume m3/ha": "Volumen de Riego m3/ha",
      "You didn't create a Fertilizer object": "No creaste ningún Fertilizante",
      "Create a fertilizer": "Crear Fertilizante",
      "Fertilization Product": "Producto Fertilizante",
      "Fertilization Dose kg/ha": "Dosis de Fertilización kg/ha",
      "You didn't create a Defence object": "No creaste ningún producto de Defensa",
      "Create a Defence Product": "Crear Producto de Defensa",
      "Defence Product": "Producto de Defensa",
      "Defence Type": "Tipo de Producto de Defensa",
      "Dose Used": "Dosis Usada",
      "Volume Water Used": "Volumen de Agua Usada",
      "Level of infestaction": "Nivel de Infestación",
      "Low": "Bajo",
      "Medium": "Medio",
      "High": "Alto",
      "Authorization Technichian?": "¿Autorización del Técnico?",
      "Respect strip": "Respeto de la Franja",
      "Exceeding the intervention threshold": "Superando el Umbral de Intervención",
      "It is done?": "¿Se ha realizado la actividad?",
      "Crop Phenology": "Fenología del Cultivo",
      "Hour of work": "Horas de Trabajo",
      "Variety": "Variedad",
      "Subcontractors?": "¿Subcontratistas?",
      "Wheater Class": "Clase de Clima",
      "Temperature °C": "Temperatura °C",
      "Wind Speed km / h": "Velocidad del Viento km / h",
      "Humidity %": "Humedad %",
      "Rainfall mm": "Precipitación mm",
      "Create Field Activity": "Crear Actividad de Campo",
      "Creating the Field Activity...": "Creando la Actividad de Campo...",
      "Are you sure to delete this field activity?": "¿Estás seguro de que quieres eliminar esta actividad de campo?",
      "Delete Field Activity": "Eliminar Actividad de Campo",
      "Deleting the Field Activity...": "Eliminando la Actividad de Campo...",
      "Edit Field Activities": "Editar Actividades de Campo",
      "Edit Field Activity": "Editar Actividad de Campo",
      "Editing the Field Activity...": "Actualizando la Actividad de Campo...",
      "Seed / Planting": "Siembra / Plantación",
      "Field Activity": "Actividad de Campo",
      "Date of Hire": "Fecha de contratación",
      "Operator Cost Registered": "Costo del operador registrado",
      "Create Operator Cost": "Crear costo del operador",
      "No Operator are available for your account.": "No hay operadores disponibles para tu cuenta.",
      "First you have to create an operator": "Primero debes crear un operador",
      "No Farm are available for your account.": "No hay fincas disponibles para tu cuenta.",
      "First you have to create an Farm": "Primero debes crear una finca",
      "Please set the year operotor cost": "Por favor, establece el costo anual del operador",
      "Create a Operator Cost": "Crear un costo de operador",
      "Operator year salary": "Salario anual del operador",
      "Creating the Operator Cost...": "Creando el costo del operador...",
      "Are you sure to delete this operator cost": "¿Estás seguro de querer eliminar este costo del operador?",
      "Operator Cost Detail": "Detalle del costo del operador",
      "Delete Operator Cost": "Eliminar costo del operador",
      "Deleting the Operator Cost...": "Eliminando el costo del operador...",
      "Duplicate a Operator Cost": "Duplicar un costo del operador",
      "Duplicate Operator Cost": "Duplicar costo del operador",
      "Duplicating the Operator Cost...": "Duplicando el costo del operador...",
      "Edit a Operator Cost": "Editar un costo del operador",
      "Edit Operator Cost": "Editar costo del operador",
      "Editing the Operator Cost...": "Editando el costo del operador...",
      "Create Revenue": "Crear Ingresos",
      "Revenue Registered": "Ingresos registrados",
      "Total Revenue": "Ingresos totales",
      "Please set the total revenue": "Por favor, introduzca el valor de los ingresos totales",
      "Please set the type of the revenue": "Por favor, introduzca el tipo de ingresos",
      "Register a Revenue": "Registrar un ingreso",
      "Select the type of revenue:": "Seleccione el tipo de ingreso:",
      "Direct Payments": "Pagos directos",
      "pac": "PAC",
      "indinizzi": "Indicios",
      "Agricultural compensation": "Compensaciones agrícolas",
      "Creating the Revenue...": "Creando el ingreso...",
      "Are you sure to delete this revenue?": "¿Está seguro de que desea eliminar este ingreso?",
      "Delete Revenue": "Eliminar ingreso",
      "Deleting the Revenue...": "Eliminando el ingreso...",
      "Duplicate a Revenue": "Duplicar un ingreso",
      "Duplicate Revenue": "Duplicar el ingreso",
      "Duplicating the Revenue...": "Duplicando el ingreso...",
      "Edit a Revenue": "Editar el ingreso",
      "Edit Revenue": "Editar ingreso",
      "Editing the Revenue...": "Editando el ingreso...",
      "Farmer Economy Analysis": "Análisis económico del agricultor",
      "Farm Profit": "Beneficio de la granja",
      "Farm Revenue": "Ingresos de la granja",
      "Farm Total Cost": "Coste total de la granja",
      "Economy Data": "Datos económicos",
      "Activity": "Actividad",
      "Cost/Price": "Costo/Precio",
      "Quantity/Hours": "Cantidad/Horas",
      "Cost allocation": "Asignación de costos",
      "Allocation of Revenues": "Asignación de ingresos",
      "Revenues": "Ingresos",
      "Monthly Costs and Revenues": "Costes e ingresos mensuales",
      "Cash Flow": "Flujo de caja",
      "Air Temperature": "Temperatura del aire",
      "Atmospheric Pressure": "Presión atmosférica",
      "Rainfall": "Precipitación",
      "Air Humidity": "Humedad del aire",
      "Evapotraspiration": "Evapotranspiración",
      "Degree Day": "Grado día",
      "TWH Index": "Índice TWH",
      "Dew Point": "Punto de rocío",
      "Gust": "Ráfaga",
      "Real time data or Historic data?": "¿Datos en tiempo real o datos históricos?",
      "Last 24 Hours Data": "Datos de las últimas 24 horas",
      "Degree Days": "Grados día",
      "Leaf Wetness": "Humedad de la hoja",
      "Irrigation Analysis": "Análisis de riego",
      "Download Operator - Format Excel": "Descargar operador - Formato Excel",
      "Download Warehouse - Format Excel": "Descargar almacén - Formato Excel",
      "Download Field Note - Format Excel": "Descargar nota de campo - Formato Excel",
      "Download Yield - Format Excel": "Descargar rendimiento - Formato Excel",
      "Download Field Activity - Format Excel": "Descargar actividad de campo - Formato Excel",
      "Download Other Revenue - Format Excel": "Descargar otros ingresos - Formato Excel",
      "Download Operator Cost - Format Excel": "Descargar coste de operadores - Formato Excel",
      "generic": "Genérico",
      "phatogens": "Patógenos",
      "insect": "Insectos",
      "weed": "Malezas",
      "waterlogging": "Encharcamiento",
      "genericdamage": "Daño Genérico",
      "fieldsurvey": "Encuesta de campo",
      "cropstatus": "Estado del cultivo",
      "acaricida": "Acaricida",
      "aficida": "Aficida",
      "antideriva": "Antideriva",
      "antidoto": "Antídoto",
      "antigermogliante": "Antigerminante",
      "antiriscaldo": "Antiescaldo",
      "attrattivo": "Atrayente",
      "bagnante": "Mojante",
      "coaudiuvante": "Coayudante",
      "diradante": "Aclarador",
      "feromone": "Feromona",
      "fitoregolatore": "Fitorregulador",
      "geodisinfestante": "Geodesinfestante",
      "repellente": "Repelente",
      "sinergizzante": "Sinergizante",
      "adiuvante": "Adyuvante",
      "fungicida": "Fungicida",
      "diserbante": "Herbicida",
      "insetticida": "Insecticida",
      "molluschicida": "Molusquicida",
      "organic_product": "Producto Orgánico",
      "nematocida": "Nematicida",
      "sostanza_di_crescita": "Sustancia de crecimiento",
      "biostimolante": "Bioestimulante",
      "ritirato": "Retirado",
      "attivo": "Activo",
      "kg_ha": "kg/ha",
      "l_ha": "l/ha",
      "unita_ha": "Unidades/ha",
      "anno": "Año",
      "ciclo": "Ciclo",
      "stagione": "Temporada",
      "semente": "Semilla",
      "piantine": "Plántulas",
      "libre_acro": "Libras/acre",
      "minerale": "Mineral",
      "organico": "Orgánico",
      "tillage": "Labranza",
      "sowing": "Siembra",
      "defence": "Defensa",
      "irrigation": "Riego",
      "nutrition": "Nutrición",
      "sereno": "Despejado",
      "nuvoloso": "Nublado",
      "variabile": "Variable",
      "molto_nuvoloso": "Muy nublado",
      "coperto": "Cubierto",
      "nebbia": "Niebla",
      "coperto_nebbia": "Cubierto con niebla",
      "variabile_con_rovesci": "Variable con chubascos",
      "temporale": "Tormenta",
      "coperto_con_neve": "Cubierto con nieve",
      "pioggia_misto_neve": "Lluvia con nieve",
      "erbacee_botticella": "Herbáceas embotellado",
      "erbacee_fioritura": "Herbáceas floración",
      "erbacee_invaiatura": "Herbáceas envero",
      "erbacee_allegagione": "Herbáceas cuajado",
      "erbacee_ingrossamento": "Herbáceas engrosamiento",
      "erbacee_germinazione": "Herbáceas germinación",
      "erbacee_emergenza_infioriscenza": "Herbáceas emergencia inflorescencia",
      "erbacee_sviluppo_fogliare": "Herbáceas desarrollo foliar",
      "erbacee_maturazione": "Herbáceas maduración",
      "erbacee_senescenza": "Herbáceas senescencia",
      "erbacee_levata": "Herbáceas levantamiento",
      "erbacee_accestimento": "Herbáceas macollamiento",
      "arboree_gemme_inverno": "Arbóreas yemas invierno",
      "arboree_apertura_gemme": "Arbóreas apertura de yemas",
      "arboree_ripresa_vegetativa": "Arbóreas reanudación vegetativa",
      "arboree_sviluppo_germogli": "Arbóreas desarrollo de brotes",
      "arboree_foglie_distese": "Arbóreas hojas extendidas",
      "arboree_grappoli_visibili": "Arbóreas racimos visibles",
      "arboree_grappoli_separati": "Arbóreas racimos separados",
      "arboree_ingrossamento_frutti": "Arbóreas engrosamiento de frutos",
      "arboree_pre_chiusura_grappolo": "Arbóreas pre-cierre del racimo",
      "arboree_chiusura_grappolo": "Arbóreas cierre del racimo",
      "arboree_indurimento_nocciolo": "Arbóreas endurecimiento del hueso",
      "arboree_invaitura": "Arbóreas envero",
      "arboree_riposo_vegetativo": "Arbóreas reposo vegetativo",
      "arboree_fioritura": "Arbóreas floración",
      "arboree_allegagione": "Arbóreas cuajado",
      "arboree_pianta_ferma": "Arbóreas planta parada",
      "arboree_germogliamento": "Arbóreas brotación",
      "arboree_germogli_5_10_cm": "Arbóreas brotes 5-10 cm",
      "arboree_comparsa_boccioli_floreali": "Arbóreas aparición de botones florales",
      "arboree_bottone_bianco": "Arbóreas botón blanco",
      "arboree_crescita": "Arbóreas crecimiento",
      "arboree_semi_neri": "Arbóreas semillas negras",
      "arboree_pre_raccolta": "Arbóreas pre-cosecha",
      "arboree_raccolta": "Arbóreas cosecha",
      "basso": "Bajo",
      "medio": "Medio",
      "alto": "Alto",
      "aratura": "Arado",
      "erpicatura": "Gradeo",
      "zappatura": "Azada",
      "sarchiatura": "Escarda",
      "frangizollatura": "Despedregado",
      "fresatura": "Fresado",
      "rippatura": "Rasgado",
      "rullatura": "Rodado",
      "compattazione": "Compactación",
      "pacciamatura": "Acolchado",
      "trinciatura": "Trituración",
      "Seedlings": "Plántulas",
      "Pounds per acre": "Libras por acre",
      "Mineral Product": "Producto Mineral",
      "Nutrition": "Nutrición",
      "Historic Data": "Datos Históricos",
      "Withdrawn": "Retirado",
      "Active": "Activo",
      "Year": "Anual",
      "Cycle": "Ciclo",
      "Season": "Temporada",
      "Defense": "Defensa",
      "Active or Retrivied:": "Activo o retirado",
      "Please fill all the field": "Por favor, complete todos los campos",
      "Please fill all the form to create a field activities": "Por favor, complete todo el formulario para crear una actividad de campo",
      "Other Revenue": "Otros Ingresos",
      "Harvest": "Cosecha",
      "Please insert the date of the revenue": "Por favor, inserte la fecha del ingreso",
      "Please enter the start date of the operator cost": "Por favor, ingrese la fecha de inicio del costo del operador",
      "Pear_fruit": "Pera",
      "Dry_pulses": "Legumbres secas",
      "Strawberries": "Fresa",
      "Tobacco": "Tabaco",
      "Other_fibre_and_oleaginous_crops": "Otros cultivos fibrosos y oleaginosos",
      "Other_fresh_vegetables": "Otras hortalizas frescas",
      "Upgrade to PRO": "Actualiza a PRO",
      "Something Went Wrong During the Payment": "Algo salió mal durante el pago",
      "Please send us an email to info@automaticfarmsolution.com": "Por favor envíanos un correo electrónico a info@automaticfarmsolution.com",
      "An operator will answear you as soon as possible": "Un operador te responderá lo antes posible",
      "VAT Excluded": "IVA Excluido",
      "The total price is 832 € (VAT Included)": "El precio total es 832 € (IVA incluido)",
      "All the modules of free version": "Todos los módulos de la versión gratuita",
      "Generation of prescription map with Artificial Intelligence": "Generación de mapa de prescripción con Inteligencia Artificial",
      "Capability to connect IoT Sensors": "Capacidad para conectar sensores IoT",
      "Set automatic alerts": "Configurar alertas automáticas",
      "Artificial Intelligence Digital Soil Mapping": "Mapeo digital del suelo con Inteligencia Artificial",
      "Advanced Report & Documentation": "Informes avanzados y documentación",
      "Online Live Training of the Platform": "Formación en vivo en línea de la plataforma",
      "Number of Plant": "Número de plantas",
      "Number of note": "Número de notas",
      "Note": "Nota",
      "Add Note": "Agregar nota",
      "Download Note": "Descargar nota",
      "List of the note": "Lista de notas registradas",
      "User": "Usuario",
      "Plant": "Planta",
      "Image": "Imagen",
      "Created at": "Creada el",
      "Detail": "Detalles",
      "No Note are Registrated": "No se han registrado notas",
      "Plant Number": "Número de planta",
      "Upload or Capture Image": "Cargar o capturar una imagen",
      "Save": "Guardar",
      "Detail Note - ": "Detalle de la nota - ",
      "User - ": "Usuario que creó la nota - ",
      "Plant - ": "Planta - ",
      "Note - ": "Nota - ",
      "Image": "Imagen",
      "Created at - ": "Creada el - ",
      "Are you sure to delete the note?": "¿Estás seguro de que deseas eliminar la nota?",
      "Update Note": "Actualizar nota",
      "New registration": "Nuevo registro",
      "You don't have yet the permission to use this application": "Aún no tienes permiso para usar esta aplicación",
      "Please use the following button to send a request to the wine manager to be activated": "Para comenzar a usar la aplicación, utiliza el siguiente botón para enviar una solicitud al gerente de vinos para su aprobación",
      "Send the request": "Enviar solicitud",
      "The plant cannot be empty": "El número de la planta no puede estar vacío",
      "The note cannot be empty": "La nota no puede estar vacía",
      "You must upload a image": "Debes cargar una imagen"
    }
  },
  "FR": {
    "translation": {
      "Hello": "Bonjour",
      "Yes": "Oui",
      "No": "Non",
      "Temporary_grassland": "Prairie temporaire",
      "Grassland": "Prairie",
      "Clovers": "Trèfles",
      "Olive_groves": "Oliviers",
      "Olive": "Olive",
      "Oranges": "Oranges",
      "Apple_fruit": "Pomme",
      "Apple": "Pomme",
      "Cherry_fruit": "Cerise",
      "Cherry": "Cerise",
      "Nuts_trees": "Arbres à noix",
      "Nuts": "Noix",
      "Rye": "Seigle",
      "Potatoes": "Pommes de terre",
      "Triticale": "Triticale",
      "Sugar_beet": "Betterave à sucre",
      "Sugar Beet": "Betterave à sucre",
      "Soya": "Soja",
      "Cotton": "Coton",
      "Tomatoes": "Tomates",
      "Set the analysis": "Définir l'analyse",
      "Number of farmer": "Nombre D'Agriculteurs",
      "Do you want to define the number of zone in Automatically way or Manual?": "Veux-tu définir le nombre de zones de manière automatique ou manuelle ?",
      "If is set Manual, how many zones do you want to create?": "Si c'est réglé manuellement, combien de zones veux-tu créer ?",
      "Select the Pest Model To Use": "Sélectionne le modèle de ravageurs à utiliser",
      "Number of field": "Nombre de champs",
      "Phosphorus": "Phosphore",
      "Farmer Registred": "Agriculteur enregistré",
      "Field Registred": "Champ enregistré",
      "Durum_wheat": "Blé dur",
      "Download data": "Télécharger les données",
      "The email is empty": "L'e-mail est vide",
      "The password is empty": "Le mot de passe est vide",
      "The user is not active!": "L'utilisateur n'est pas actif !",
      "The email is used": "L'e-mail est déjà utilisé",
      "Forgot password?": "Mot de passe oublié ?",
      "Create new account": "Créer un nouveau compte",
      "Check your email inbox and follow the instructions to change the password.": "Consultez votre boîte de réception électronique et suivez les instructions pour changer le mot de passe.",
      "Go back to Login": "Retour à la connexion",
      "The email doesn't exist in our database!": "L'e-mail n'existe pas dans notre base de données !",
      "Change Password": "Changer le mot de passe",
      "Send Email": "Envoyer un e-mail",
      "Sending email...": "Envoi de l'e-mail...",
      "The first password is empty": "Le premier mot de passe est vide",
      "The confirm password is empty": "La confirmation du mot de passe est vide",
      "The password don't match": "Les mots de passe ne correspondent pas",
      "Password change failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Échec du changement de mot de passe. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors du changement de mot de passe. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Confirm Password": "Confirmer le mot de passe",
      "Changing Password...": "Changement de mot de passe...",
      "The password has been changed with success. Now use the new password to login.": "Le mot de passe a été changé avec succès. Utilisez désormais le nouveau mot de passe pour vous connecter.",
      "In order to register you should accept the Privacy Policy": "Pour vous inscrire, vous devez accepter la politique de confidentialité",
      "In order to register you should provide your name": "Pour vous inscrire, vous devez fournir votre nom",
      "In order to register you should provide a valid email": "Pour vous inscrire, vous devez fournir une adresse e-mail valide",
      "The current email is used, please use another one!": "L'e-mail actuel est déjà utilisé, veuillez en utiliser un autre",
      "Registration": "Inscription",
      "Name": "Nom",
      "I agree with the": "Je suis d'accord avec le",
      "Register": "S'inscrire",
      "Registration...": "Inscription...",
      "Already have an account?": "Vous avez déjà un compte ?",
      "Check your email inbox and follow the instructions to activate your account.": "Consultez votre boîte de réception électronique et suivez les instructions pour activer votre compte.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "L'activation de votre compte a échoué. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "An error occurred during the activation of your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors de l'activation de votre compte. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Voulez-vous activer votre compte ?",
      "Activate my account": "Activer mon compte",
      "Activation...": "Activation...",
      "Your Account has been activated. Now You can login": "Votre compte a été activé. Vous pouvez maintenant vous connecter",
      "Welcome!": "Bienvenue !",
      "My profile": "Mon profil",
      "Settings": "Paramètres",
      "Support": "Support",
      "Logout": "Déconnexion",
      "Farm": "Ferme",
      "Field": "Champ",
      "Field Notebook": "Carnet de champ",
      "Agrometeo": "Agrométéo",
      "Weather": "Météo",
      "Climatic Index": "Indice climatique",
      "Satellite": "Satellite",
      "Prescription Map": "Carte de prescription",
      "Sensors": "Capteurs",
      "Summary": "Résumé",
      "Analysis": "Analyse",
      "Forecast": "Prévision",
      "Anomaly Detection": "Détection d'anomalies",
      "Comparation": "Comparaison",
      "Allert": "Alerte",
      "Bio Trap": "Piège Biologique",
      "Agronomic tools": "Outils Agronomiques",
      "Nutrient Balance": "Bilan des Nutriments",
      "Soil Data": "Données du Sol",
      "Irrigation": "Irrigation",
      "Pest Model": "Modèle de Ravageurs",
      "Artificial Intelligence": "Intelligence Artificielle",
      "Digital Consulting": "Conseil Numérique",
      "Crop Models & simulations": "Modèles et Simulations de Cultures",
      "Soil Mapping & Carbon Credits": "Cartographie des Sols et Crédits Carbone",
      "Organic vs Conventional": "Bio vs Conventionnel",
      "Connections & Data Sharing": "Connexions et Partage de Données",
      "Upgrade your profile": "Mettre à niveau votre profil",
      "Register a new farmer": "Enregistrer un nouveau fermier",
      "Farmer Company Name:": "Nom de l'entreprise agricole",
      "Farmer First Name:": "Prénom du fermier :",
      "Farmer Surname:": "Nom de famille du fermier :",
      "Farmer Country:": "Pays du fermier :",
      "Farmer City:": "Ville du fermier :",
      "Farmer Address:": "Adresse du fermier :",
      "Farmer Email:": "E-mail du fermier :",
      "Farmer Phone Number:": "Numéro de téléphone du fermier :",
      "Create Farm": "Créer une ferme",
      "Go back": "Retour",
      "Restart Creation": "Recommencer la création",
      "Creating the farm...": "Création de la ferme...",
      "Farm Registered": "Ferme enregistrée",
      "No farmer are registered": "Aucun fermier n'est enregistré",
      "If you want to start using this application, you should start by registering a new farmer.": "Si vous voulez commencer à utiliser cette application, commencez par enregistrer un nouveau fermier.",
      "Farm Name": "Nom de la ferme",
      "Surname": "Nom de famille",
      "Email": "E-mail",
      "Phone Number": "Numéro de téléphone",
      "Edit": "Modifier",
      "Delete": "Supprimer",
      "Are you sure to remove the following farmer?": "Êtes-vous sûr de vouloir supprimer le fermier suivant ?",
      "Name of the farmer:": "Nom du fermier :",
      "Surname of the farmer:": "Nom de famille du fermier :",
      "Country of the farmer:": "Pays du fermier :",
      "City of the farmer:": "Ville du fermier :",
      "Address of the farmer:": "Adresse du fermier :",
      "Email of the farmer:": "E-mail du fermier :",
      "Phone Number of the farmer:": "Numéro de téléphone du fermier :",
      "Delete Farmer": "Supprimer le fermier",
      "Invalid email address": "Adresse e-mail invalide",
      "Invalid phone number": "Numéro de téléphone invalide",
      "Add Farm": "Ajouter une ferme",
      "Farmer Registered": "Fermier enregistré",
      "Farmer": "Fermier",
      "No registered farm": "Aucune ferme enregistrée",
      "Loading": "Chargement",
      "Update Farm": "Mettre à jour la ferme",
      "Updating the farm...": "Mise à jour de la ferme...",
      "The Name of the farmer is empty": "Le nom du fermier est vide",
      "The Surname of the farmer is empty": "Le nom de famille du fermier est vide",
      "The CountryCode of the farmer is empty": "Le code pays du fermier est vide",
      "The City of the farmer is empty": "La ville du fermier est vide",
      "The Address of the farmer is empty": "L'adresse du fermier est vide",
      "The Email of the farmer is empty": "L'e-mail du fermier est vide",
      "The Phone Number of the farmer is empty": "Le numéro de téléphone du fermier est vide",
      "An error occurred while updating the farm. If the problem persists, please contact us at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors de la mise à jour de la ferme. Si le problème persiste, veuillez nous contacter à automaticfarmsolution@gmail.com",
      "Create Field": "Créer un champ",
      "You don't have any farm registred, first you have to set at least one farmer": "Vous n'avez aucune ferme enregistrée, vous devez d'abord configurer au moins un agriculteur",
      "Field name:": "Nom du champ :",
      "Crop Rotation:": "Rotation des cultures :",
      "Crop Rotation": "Rotation des cultures",
      "Perennial crop": "Culture pérenne",
      "Two year crop rotation": "Rotation des cultures sur deux ans",
      "Three year crop rotation": "Rotation des cultures sur trois ans",
      "Actual Crop": "Culture actuelle",
      "Alfalfa": "Trèfle",
      "Barley": "Orge",
      "Maize": "Maïs",
      "Durum Wheat": "Blé dur",
      "The next year Crop": "Culture de l'année suivante",
      "The crop in two years' time": "Culture dans deux ans",
      "Organic": "Biologique",
      "Conventional": "Conventionnelle",
      "Draw the edge of your field:": "Dessinez le bord de votre champ :",
      "The field name is empty": "Le nom du champ est vide",
      "Please draw the edge of your field in the map": "Veuillez dessiner le bord de votre champ sur la carte",
      "An error occurred while creating the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors de la création du champ. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "List of the field": "Liste des champs",
      "Field Name:": "Nom du champ :",
      "Field Detail": "Détail du champ",
      "Crop:": "Culture :",
      "Agronomic Management:": "Gestion agronomique :",
      "Are you sure to delete this field?": "Êtes-vous sûr de vouloir supprimer ce champ ?",
      "Delete Field": "Supprimer le champ",
      "Field:": "Champ :",
      "Loading...": "Chargement...",
      "Edit Field": "Modifier le champ",
      "Draw again the edge of your field:": "Dessinez à nouveau le bord de votre champ :",
      "In red there is the old field": "En rouge, il y a le champ ancien",
      "An error occurred while editing the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Une erreur s'est produite lors de l'édition du champ. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Try Again": "Réessayer",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Quelque chose s'est mal passé. Veuillez rafraîchir la page et si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Sélectionnez le champ à analyser",
      "View Satellite Image": "Voir l'image satellite",
      "Field Details": "Détails du champ",
      "Actual Crop:": "Culture actuelle :",
      "Time series plot": "Graphique de séries temporelles",
      "Satellite Image": "Image satellite",
      "Select one field": "Sélectionnez un champ",
      "Field Boundary": "Limite du champ",
      "Select the Vegetation Index": "Sélectionnez l'indice de végétation",
      "Set the prescription map": "Définir la carte de prescription",
      "Do you want to define the number of zones in an Automatic way or Manual?": "Voulez-vous définir le nombre de zones de manière automatique ou manuelle ?",
      "Automatic": "Automatique",
      "Manual": "Manuel",
      "If it is set to Manual, how many zones do you want to create?": "Si c'est réglé sur Manuel, combien de zones souhaitez-vous créer ?",
      "Max fertilizer that you want to apply?": "Quelle quantité maximale d'engrais souhaitez-vous appliquer ?",
      "Which strategy do you want to use?": "Quelle stratégie souhaitez-vous utiliser ?",
      "Provide higher amount of fertilizer where the crop is stronger": "Fournir une plus grande quantité d'engrais là où la culture est plus forte",
      "Provide lower amount of fertilizer where the crop is stronger": "Fournir une plus petite quantité d'engrais là où la culture est plus forte",
      "Percentage of difference of fertilizer between zones": "Pourcentage de différence d'engrais entre les zones",
      "Selected Percentage:": "Pourcentage sélectionné :",
      "Create": "Créer",
      "Send to Jhon Deere Operation Center": "Envoyer au Centre d'Opérations Jhon Deere",
      "Download": "Télécharger",
      "Downloading...": "Téléchargement en cours...",
      "Downloading": "Téléchargement",
      "Sending": "Envoi",
      "Sending...": "Envoi en cours...",
      "Something went wrong. If the problem persists please send us an email at automaticfarmsolution@gmail.com": "Quelque chose s'est mal passé. Si le problème persiste, veuillez nous envoyer un e-mail à automaticfarmsolution@gmail.com",
      "Creating the field...": "Création du champ...",
      "Update Field": "Mettre à jour le champ",
      "Updating Field": "Mise à jour du champ en cours",
      "Integration done": "Intégration terminée",
      "You have done the integration with Jhon Deere": "Vous avez terminé l'intégration avec Jhon Deere",
      "Start the integration with the Operation Center Jhon Deere": "Démarrer l'intégration avec le Centre d'Opérations Jhon Deere",
      "A problem occurred during the integration with Jhon Deere": "Un problème est survenu lors de l'intégration avec Jhon Deere",
      "You don't have any farm registered, first you have to set at least one farmer": "Vous n'avez aucune ferme enregistrée, vous devez d'abord configurer au moins un agriculteur",
      "Start the integration with the Operation Center John Deere": "Démarrer l'intégration avec le Centre d'Opérations John Deere",
      "Connect to Operation Center Jhon Deere": "Se connecter au Centre d'Opérations Jhon Deere",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "Si vous souhaitez envoyer la carte de prescription au centre d'opérations, vous pouvez connecter cette plateforme directement à votre Centre d'Opérations",
      "Satellite Image analysis": "Analyse d'image satellite",
      "Please enable the geolocation in order to get the weather data and visualization": "Veuillez activer la géolocalisation pour obtenir les données météorologiques et la visualisation",
      "Actual Weather Data": "Données météorologiques actuelles",
      "Weather Map": "Carte météo",
      "Select the climate variable to see": "Sélectionnez la variable climatique à voir",
      "Forecast Temperature": "Température prévue",
      "Wind": "Vent",
      "Pressure": "Pression",
      "Snow": "Neige",
      "Precipitation": "Précipitation",
      "Clouds": "Nuages",
      "Temperature": "Température",
      "Add / Modify / Delete": "Ajouter / Modifier / Supprimer",
      "Field Registered": "Champ Enregistré",
      "Field Name": "Nom du Champ",
      "Select the field to view": "Sélectionnez le champ à visualiser",
      "Number of farmers": "Nombre d'agriculteurs",
      "Number of fields": "Nombre de champs",
      "Hectares Monitored": "Hectares surveillés",
      "Anomalies": "Anomalies",
      "IoT Number": "Numéro IoT",
      "Last Reading": "Dernière Lecture",
      "Soil Temperature": "Température du Sol",
      "Soil Humidity": "Humidité du sol",
      "Electrical Conductivity": "Conductivité Électrique",
      "Organic Carbon": "Carbone Organique",
      "Nitrogen": "Azote",
      "Potassium": "Potassium",
      "Battery": "Batterie",
      "Select the variable": "Sélectionnez la variable",
      "Select the IoT Cabin": "Sélectionnez la Cabine IoT",
      "View data": "Voir les données",
      "Time series Graph": "Graphique de séries temporelles",
      "Time Series Comparison": "Comparaison de séries temporelles",
      "Table": "Tableau",
      "Add Alert": "Ajouter une Alerte",
      "Alert Registered": "Alerte Enregistrée",
      "Sensor": "Capteur",
      "Variable": "Variable",
      "Condition": "Condition",
      "Value": "Valeur",
      "No Alert Registered": "Aucune Alerte Enregistrée",
      "No IoT is linked to your account": "Aucun IoT n'est lié à votre compte",
      "No IoT alert is set": "Aucune alerte IoT n'est définie",
      "Please select the IoT Cabin": "Veuillez sélectionner la Cabine IoT",
      "Please select the variable": "Veuillez sélectionner la variable",
      "Please select the logic to apply": "Veuillez sélectionner la logique à appliquer",
      "Please set the threshold value": "Veuillez définir la valeur seuil",
      "Select the logic": "Sélectionnez la logique",
      "Set the threshold value": "Définissez la valeur seuil",
      "Adding the alert...": "Ajout de l'alerte en cours...",
      "Higher than": "Supérieur à",
      "Lower than": "Inférieur à",
      "IoT Position": "Position de l'IoT",
      "Create Alert": "Créer une alerte",
      "Are you sure to remove this alert?": "Êtes-vous sûr de vouloir supprimer cette alerte ?",
      "Cabin Number:": "Numéro de cabine :",
      "Logic - Higher or Lower:": "Logique - Supérieur ou inférieur :",
      "Threshold Value:": "Valeur seuil :",
      "Delete Alert": "Supprimer l'alerte",
      "Update Alert": "Mettre à jour l'alerte",
      "Updating the alert...": "Mise à jour de l'alerte en cours...",
      "Please the expected yield (q/ha)": "Veuillez indiquer le rendement attendu (q/ha)",
      "Please the fertilizer title (%)": "Veuillez indiquer le titre du fertilisant (%)",
      "Setting of the Calculation": "Réglage du calcul",
      "Select the Nutrient": "Sélectionnez le nutriment",
      "Select the Crop": "Sélectionnez la culture",
      "Set the expected yield (q/ha)": "Définissez le rendement attendu (q/ha)",
      "Title of fertilizer - range 0-100 %": "Titre du fertilisant - plage de 0 à 100 %",
      "Calculate": "Calculer",
      "The amount of fertilizer that you have provided to the crop for the entire crop growth cycle is:": "La quantité de fertilisant que vous avez fournie à la culture pour l'ensemble du cycle de croissance est :",
      "Set the field to get the soil data": "Sélectionnez le champ pour obtenir les données du sol",
      "Hello, I'm your Digital Agronomist Consultant! Ask me anything!": "Bonjour, je suis votre consultant agronome numérique ! Posez-moi toutes vos questions !",
      "The digital agronomist consultant is typing": "Le consultant agronome numérique est en train de taper",
      "Send a Message": "Envoyer un message",
      "Digital agronomist consultant": "Consultant agronome numérique",
      "You have done the integration with John Deere": "Vous avez réalisé l'intégration avec John Deere",
      "Please fill all the requests": "Veuillez remplir toutes les demandes",
      "Set the analysis parameter": "Définir le paramètre d'analyse",
      "Date of sowing": "Date de semis",
      "Select the soil type": "Sélectionnez le type de sol",
      "Sand": "Sable",
      "Loamy Sand": "Sable limoneux",
      "Sandy Loam": "Limono-sableux",
      "Loam": "Limon",
      "Silty Loam": "Limono-argileux",
      "Silt": "Argileux",
      "Sandy Clay Loam": "Argilo-sableux",
      "Clay Loam": "Argile limoneuse",
      "Silty Clay Loam": "Argilo-limoneux",
      "Sandy Clay": "Argile sableuse",
      "Clay": "Argile",
      "Sunflower": "Tournesol",
      "Millet": "Millet",
      "Rice": "Riz",
      "Sugarbeet": "Betterave à sucre",
      "Sorghum": "Sorgho",
      "Soybean": "Soja",
      "Canola": "Colza",
      "Tomato": "Tomate",
      "Cabbage": "Chou",
      "Potato": "Pomme de terre",
      "Sugarcane": "Canne à sucre",
      "Select the Variety": "Sélectionnez la variété",
      "Do you want to provide fertilization?": "Souhaitez-vous fournir de la fertilisation ?",
      "Date of fertilization": "Date de fertilisation",
      "Define the amount of Nitrogen provided (kg N / ha)": "Définir la quantité d'azote fournie (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Définir la quantité de phosphore fournie (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Définir la quantité de potassium fournie (kg K / ha)",
      "Do you want to provide irrigation": "Souhaitez-vous fournir de l'irrigation",
      "Date of irrigation": "Date de l'irrigation",
      "Define the amount of water mm": "Définir la quantité d'eau en mm",
      "Next": "Suivant",
      "Submit": "Envoyer",
      "Temporal creation of the yield": "Création temporelle du rendement",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Avant de pouvoir exécuter le modèle, vous devez définir le type de culture. Accédez à la section Champ et mettez à jour les informations.",
      "Set the Field where apply the analysis": "Définir le champ où appliquer l'analyse",
      "Set the Farmer": "Définir l'agriculteur",
      "Set the Field": "Définir le champ",
      "Run The Analysis": "Lancer l'analyse",
      "NDVI Comparation": "Comparaison de NDVI",
      "SAR Comparation": "Comparaison de SAR",
      "Probabilistic Map": "Carte probabiliste",
      "% Convenctional": "% Conventionnel",
      "% Organic": "% Biologique",
      "The field was declared by the farmer as": "Le champ a été déclaré par l'agriculteur comme",
      "Select the start year to analyze": "Sélectionnez l'année de début pour l'analyse",
      "Select the base temperature for the crop": "Sélectionnez la température de base pour la culture",
      "Select the max temperature for the crop": "Sélectionnez la température maximale pour la culture",
      "Start analysis": "Lancer l'analyse",
      "Upgrade your account": "Mettez à niveau votre compte",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "Si vous souhaitez accéder aux données satellitaires et générer la carte de prescription, vous devez mettre à niveau votre compte en PRO",
      "Not Now": "Pas maintenant",
      "Upgrade your account to PRO": "Mettez à niveau votre compte en PRO",
      "Your Account is PRO": "Votre compte est PRO",
      "You can access to all the service": "Vous pouvez accéder à tous les services",
      "Your Account is not PRO": "Votre compte n'est pas PRO",
      "Purchase the PRO Service": "Acheter le service PRO",
      "The total price is": "Le prix total est",
      "The payment was successful": "Le paiement a réussi",
      "Now you should logout and login again to access all the features just unlocked": "Maintenant, vous devez vous déconnecter et vous reconnecter pour accéder à toutes les fonctionnalités débloquées",
      "By purchasing this service you will access:": "En achetant ce service, vous aurez accès à :",
      "Prescription maps that can be integrated with your display tractors": "Cartes de prescription pouvant être intégrées à vos tracteurs avec écran",
      "Visualization & Analysis of sensor data": "Visualisation et analyse des données du capteur",
      "Set alert": "Configurer l'alerte",
      "All the agronomic tools": "Tous les outils agronomiques",
      "AI Tools": "Outils d'IA",
      "Synchronization with Operation Center John Deere": "Synchronisation avec le centre d'opérations John Deere",
      "Vineyards": "Vignobles",
      "perennial": "Pluriannuel",
      "biennial": "Biennal",
      "triennial": "Triennal",
      "Kiwi": "Kiwi",
      "Garlic": "Ail",
      "Apricot": "Abricot",
      "Orange": "Orange",
      "Asparagus": "Asperge",
      "Oats": "Avoine",
      "Basil": "Basilic",
      "Swiss_chard": "Blette",
      "Beet_leaves": "Feuilles de betterave",
      "Broccoli": "Brocoli",
      "Hemp": "Chanvre",
      "Artichoke": "Artichaut",
      "Carrot": "Carotte",
      "Cauliflower": "Chou-fleur",
      "chickpea": "Pois chiche",
      "cucumber": "Concombre",
      "Chicory": "Chicorée",
      "Onion": "Oignon",
      "Endive": "Endive",
      "Watermelon": "Pastèque",
      "Rapeseed": "Colza",
      "Green_bean_for_industry": "Haricot vert pour l'industrie",
      "Fresh_green_bean": "Haricot vert frais",
      "Bean": "Haricot",
      "Dry_bean": "Haricot sec",
      "Spelt": "Épeautre",
      "Grain_fava_bean": "Fève de grain",
      "Favino": "Favino",
      "Fico": "Figue",
      "Fennel": "Fenouil",
      "Strawberry": "Fraise",
      "winter_wheat": "Blé d'hiver",
      "Kaki": "Kaki",
      "Lettuce": "Laitue",
      "Lentil": "Lentilles",
      "Lemon": "Citron",
      "Linen": "Lin",
      "Lupin": "Lupin",
      "Corn": "Maïs",
      "Tangerine": "Mandarine",
      "Almond": "Amande",
      "Eggplant": "Aubergine",
      "Melon": "Melon",
      "Blueberry": "Myrtille",
      "Nectarines": "Nectarine",
      "Kernel": "Noyau",
      "Walnut": "Noix",
      "Bell_pepper": "Poivron",
      "Pear": "Poire",
      "Peach": "Pêche",
      "Pea": "Pois",
      "Leek": "Poireau",
      "Parsley": "Persil",
      "Radicchio": "Radicchio",
      "Turnip": "Navet",
      "Radish": "Radis",
      "Ribes": "Groseille",
      "Shallots": "Échalote",
      "Celery": "Céleri",
      "Soy": "Soja",
      "Spinach": "Épinard",
      "Plum_tree": "Prunier",
      "Tabacco": "Tabac",
      "Vineyard": "Vignoble",
      "Savoy_cabbage": "Chou de Savoie",
      "Pumpkin": "Citrouille",
      "Zucchini_for_industry": "Courgette pour l'industrie",
      "Fresh_zucchini": "Courgette fraîche",
      "Peanuts": "Arachides",
      "Chard": "Blette",
      "Leaf_beet": "Feuilles de betterave",
      "Hemp_for_seeds": "Chanvre (graines)",
      "Hemp_for_fiber": "Chanvre (fibres)",
      "Cardoon": "Cardon",
      "Chestnut": "Châtaigne",
      "Cherry_tree": "Cerisier",
      "French_bean": "Haricot vert frais",
      "Fava_bean": "Fève sèche",
      "Wheat": "Blé",
      "Raspberry": " Framboise",
      "Flax": "Lin",
      "Ley_grass": "Lupin",
      "Corn_for_grain": "Aubergine",
      "Hazelnut": "Noisette",
      "Pear_tree": "Poirier",
      "Peach_tree": "Pêcher",
      "Protein_pea": "Pois protéiné",
      "Processing_tomato": "Tomate de transformation",
      "Table_tomato": "Tomate de table",
      "Table_grape": "Raisin de table",
      "Gooseberry": "Groseille à maquereau",
      "Grapevine": "Vigne",
      "Clover": "Trèfle",
      "Corn_salad": "Salade de maïs",
      "Grapevine_for_wine_grapes": "Vigne pour raisins de vin",
      "Sweet_corn": "Maïs sucré",
      "Silage_corn": "Maïs pour ensilage",
      "Oilseed_rape": "Colza oléagineux",
      "Field-grown_bell_pepper": "Poivron cultivé en champ",
      "Pea_for_industry": "Pois pour l'industrie",
      "Fresh_pea": "Pois frais",
      "Greenhouse_table_tomato": "Tomate de table sous serre",
      "Clover_grassland": "Pâturage de trèfle",
      "Hill_pastures": "Pâturages de colline",
      "Mixed_grassland_>50_legumes": "Pâturage mixte >50% de légumineuses",
      "Artificial_mixed_hill_pastures": "Pâturages de colline mixtes artificiels",
      "Stable_plains": "Plaines stables",
      "Silage_fodder": "Fourrage pour ensilage",
      "Arugula_1st_cut": "Roquette, 1ère coupe",
      "Arugula_2nd_cut": "Roquette, 2ème coupe",
      "Shallot": "Échalote",
      "Arugula_in_open_field": "Roquette en champ ouvert",
      "Organic vs Conventional:": "Biologique vs Conventionnel:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Êtes-vous sûr de vouloir éditer le polygone? Si oui, appuyez à nouveau sur le bouton Mettre à jour le champ",
      "Set the Year of analysis": "Définir l'année d'analyse",
      "Set the nutrient to visualize": "Définir le nutriment à visualiser",
      "Carbonates": "Carbonates",
      "Soil Electrical Conductivity": "Conductivité électrique du sol",
      "pH": "pH",
      "Please set the year": "Veuillez définir l'année",
      "Please set the nutrient": "Veuillez définir le nutriment",
      "Please set the farmer": "Veuillez définir l'agriculteur",
      "Please set the field": "Veuillez définir le champ",
      "The map is not ready yet": "La carte n'est pas encore prête",
      "Please select the object of your request": "Veuillez sélectionner l'objet de votre demande",
      "Please report a valid message": "Veuillez signaler un message valide",
      "Send us your message": "Envoyez-nous votre message",
      "Object of the request:": "Objet de la demande :",
      "Object:": "Objet :",
      "Support Request": "Demande de support",
      "Upgrade account": "Mettre à niveau le compte",
      "Problem with the application": "Problème avec l'application",
      "Other": "Autre",
      "Message of the request:": "Message de la demande :",
      "Message:": "Message :",
      "Send message": "Envoyer le message",
      "My account": "Mon compte",
      "Do you want to upgrade your account?": "Voulez-vous mettre à niveau votre compte ?",
      "Send us a message": "Envoyez-nous un message",
      "Your Message have been sent with success": "Votre message a été envoyé avec succès",
      "As soon as possible one of our operators will check your request and answer you": "Dès que possible, l'un de nos opérateurs vérifiera votre demande et vous répondra",
      "Frumento_tenero": "Blé d'hiver",
      "Winter Wheat": "Blé d'hiver",
      "Annual percentage increase": "Augmentation annuelle en pourcentage",
      "Tons of CO2 Sequestered": "Tonnes de CO2 séquestrées",
      "Carbon Credits Generated": "Crédits carbone générés",
      "Soil Map": "Carte des sols",
      "Temporal Variation": "Variation temporelle",
      "If you want to access to this module data, you must upgrade your account to PRO": "Si vous souhaitez accéder aux données de ce module, vous devez passer à la version PRO de votre compte.",
      "Run Model": "Modèle d'exécution",
      "Prescription AI Map": "Carte de prescription AI",
      "Please set the expeted yield": "Veuillez définir le rendement attendu",
      "Please set the number of the application of fertilizer during the year": "Veuillez définir le nombre d'applications d'engrais pendant l'année",
      "AI Section": "Section IA",
      "Which crop?": "Quelle culture ?",
      "Which nutrient?": "Quel nutriment ?",
      "Expected yield quintals per hectares?": "Rendement attendu quintaux par hectare ?",
      "Percentage of title of the fertilizer": "Pourcentage du titre de l'engrais",
      "Number of the application during the year": "Nombre d'applications pendant l'année",
      "Hectares Deleted": "Hectares supprimés",
      "Search City:": "Rechercher la ville:",
      "Export Document": "Exporter le document",
      "Download Section": "Section de téléchargement",
      "Download Field - Format ShapeFile": "Télécharger champ - Format ShapeFile",
      "Download Field - Format Excel": "Télécharger champ - Format Excel",
      "Download Farmer Data - Format Excel": "Télécharger données agriculteur - Format Excel",
      "Welcome to AIBIOSAT": "Bienvenue sur AIBIOSAT",
      "The 1st AI-based application that support the organic certification": "La première application basée sur l'IA qui soutient la certification biologique",
      "Before to start to use the application you should follow this step by step welcome on boarding procedure": "Avant de commencer à utiliser l'application, vous devez suivre cette procédure d'accueil étape par étape",
      "1) Setting up a Farm": "1) Configuration d'une exploitation agricole",
      "2) Create a Field to be associated with the Farm": "2) Créer un champ à associer à l'exploitation agricole",
      "3) You are done": "3) Vous avez terminé",
      "Let's start": "Commençons",
      "Skip the procedure": "Passer la procédure",
      "Start Using the application": "Commencer à utiliser l'application",
      "Create a farm": "Créer une ferme",
      "Create a field": "Créer un champ",
      "Delete Account": "Supprimer le compte",
      "Are you sure to delete your account?": "Êtes-vous sûr de vouloir supprimer votre compte ?",
      "Are you sure to delete the account?": "Êtes-vous sûr de vouloir supprimer le compte ?",
      "Please be carefull cause if you delete your account all the data related will be removed": "Veuillez faire attention car si vous supprimez votre compte, toutes les données associées seront supprimées",
      "If you want to proceed, please report your email ": "Si vous souhaitez continuer, veuillez indiquer votre adresse e-mail ",
      " in the following form input": " dans le champ de formulaire suivant",
      "Deleting the user...": "Suppression de l'utilisateur...",
      "Delete account": "Supprimer l'utilisateur",
      "In order to register you should accept the Terms And Conditions": "Pour vous inscrire, vous devez accepter les conditions générales d'utilisation.",
      "Account Level:": "Niveau de votre compte:",
      "Farmer company name:": "Nom de l'entreprise agricole:",
      "Your account is basic": "Votre compte est de base",
      "Close": "Fermer",
      "Select the Farm": "Sélectionnez la ferme",
      "No Sensors Are Linked to Your Account": "Aucun capteur n'est lié à votre compte",
      "Table of the Soil Data": "Tableau des données du sol",
      "Depth start (cm)": "Profondeur de départ (cm)",
      "Depth end (cm)": "Profondeur de fin (cm)",
      "Mean Bulk density (cg/cm3)": "Densité en vrac moyenne (cg/cm3)",
      "Uncertainty Bulk density (cg/cm3)": "Incertitude de la densité en vrac (cg/cm3)",
      "Mean Cation Exchange Capacity (mmol/kg)": "Capacité d'échange de cations moyenne (mmol/kg)",
      "Uncertainty Cation Exchange Capacity (mmol/kg)": "Incertitude de la capacité d'échange de cations (mmol/kg)",
      "Mean Clay (g/kg)": "Argile moyenne (g/kg)",
      "Uncertainty Clay (g/kg)": "Incertitude de l'argile (g/kg)",
      "Mean Nitrogen (cg/kg)": "Azote moyen (cg/kg)",
      "Uncertainty Nitrogen (cg/kg)": "Incertitude de l'azote (cg/kg)",
      "Mean pH": "pH moyen",
      "Uncertainty pH": "Incertitude du pH",
      "Mean Sand (g/kg)": "Sable moyen (g/kg)",
      "Uncertainty Sand (g/kg)": "Incertitude du sable (g/kg)",
      "Mean Silt (g/kg)": "Limon moyen (g/kg)",
      "Uncertainty Silt (g/kg)": "Incertitude du limon (g/kg)",
      "Mean SOC (g/kg)": "Matière organique moyenne du sol (g/kg)",
      "Uncertainty SOC (g/kg)": "Incertitude de la matière organique du sol (g/kg)",
      "Operators": "Opérateurs",
      "Warehouse": "Entrepôt",
      "Field Notes": "Notes de terrain",
      "Yield": "Rendement",
      "Seed": "Semence",
      "Fertilizers": "Engrais",
      "Pesticide": "Pesticide",
      "Field Activities": "Activités sur le terrain",
      "Operator Cost": "Coût de l'opérateur",
      "Revenue": "Revenu",
      "Economy": "Économie",
      "Operators Registred": "Opérateurs enregistrés",
      "Create Operator": "Créer un opérateur",
      "Create operator": "Créer un opérateur",
      "Last Name": "Nom de famille",
      "Operator Name:": "Nom de l'opérateur :",
      "Operator Last Name:": "Nom de famille de l'opérateur :",
      "Operator Email:": "Email de l'opérateur :",
      "Operator Phone:": "Téléphone de l'opérateur :",
      "Are you sure to delete this operator?": "Êtes-vous sûr de vouloir supprimer cet opérateur ?",
      "Delete Operator": "Supprimer l'opérateur",
      "Deleting the Operator...": "Suppression de l'opérateur...",
      "Creating the operator...": "Création de l'opérateur...",
      "Update Operator": "Mettre à jour l'opérateur",
      "Updating Operator...": "Mise à jour de l'opérateur...",
      "The name is empty": "Le nom est vide",
      "The last name is empty": "Le nom de famille est vide",
      "The phone is empty": "Le téléphone est vide",
      "You must select a farm to associate to the operators": "Vous devez sélectionner une ferme à associer aux opérateurs",
      "The phone number isn't correct": "Le numéro de téléphone n'est pas correct",
      "Create Warehouse": "Créer Entrepôt",
      "Warehouse Name": "Nom de l'entrepôt",
      "Warehouses Registred": "Entrepôts Enregistrés",
      "Warehouse Map": "Carte des entrepôts",
      "Warehouse Name:": "Nom de l'entrepôt:",
      "Select warehouse location:": "Sélectionnez l'emplacement de l'entrepôt:",
      "Creating the Warehouse...": "Création de l'entrepôt...",
      "Please report the position of the warehouse": "Veuillez indiquer la position de l'entrepôt",
      "Please insert the name of the warehouse": "Veuillez entrer le nom de l'entrepôt",
      "Please insert the farm to relate to the warehouse": "Veuillez indiquer la ferme à associer à l'entrepôt",
      "Edit Warehouse": "Modifier l'entrepôt",
      "The red point is the old location of the warehouse": "Le point rouge est l'ancienne position de l'entrepôt",
      "Editing the Warehouse...": "Modification de l'entrepôt...",
      "Are you sure to delete the warehouse?": "Êtes-vous sûr de vouloir supprimer l'entrepôt?",
      "Latitude:": "Latitude:",
      "Longitude:": "Longitude:",
      "Warehouse Farm:": "Ferme associée à l'entrepôt:",
      "Delete Warehouse": "Supprimer l'entrepôt",
      "Deleting the Warehouse...": "Suppression de l'entrepôt...",
      "Actual Wheater Data": "Données météorologiques actuelles",
      "Wheater Map": "Carte météo",
      "Create Field Note": "Créer une Note de Terrain",
      "Field Note Registred": "Notes de Terrain Enregistrées",
      "Date": "Date",
      "Title of the note": "Titre de la note de terrain",
      "Field Note Title:": "Titre de la note de terrain:",
      "No Field Note Are Registred": "Aucune note de terrain n'est enregistrée",
      "No Field exist for your account": "Aucun terrain n'existe pour votre compte",
      "If you want to register an Field Note you should create first an Operator": "Si vous souhaitez enregistrer une note de terrain, vous devez d'abord créer un opérateur",
      "Select field note location:": "Sélectionnez l'emplacement de la note de terrain:",
      "Warehouse location": "Emplacement de l'entrepôt",
      "Type of Note": "Type de note",
      "Generic": "Générique",
      "Pathogens": "Pathogènes",
      "Insects": "Insectes",
      "Weed": "Mauvaises herbes",
      "Waterlogging": "Engorgement",
      "Generic Damage": "Dommages Génériques",
      "Field Survey": "Enquête de Terrain",
      "Crop Status": "État de la culture",
      "Description": "Description",
      "Operator": "Opérateur",
      "Creating the Field Note...": "Création de la Note de Terrain...",
      "Please insert the title of the field note": "Veuillez insérer le titre de la note de terrain",
      "Please set the farm related to the field note": "Veuillez indiquer la ferme liée à la note de terrain",
      "Please set the field related to the field note": "Veuillez indiquer le champ lié à la note de terrain",
      "Please insert the type of the field note": "Veuillez insérer le type de note de terrain",
      "Please insert the date of the field note": "Veuillez insérer la date de la note de terrain",
      "Please insert the operator that create the field note": "Veuillez insérer l'opérateur qui crée la note de terrain",
      "type": "Type",
      "Title": "Titre",
      "Field Note Map": "Carte des Notes de Terrain",
      "Edit Field Note": "Modifier la Note de Terrain",
      "In red there is the old Field Note": "En rouge, l'ancienne position de la note de terrain",
      "Old Field Note": "Ancienne note de terrain",
      "Editing the Field Note...": "Modification de la Note de Terrain...",
      "Please report the position of the field note": "Veuillez indiquer la position de la note de terrain",
      "Field Note Date:": "Date de la note de terrain:",
      "Field Note Farm:": "Ferme de la note de terrain:",
      "Field Note Field:": "Champ de la note de terrain:",
      "Field Note Operator:": "Opérateur de la note de terrain:",
      "Field Note Type:": "Type de note de terrain:",
      "Field Note Description:": "Description de la note de terrain:",
      "Delete Field Note": "Supprimer la Note de Terrain",
      "Deleting the Field Note...": "Suppression de la Note de Terrain...",
      "Are you sure to delete this field note?": "Êtes-vous sûr de vouloir supprimer cette note de champ ?",
      "Field Note Data": "Données des notes de terrain",
      "Create Yield data": "Créer des données de rendement",
      "Yield Data Registred": "Données de rendement enregistrées",
      "Harvest Date": "Date de récolte",
      "Crop": "Culture",
      "Total Quantity": "Quantité totale",
      "Price": "Prix",
      "Total Cost": "Coût total",
      "No Yield data are registred": "Aucune donnée de rendement n'est enregistrée",
      "No Operator exist for your account": "Aucun opérateur n'est enregistré pour votre compte",
      "If you want to register an Yield you should create first a Operator": "Si vous souhaitez enregistrer un rendement, vous devez d'abord créer un opérateur",
      "If you want to register an Yield you should create first a Field": "Si vous souhaitez enregistrer un rendement, vous devez d'abord créer un champ",
      "Please select the farm": "Veuillez sélectionner la ferme",
      "Please select the field": "Veuillez sélectionner un champ",
      "Please select the operator": "Veuillez sélectionner l'opérateur",
      "Please set the harvest date": "Veuillez sélectionner une date de récolte",
      "Please set the total yield": "Veuillez entrer le rendement total",
      "Please set the price of the yield": "Veuillez entrer le prix de vente du rendement",
      "Please set the cost of the operation": "Veuillez entrer le coût de l'opération de récolte",
      "Register a Yield": "Enregistrer une donnée de rendement",
      "The crop is: ": "La culture est : ",
      "Total Yield quintals": "Rendement total en quintaux",
      "Price selled euro per quintal": "Prix de vente par quintal",
      "Did you use a subcontractors?": "Avez-vous utilisé des sous-traitants?",
      "Subcontractor Name": "Nom du sous-traitant",
      "Total cost of the harvest operation": "Coût total de l'opération de récolte",
      "Create Yield": "Créer rendement",
      "Creating the Yield...": "Création des données de rendement...",
      "Update Yield": "Mettre à jour le rendement",
      "Updating the Yield...": "Mise à jour du rendement...",
      "Are you sure to delete this yield data?": "Êtes-vous sûr de vouloir supprimer les données de rendement?",
      "Delete Yield": "Supprimer les données de rendement",
      "Deleting the Yield...": "Suppression des données de rendement...",
      "Total cost to harvest": "Coût total de l'opération de récolte",
      "Create Seed": "Créer Semence",
      "Seed Registred": "Graine Enregistrée",
      "Macro Type": "Type Macro",
      "Type": "Type",
      "Commercial Product Name": "Nom du Produit Commercial",
      "Organic Approved": "Produit Approuvé pour l'Agriculture Biologique?",
      "Duplicate": "Dupliquer",
      "Please select the unit doses": "Veuillez sélectionner la dose",
      "Please set the name of the seed": "Veuillez entrer le nom du produit commercial",
      "Please select the type of the seed": "Veuillez sélectionner le type de graine/plant",
      "Please set the productor name": "Veuillez sélectionner le nom du producteur",
      "Please set the crop name": "Veuillez entrer le nom de la culture",
      "Commercial Product Name:": "Nom du Produit Commercial:",
      "Product Type:": "Type de Produit:",
      "Unit Dose:": "Dose:",
      "Can be used in organic farming production?": "Peut être utilisé dans la production biologique?",
      "Day Growth Cycle": "Cycle de Croissance en Jours",
      "FAO Class": "Classe FAO",
      "Productor": "Producteur",
      "Creating the Seed...": "Création de la graine...",
      "Are you sure to delete this seed?": "Êtes-vous sûr de vouloir supprimer cette graine?",
      "Macro Type:": "Type Macro:",
      "Is organic product approved": "Produit approuvé pour l'agriculture biologique",
      "Unit dose": "Dose",
      "Delete seed": "Supprimer la graine",
      "Deleting the seed...": "Suppression de la graine...",
      "Duplicate Seed": "Dupliquer la graine",
      "Edit Seed": "Modifier la graine",
      "Editing the Seed...": "Mise à jour de la graine...",
      "Create Fertilizer": "Créer un Engrais",
      "Fertilizer Registred": "Engrais Enregistré",
      "Please set the name of the fertilizer": "Veuillez entrer le nom commercial de l'engrais",
      "Please set the type of fertilizer": "Veuillez sélectionner le type d'engrais",
      "Please set the nitrogen": "Veuillez entrer le pourcentage d'azote de l'engrais",
      "Please set the potassium": "Veuillez entrer le pourcentage de potassium de l'engrais",
      "Please set the phosphorus": "Veuillez entrer le pourcentage de phosphore de l'engrais",
      "Nitrogen Percentange": "Pourcentage d'azote",
      "Phosphorus Percentange": "Pourcentage de phosphore",
      "Potassium Percentange": "Pourcentage de potassium",
      "Are you sure to delete this fertilizer?": "Êtes-vous sûr de vouloir supprimer cet engrais?",
      "Delete Fertilizer": "Supprimer l'engrais",
      "Deleting the Fertilizer...": "Suppression de l'engrais...",
      "Creating the Fertilizer...": "Création de l'engrais...",
      "Duplicate Fertilizer": "Dupliquer l'engrais",
      "Edit Fertilizer": "Modifier l'engrais",
      "Editing the Fertilizer...": "Modification de l'engrais...",
      "Create Product": "Créer un produit",
      "Product Registred": "Produit enregistré",
      "Register Number": "Numéro d'enregistrement",
      "Registration Date": "Date d'enregistrement",
      "Product Formulation:": "Formulation du produit:",
      "Active substances:": "Substances actives:",
      "Content per 100 grams": "Contenu pour 100 grammes",
      "Danger Indications": "Indications de danger",
      "Phenological Stage": "Stade phénologique",
      "Scientific name adversity": "Nom scientifique de l'adversité",
      "Common name adversity": "Nom commun de l'adversité",
      "Maximum dose": "Dose maximale",
      "Minimum dose": "Dose minimale",
      "Shortage time": "Temps de carence - Jours avant que le produit puisse être récolté/commercialisé",
      "Return time": "Temps de retour - Jours minimum avant de permettre le retour des opérateurs dans le champ",
      "Minimum interval between treatments": "Intervalle minimum entre les traitements",
      "Maximum interval between treatments": "Intervalle maximum entre les traitements",
      "Maximum number of treatments": "Nombre maximum de traitements",
      "Unit for maximum number of treatment": "Unité pour le nombre maximum de traitements",
      "Max Volume of water": "Volume maximum d'eau",
      "Min Volume of water": "Volume minimum d'eau",
      "Creating the Product...": "Création du produit...",
      "Are you sure to delete this product?": "Êtes-vous sûr de vouloir supprimer ce produit?",
      "Organic Product": "Produit biologique",
      "Delete Product": "Supprimer le produit",
      "Deleting the Product...": "Suppression du produit...",
      "Duplicate Product": "Dupliquer le produit",
      "Edit Product": "Modifier le produit",
      "Editing the Product...": "Modification du produit...",
      "Cost": "Coût",
      "Create Field Activities": "Créer des Activités sur le Terrain",
      "Type of Field Activity": "Type d'Activité sur le Terrain",
      "Tillage": "Labour",
      "Sowing / Planting": "Semis / Plantation",
      "Defence": "Défense",
      "Tillage Type": "Type de Labour",
      "Plowing": "Labourage",
      "Harrowing": "Hersage",
      "Hoeing": "Binage",
      "Weeding": "Désherbage",
      "Clod breaking": "Décompactage",
      "Milling": "Fraisage",
      "Ripping": "Déchiquetage",
      "Rolling": "Rouleau",
      "Compaction": "Compactage",
      "Mulching": "Paillage",
      "Shredding": "Broyage",
      "Tillage Depht cm": "Profondeur de Labour en cm",
      "You didn't create a seed object": "Vous n'avez pas créé d'objet de semence",
      "Sowing Dose kg / ha": "Dose de Semis kg / ha",
      "Irrigation Volume m3/ha": "Volume d'Irrigation m3/ha",
      "You didn't create a Fertilizer object": "Vous n'avez pas créé d'objet d'engrais",
      "Create a fertilizer": "Créer un Engrais",
      "Fertilization Product": "Produit d'Engrais",
      "Fertilization Dose kg/ha": "Dose d'Engrais kg/ha",
      "You didn't create a Defence object": "Vous n'avez pas créé d'objet de Défense",
      "Create a Defence Product": "Créer un Produit de Défense",
      "Defence Product": "Produit de Défense",
      "Defence Type": "Type de Produit de Défense",
      "Dose Used": "Dose Utilisée",
      "Volume Water Used": "Volume d'Eau Utilisé",
      "Level of infestaction": "Niveau d'Infestation",
      "Low": "Faible",
      "Medium": "Moyen",
      "High": "Élevé",
      "Authorization Technichian?": "Autorisation du Technicien ?",
      "Respect strip": "Respect de la Bande",
      "Exceeding the intervention threshold": "Dépassement du Seuil d'Intervention",
      "It is done?": "C'est fait ?",
      "Crop Phenology": "Phénologie de la Culture",
      "Hour of work": "Heures de Travail",
      "Variety": "Variété",
      "Subcontractors?": "Sous-traitants ?",
      "Wheater Class": "Classe Météorologique",
      "Temperature °C": "Température °C",
      "Wind Speed km / h": "Vitesse du Vent km / h",
      "Humidity %": "Humidité %",
      "Rainfall mm": "Précipitations mm",
      "Create Field Activity": "Créer une Activité sur le Terrain",
      "Creating the Field Activity...": "Création de l'Activité sur le Terrain...",
      "Are you sure to delete this field activity?": "Êtes-vous sûr de vouloir supprimer cette activité sur le terrain ?",
      "Delete Field Activity": "Supprimer l'Activité sur le Terrain",
      "Deleting the Field Activity...": "Suppression de l'Activité sur le Terrain...",
      "Edit Field Activities": "Modifier les Activités sur le Terrain",
      "Edit Field Activity": "Modifier l'Activité sur le Terrain",
      "Editing the Field Activity...": "Modification de l'Activité sur le Terrain...",
      "Seed / Planting": "Semis / Plantation",
      "Field Activity": "Activité sur le Terrain",
      "Date of Hire": "Date d'embauche",
      "Operator Cost Registered": "Coût de l'opérateur enregistré",
      "Create Operator Cost": "Créer le coût de l'opérateur",
      "No Operator are available for your account.": "Aucun opérateur n'est disponible pour votre compte.",
      "First you have to create an operator": "Vous devez d'abord créer un opérateur",
      "No Farm are available for your account.": "Aucune ferme n'est disponible pour votre compte.",
      "First you have to create an Farm": "Vous devez d'abord créer une ferme",
      "Please set the year operotor cost": "Veuillez définir le coût annuel de l'opérateur",
      "Create a Operator Cost": "Créer un coût d'opérateur",
      "Operator year salary": "Salaire annuel de l'opérateur",
      "Creating the Operator Cost...": "Création du coût de l'opérateur en cours...",
      "Are you sure to delete this operator cost": "Êtes-vous sûr de vouloir supprimer ce coût de l'opérateur ?",
      "Operator Cost Detail": "Détails du coût de l'opérateur",
      "Delete Operator Cost": "Supprimer le coût de l'opérateur",
      "Deleting the Operator Cost...": "Suppression du coût de l'opérateur en cours...",
      "Duplicate a Operator Cost": "Dupliquer un coût de l'opérateur",
      "Duplicate Operator Cost": "Dupliquer le coût de l'opérateur",
      "Duplicating the Operator Cost...": "Duplication du coût de l'opérateur en cours...",
      "Edit a Operator Cost": "Modifier un coût de l'opérateur",
      "Edit Operator Cost": "Modifier le coût de l'opérateur",
      "Editing the Operator Cost...": "Modification du coût de l'opérateur en cours...",
      "Create Revenue": "Créer des Revenus",
      "Revenue Registered": "Revenus enregistrés",
      "Total Revenue": "Revenus totaux",
      "Please set the total revenue": "Veuillez entrer la valeur des revenus totaux",
      "Please set the type of the revenue": "Veuillez entrer le type de revenus",
      "Register a Revenue": "Enregistrer un revenu",
      "Select the type of revenue:": "Sélectionnez le type de revenu:",
      "Direct Payments": "Paiements directs",
      "pac": "PAC",
      "indinizzi": "Indices",
      "Agricultural compensation": "Compensations agricoles",
      "Creating the Revenue...": "Création du revenu...",
      "Are you sure to delete this revenue?": "Êtes-vous sûr de vouloir supprimer ce revenu?",
      "Delete Revenue": "Supprimer le revenu",
      "Deleting the Revenue...": "Suppression du revenu...",
      "Duplicate a Revenue": "Dupliquer un revenu",
      "Duplicate Revenue": "Dupliquer le revenu",
      "Duplicating the Revenue...": "Duplication du revenu...",
      "Edit a Revenue": "Modifier le revenu",
      "Edit Revenue": "Modifier le revenu",
      "Editing the Revenue...": "Modification du revenu...",
      "Farmer Economy Analysis": "Analyse économique de l'agriculteur",
      "Farm Profit": "Bénéfice de la ferme",
      "Farm Revenue": "Revenu de la ferme",
      "Farm Total Cost": "Coût total de la ferme",
      "Economy Data": "Données économiques",
      "Activity": "Activité",
      "Cost/Price": "Coût/Prix",
      "Quantity/Hours": "Quantité/Heures",
      "Cost allocation": "Allocation des coûts",
      "Allocation of Revenues": "Répartition des revenus",
      "Revenues": "Revenus",
      "Monthly Costs and Revenues": "Coûts et revenus mensuels",
      "Cash Flow": "Flux de trésorerie",
      "Air Temperature": "Température de l'air",
      "Atmospheric Pressure": "Pression atmosphérique",
      "Rainfall": "Précipitations",
      "Air Humidity": "Humidité de l'air",
      "Evapotraspiration": "Évapotranspiration",
      "Degree Day": "Degré jour",
      "TWH Index": "Indice TWH",
      "Dew Point": "Point de rosée",
      "Gust": "Rafale",
      "Real time data or Historic data?": "Données en temps réel ou données historiques?",
      "Last 24 Hours Data": "Données des dernières 24 heures",
      "Degree Days": "Degrés jour",
      "Leaf Wetness": "Humidité des feuilles",
      "Irrigation Analysis": "Analyse de l'irrigation",
      "Download Operator - Format Excel": "Télécharger opérateur - Format Excel",
      "Download Warehouse - Format Excel": "Télécharger entrepôt - Format Excel",
      "Download Field Note - Format Excel": "Télécharger note de terrain - Format Excel",
      "Download Yield - Format Excel": "Télécharger rendement - Format Excel",
      "Download Field Activity - Format Excel": "Télécharger activité de terrain - Format Excel",
      "Download Other Revenue - Format Excel": "Télécharger autres revenus - Format Excel",
      "Download Operator Cost - Format Excel": "Télécharger coût des opérateurs - Format Excel",
      "generic": "Générique",
      "phatogens": "Pathogènes",
      "insect": "Insectes",
      "weed": "Mauvaises herbes",
      "waterlogging": "Engorgement",
      "genericdamage": "Dommage générique",
      "fieldsurvey": "Enquête de terrain",
      "cropstatus": "État de la culture",
      "acaricida": "Acaricide",
      "aficida": "Aphicide",
      "antideriva": "Antidérive",
      "antidoto": "Antidote",
      "antigermogliante": "Antigerminatif",
      "antiriscaldo": "Antichauffe",
      "attrattivo": "Attractif",
      "bagnante": "Mouillant",
      "coaudiuvante": "Coaide",
      "diradante": "Éclaircissant",
      "feromone": "Phéromone",
      "fitoregolatore": "Phytorégulateur",
      "geodisinfestante": "Géodésinfestant",
      "repellente": "Répulsif",
      "sinergizzante": "Synergisant",
      "adiuvante": "Adjuvant",
      "fungicida": "Fongicide",
      "diserbante": "Herbicide",
      "insetticida": "Insecticide",
      "molluschicida": "Molluscicide",
      "organic_product": "Produit biologique",
      "nematocida": "Nématicide",
      "sostanza_di_crescita": "Substance de croissance",
      "biostimolante": "Biostimulant",
      "ritirato": "Retiré",
      "attivo": "Actif",
      "kg_ha": "kg/ha",
      "l_ha": "l/ha",
      "unita_ha": "Unités/ha",
      "anno": "Année",
      "ciclo": "Cycle",
      "stagione": "Saison",
      "semente": "Semence",
      "piantine": "Plantules",
      "libre_acro": "Livres/acre",
      "minerale": "Minéral",
      "organico": "Organique",
      "tillage": "Labour",
      "sowing": "Semis",
      "defence": "Défense",
      "irrigation": "Irrigation",
      "nutrition": "Nutrition",
      "sereno": "Dégagé",
      "nuvoloso": "Nuageux",
      "variabile": "Variable",
      "molto_nuvoloso": "Très nuageux",
      "coperto": "Couvert",
      "nebbia": "Brouillard",
      "coperto_nebbia": "Couvert de brouillard",
      "variabile_con_rovesci": "Variable avec averses",
      "temporale": "Orage",
      "coperto_con_neve": "Couvert de neige",
      "pioggia_misto_neve": "Pluie mêlée de neige",
      "erbacee_botticella": "Herbacées bouton floral",
      "erbacee_fioritura": "Herbacées floraison",
      "erbacee_invaiatura": "Herbacées véraison",
      "erbacee_allegagione": "Herbacées nouaison",
      "erbacee_ingrossamento": "Herbacées grossissement",
      "erbacee_germinazione": "Herbacées germination",
      "erbacee_emergenza_infioriscenza": "Herbacées émergence inflorescence",
      "erbacee_sviluppo_fogliare": "Herbacées développement foliaire",
      "erbacee_maturazione": "Herbacées maturation",
      "erbacee_senescenza": "Herbacées sénescence",
      "erbacee_levata": "Herbacées montaison",
      "erbacee_accestimento": "Herbacées tallage",
      "arboree_gemme_inverno": "Arborées bourgeons hiver",
      "arboree_apertura_gemme": "Arborées ouverture des bourgeons",
      "arboree_ripresa_vegetativa": "Arborées reprise végétative",
      "arboree_sviluppo_germogli": "Arborées développement des pousses",
      "arboree_foglie_distese": "Arborées feuilles étalées",
      "arboree_grappoli_visibili": "Arborées grappes visibles",
      "arboree_grappoli_separati": "Arborées grappes séparées",
      "arboree_ingrossamento_frutti": "Arborées grossissement des fruits",
      "arboree_pre_chiusura_grappolo": "Arborées pré-fermeture de la grappe",
      "arboree_chiusura_grappolo": "Arborées fermeture de la grappe",
      "arboree_indurimento_nocciolo": "Arborées durcissement du noyau",
      "arboree_invaitura": "Arborées véraison",
      "arboree_riposo_vegetativo": "Arborées repos végétatif",
      "arboree_fioritura": "Arborées floraison",
      "arboree_allegagione": "Arborées nouaison",
      "arboree_pianta_ferma": "Arborées plante en arrêt",
      "arboree_germogliamento": "Arborées bourgeonnement",
      "arboree_germogli_5_10_cm": "Arborées pousses 5-10 cm",
      "arboree_comparsa_boccioli_floreali": "Arborées apparition des boutons floraux",
      "arboree_bottone_bianco": "Arborées bouton blanc",
      "arboree_crescita": "Arborées croissance",
      "arboree_semi_neri": "Arborées graines noires",
      "arboree_pre_raccolta": "Arborées pré-récolte",
      "arboree_raccolta": "Arborées récolte",
      "basso": "Bas",
      "medio": "Moyen",
      "alto": "Élevé",
      "aratura": "Labour",
      "erpicatura": "Hersage",
      "zappatura": "Binage",
      "sarchiatura": "Sarclage",
      "frangizollatura": "Déchaumage",
      "fresatura": "Fraisage",
      "rippatura": "Décompactage",
      "rullatura": "Roulage",
      "compattazione": "Compactage",
      "pacciamatura": "Paillage",
      "trinciatura": "Broyage",
      "Seedlings": "Semis",
      "Pounds per acre": "Livres par acre",
      "Mineral Product": "Produit Minéral",
      "Nutrition": "Nutrition",
      "Historic Data": "Données Historiques",
      "Withdrawn": "Retiré",
      "Active": "Actif",
      "Year": "Annuel",
      "Cycle": "Cycle",
      "Season": "Saison",
      "Defense": "Défense",
      "Active or Retrivied:": "Actif ou retiré",
      "Please fill all the field": "Veuillez remplir tous les champs",
      "Please fill all the form to create a field activities": "Veuillez remplir tout le formulaire pour créer une activité sur le terrain",
      "Other Revenue": "Autres Revenus",
      "Harvest": "Récolte",
      "Please insert the date of the revenue": "Veuillez insérer la date du revenu",
      "Please enter the start date of the operator cost": "Veuillez entrer la date de début du coût de l'opérateur",
      "Pear_fruit": "Poire",
      "Dry_pulses": "Légumineuses sèches",
      "Strawberries": "Fraise",
      "Tobacco": "Tabac",
      "Other_fibre_and_oleaginous_crops": "Autres cultures fibreuses et oléagineuses",
      "Other_fresh_vegetables": "Autres légumes frais",
      "Upgrade to PRO": "Passez à PRO",
      "Something Went Wrong During the Payment": "Une erreur s'est produite pendant le paiement",
      "Please send us an email to info@automaticfarmsolution.com": "Veuillez nous envoyer un e-mail à info@automaticfarmsolution.com",
      "An operator will answear you as soon as possible": "Un opérateur vous répondra dès que possible",
      "VAT Excluded": "TVA Exclue",
      "The total price is 832 € (VAT Included)": "Le prix total est de 832 € (TVA incluse)",
      "All the modules of free version": "Tous les modules de la version gratuite",
      "Generation of prescription map with Artificial Intelligence": "Génération de carte de prescription avec Intelligence Artificielle",
      "Capability to connect IoT Sensors": "Capacité à connecter des capteurs IoT",
      "Set automatic alerts": "Configurer des alertes automatiques",
      "Artificial Intelligence Digital Soil Mapping": "Cartographie numérique des sols avec Intelligence Artificielle",
      "Advanced Report & Documentation": "Rapports avancés & Documentation",
      "Online Live Training of the Platform": "Formation en direct en ligne de la plateforme",
      "Number of Plant": "Nombre de plantes",
      "Number of note": "Nombre de notes",
      "Note": "Note",
      "Add Note": "Ajouter une note",
      "Download Note": "Télécharger la note",
      "List of the note": "Liste des notes enregistrées",
      "User": "Utilisateur",
      "Plant": "Plante",
      "Image": "Image",
      "Created at": "Créée le",
      "Detail": "Détails",
      "No Note are Registrated": "Aucune note n'est enregistrée",
      "Plant Number": "Numéro de plante",
      "Upload or Capture Image": "Télécharger ou capturer une image",
      "Save": "Enregistrer",
      "Detail Note - ": "Détail de la note - ",
      "User - ": "Utilisateur ayant créé la note - ",
      "Plant - ": "Plante - ",
      "Note - ": "Note - ",
      "Image": "Image",
      "Created at - ": "Créée le - ",
      "Are you sure to delete the note?": "Êtes-vous sûr de vouloir supprimer la note ?",
      "Update Note": "Mettre à jour la note",
      "New registration": "Nouvelle inscription",
      "You don't have yet the permission to use this application": "Vous n'avez pas encore l'autorisation d'utiliser cette application",
      "Please use the following button to send a request to the wine manager to be activated": "Pour commencer à utiliser l'application, utilisez le bouton ci-dessous pour envoyer une demande au responsable des vins pour son approbation",
      "Send the request": "Envoyer la demande",
      "The plant cannot be empty": "Le numéro de la plante ne peut pas être vide",
      "The note cannot be empty": "La note ne peut pas être vide",
      "You must upload a image": "Vous devez télécharger une image"
    }
  },
  "DE": {
    "translation": {
      "Hello": "Hallo",
      "Set the analysis": "Legen Sie die Analyse",
      "Number of farmer": "Anzahl der Landwirte",
      "Phosphorus": "Phosphor",
      "Yes": "Ja",
      "No": "Nein",
      "Temporary_grassland": "Temporäre Wiese",
      "Grassland": "Wiese",
      "Clovers": "Klee",
      "Olive_groves": "Olivenhaine",
      "Olive": "Olive",
      "Oranges": "Orangen",
      "Apple_fruit": "Apfel",
      "Apple": "Apfel",
      "Cherry_fruit": "Kirsche",
      "Cherry": "Kirsche",
      "Nuts_trees": "Nussbäume",
      "Nuts": "Nüsse",
      "Rye": "Roggen",
      "Potatoes": "Kartoffeln",
      "Triticale": "Triticale",
      "Sugar_beet": "Zuckerrübe",
      "Sugar Beet": "Zuckerrübe",
      "Soya": "Soja",
      "Cotton": "Baumwolle",
      "Tomatoes": "Tomaten",
      "Do you want to define the number of zone in Automatically way or Manual?": "Möchtest du die Anzahl der Zonen automatisch oder manuell festlegen?",
      "If is set Manual, how many zones do you want to create?": "Wenn manuell eingestellt, wie viele Zonen möchtest du erstellen?",
      "Select the Pest Model To Use": "Wähle das Schädlingsmodell aus, das verwendet werden soll",
      "Number of field": "Anzahl der Felder",
      "Farmer Registred": "Landwirt registriert",
      "Field Registred": "Feld registriert",
      "Durum_wheat": "Hartweizen",
      "Download data": "Daten herunterladen",
      "The email is empty": "Die E-Mail ist leer",
      "The password is empty": "Das Passwort ist leer",
      "The user is not active!": "Der Benutzer ist nicht aktiv!",
      "The email is used": "Die E-Mail wird bereits verwendet",
      "Forgot password?": "Passwort vergessen?",
      "Create new account": "Neues Konto erstellen",
      "Check your email inbox and follow the instructions to change the password.": "Überprüfen Sie Ihr E-Mail-Postfach und folgen Sie den Anweisungen, um das Passwort zu ändern.",
      "Go back to Login": "Zurück zum Login",
      "The email doesn't exist in our database!": "Die E-Mail existiert nicht in unserer Datenbank!",
      "Change Password": "Passwort ändern",
      "Send Email": "E-Mail senden",
      "Sending email...": "E-Mail wird gesendet...",
      "The first password is empty": "Das erste Passwort ist leer",
      "The confirm password is empty": "Das Bestätigungspasswort ist leer",
      "The password don't match": "Die Passwörter stimmen nicht überein",
      "Password change failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Passwortänderung fehlgeschlagen. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "An error occurred while changing the password. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Es ist ein Fehler aufgetreten beim Ändern des Passworts. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Confirm Password": "Passwort bestätigen",
      "Changing Password...": "Passwort wird geändert...",
      "The password has been changed with success. Now use the new password to login.": "Das Passwort wurde erfolgreich geändert. Verwenden Sie jetzt das neue Passwort zum Einloggen.",
      "In order to register you should accept the Privacy Policy": "Um sich zu registrieren, müssen Sie die Datenschutzrichtlinie akzeptieren",
      "In order to register you should provide your name": "Um sich zu registrieren, müssen Sie Ihren Namen angeben",
      "In order to register you should provide a valid email": "Um sich zu registrieren, müssen Sie eine gültige E-Mail-Adresse angeben",
      "The current email is used, please use another one!": "Die aktuelle E-Mail wird bereits verwendet, bitte verwenden Sie eine andere!",
      "Registration": "Registrierung",
      "Name": "Name",
      "I agree with the": "Ich stimme der",
      "Register": "Registrieren",
      "Registration...": "Registrierung...",
      "Already have an account?": "Sie haben bereits ein Konto?",
      "Check your email inbox and follow the instructions to activate your account.": "Überprüfen Sie Ihr E-Mail-Postfach und folgen Sie den Anweisungen, um Ihr Konto zu aktivieren.",
      "Activation of your account failed. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Die Aktivierung Ihres Kontos ist fehlgeschlagen. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "An error occurred during the activation of your account. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Es ist ein Fehler aufgetreten während der Aktivierung Ihres Kontos. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Do you want to activate your account?": "Möchten Sie Ihr Konto aktivieren?",
      "Activate my account": "Mein Konto aktivieren",
      "Activation...": "Aktivierung...",
      "Your Account has been activated. Now You can login": "Ihr Konto wurde aktiviert. Jetzt können Sie sich anmelden",
      "Welcome!": "Willkommen!",
      "My profile": "Mein Profil",
      "Settings": "Einstellungen",
      "Support": "Support",
      "Logout": "Abmelden",
      "Farm": "Bauernhof",
      "Field": "Feld",
      "Field Notebook": "Feldnotizbuch",
      "Agrometeo": "Agrometeo",
      "Weather": "Wetter",
      "Climatic Index": "Klimaindex",
      "Satellite": "Satellit",
      "Prescription Map": "Verschreibungskarte",
      "Sensors": "Sensoren",
      "Summary": "Zusammenfassung",
      "Analysis": "Analyse",
      "Forecast": "Prognose",
      "Anomaly Detection": "Anomalieerkennung",
      "Comparation": "Vergleich",
      "Allert": "Warnung",
      "Bio Trap": "Biofalle",
      "Agronomic tools": "Agronomische Werkzeuge",
      "Nutrient Balance": "Nährstoffbilanz",
      "Soil Data": "Bodendaten",
      "Irrigation": "Bewässerung",
      "Pest Model": "Schädlingsmodell",
      "Artificial Intelligence": "Künstliche Intelligenz",
      "Digital Consulting": "Digitale Beratung",
      "Crop Models & simulations": "Pflanzenmodelle & Simulationen",
      "Soil Mapping & Carbon Credits": "Bodenkartierung & Kohlenstoffguthaben",
      "Organic vs Conventional": "Bio vs Konventionell",
      "Connections & Data Sharing": "Verbindungen & Datenaustausch",
      "Upgrade your profile": "Profil aktualisieren",
      "Register a new farmer": "Neuen Landwirt registrieren",
      "Farmer Company Name:": "Name des landwirtschaftlichen Unternehmens",
      "Farmer First Name:": "Vorname des Landwirts:",
      "Farmer Surname:": "Nachname des Landwirts:",
      "Farmer Country:": "Land des Landwirts:",
      "Farmer City:": "Stadt des Landwirts:",
      "Farmer Address:": "Adresse des Landwirts:",
      "Farmer Email:": "E-Mail des Landwirts:",
      "Farmer Phone Number:": "Telefonnummer des Landwirts:",
      "Create Farm": "Farm erstellen",
      "Go back": "Zurück",
      "Restart Creation": "Neustart der Erstellung",
      "Creating the farm...": "Farm wird erstellt...",
      "Farm Registered": "Farm registriert",
      "No farmer are registered": "Keine Landwirte sind registriert",
      "If you want to start using this application, you should start by registering a new farmer.": "Wenn Sie diese Anwendung verwenden möchten, sollten Sie damit beginnen, einen neuen Landwirt zu registrieren.",
      "Farm Name": "Farmname",
      "Surname": "Nachname",
      "Email": "E-Mail",
      "Phone Number": "Telefonnummer",
      "Edit": "Bearbeiten",
      "Delete": "Löschen",
      "Are you sure to remove the following farmer?": "Sind Sie sicher, den folgenden Landwirt zu entfernen?",
      "Name of the farmer:": "Name des Landwirts:",
      "Surname of the farmer:": "Nachname des Landwirts:",
      "Country of the farmer:": "Land des Landwirts:",
      "City of the farmer:": "Stadt des Landwirts:",
      "Address of the farmer:": "Adresse des Landwirts:",
      "Email of the farmer:": "E-Mail des Landwirts:",
      "Phone Number of the farmer:": "Telefonnummer des Landwirts:",
      "Delete Farmer": "Landwirt löschen",
      "Invalid email address": "Ungültige E-Mail-Adresse",
      "Invalid phone number": "Ungültige Telefonnummer",
      "Add Farm": "Farm hinzufügen",
      "Farmer Registered": "Landwirt registriert",
      "Farmer": "Landwirt",
      "No registered farm": "Keine registrierte Farm",
      "Loading": "Laden",
      "Update Farm": "Farm aktualisieren",
      "Updating the farm...": "Farm wird aktualisiert...",
      "The Name of the farmer is empty": "Der Name des Landwirts ist leer",
      "The Surname of the farmer is empty": "Der Nachname des Landwirts ist leer",
      "The CountryCode of the farmer is empty": "Der Ländercode des Landwirts ist leer",
      "The City of the farmer is empty": "Die Stadt des Landwirts ist leer",
      "The Address of the farmer is empty": "Die Adresse des Landwirts ist leer",
      "The Email of the farmer is empty": "Die E-Mail des Landwirts ist leer",
      "The Phone Number of the farmer is empty": "Die Telefonnummer des Landwirts ist leer",
      "An error occurred while updating the farm. If the problem persists, please contact us at automaticfarmsolution@gmail.com": "Ein Fehler ist bei der Aktualisierung der Farm aufgetreten. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte unter automaticfarmsolution@gmail.com",
      "Create Field": "Feld erstellen",
      "You don't have any farm registred, first you have to set at least one farmer": "Sie haben keine registrierte Farm, zuerst müssen Sie mindestens einen Landwirt einrichten",
      "Field name:": "Feldname:",
      "Crop Rotation:": "Fruchtwechsel:",
      "Crop Rotation": "Fruchtwechsel",
      "Perennial crop": "Mehrjährige Kultur",
      "Two year crop rotation": "Zweijähriger Fruchtwechsel",
      "Three year crop rotation": "Dreijähriger Fruchtwechsel",
      "Actual Crop": "Aktuelle Kultur",
      "Alfalfa": "Alfalfa",
      "Barley": "Gerste",
      "Maize": "Mais",
      "Durum Wheat": "Durumweizen",
      "The next year Crop": "Kultur im nächsten Jahr",
      "The crop in two years' time": "Kultur in zwei Jahren",
      "Organic": "Biologisch",
      "Conventional": "Konventionell",
      "Draw the edge of your field:": "Zeichnen Sie den Rand Ihres Feldes:",
      "The field name is empty": "Der Feldname ist leer",
      "Please draw the edge of your field on the map": "Bitte zeichnen Sie den Rand Ihres Feldes auf der Karte",
      "An error occurred while creating the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Ein Fehler ist beim Erstellen des Feldes aufgetreten. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "List of the field": "Liste der Felder",
      "Field Name:": "Feldname:",
      "Field Detail": "Felddetails",
      "Crop:": "Kultur:",
      "Agronomic Management:": "Agronomisches Management:",
      "Are you sure to delete this field?": "Sind Sie sicher, dieses Feld zu löschen?",
      "Delete Field": "Feld löschen",
      "Field:": "Feld:",
      "Loading...": "Laden...",
      "Edit Field": "Feld bearbeiten",
      "Draw again the edge of your field:": "Zeichnen Sie den Rand Ihres Feldes erneut:",
      "In red there is the old field": "In Rot befindet sich das alte Feld",
      "An error occurred while editing the field. If the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Ein Fehler ist beim Bearbeiten des Feldes aufgetreten. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Try Again": "Erneut versuchen",
      "Something went wrong. Please refresh the page and if the problem persists, please send us an email at automaticfarmsolution@gmail.com": "Etwas ist schiefgegangen. Bitte aktualisieren Sie die Seite und wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Select the field to analyze": "Wählen Sie das Feld zur Analyse aus",
      "View Satellite Image": "Satellitenbild anzeigen",
      "Field Details": "Felddetails",
      "Actual Crop:": "Aktuelle Kultur:",
      "Time series plot": "Zeitreihenplot",
      "Satellite Image": "Satellitenbild",
      "Select one field": "Wählen Sie ein Feld aus",
      "Field Boundary": "Feldgrenze",
      "Select the Vegetation Index": "Wählen Sie den Vegetationsindex aus",
      "Set the prescription map": "Setzen Sie die Verschreibungskarte",
      "Do you want to define the number of zones in an Automatic way or Manual?": "Möchten Sie die Anzahl der Zonen automatisch oder manuell definieren?",
      "Automatic": "Automatisch",
      "Manual": "Manuell",
      "If it is set to Manual, how many zones do you want to create?": "Wenn es auf Manuell eingestellt ist, wie viele Zonen möchten Sie erstellen?",
      "Max fertilizer that you want to apply?": "Maximaldünger, den Sie anwenden möchten?",
      "Which strategy do you want to use?": "Welche Strategie möchten Sie verwenden?",
      "Provide higher amount of fertilizer where the crop is stronger": "Bieten Sie eine höhere Menge Dünger an, wo die Kultur stärker ist",
      "Provide lower amount of fertilizer where the crop is stronger": "Geben Sie weniger Dünger ab, wo die Pflanze stärker ist",
      "Percentage of difference of fertilizer between zones": "Prozentsatz des Unterschieds des Düngers zwischen den Zonen",
      "Selected Percentage:": "Ausgewählter Prozentsatz:",
      "Create": "Erstellen",
      "Send to Jhon Deere Operation Center": "An das Jhon Deere Operation Center senden",
      "Download": "Herunterladen",
      "Downloading...": "Herunterladen...",
      "Downloading": "Herunterladen",
      "Sending": "Senden",
      "Sending...": "Senden...",
      "Something went wrong. If the problem persists please send us an email at automaticfarmsolution@gmail.com": "Etwas ist schief gelaufen. Wenn das Problem weiterhin besteht, senden Sie uns bitte eine E-Mail an automaticfarmsolution@gmail.com",
      "Creating the field...": "Feld wird erstellt...",
      "Update Field": "Feld aktualisieren",
      "Updating Field": "Feld wird aktualisiert",
      "Integration done": "Integration abgeschlossen",
      "You have done the integration with Jhon Deere": "Sie haben die Integration mit Jhon Deere abgeschlossen",
      "Start the integration with the Operation Center Jhon Deere": "Starten Sie die Integration mit dem Jhon Deere Operation Center",
      "A problem occurred during the integration with Jhon Deere": "Ein Problem ist während der Integration mit Jhon Deere aufgetreten",
      "You don't have any farm registered, first you have to set at least one farmer": "Sie haben keine Farm registriert, zuerst müssen Sie mindestens einen Landwirt einrichten",
      "Start the integration with the Operation Center John Deere": "Starten Sie die Integration mit dem Operation Center John Deere",
      "Connect to Operation Center Jhon Deere": "Mit dem Jhon Deere Operation Center verbinden",
      "If you want to send the prescription map to the operation center, you can connect this platform directly to your Operation Center": "Wenn Sie die Verschreibungskarte an das Operation Center senden möchten, können Sie diese Plattform direkt mit Ihrem Operation Center verbinden",
      "Satellite Image analysis": "Satellitenbildanalyse",
      "Please enable the geolocation in order to get the weather data and visualization": "Bitte aktivieren Sie die Geolokalisierung, um Wetterdaten und Visualisierung zu erhalten",
      "Actual Weather Data": "Aktuelle Wetterdaten",
      "Weather Map": "Wetterkarte",
      "Select the climate variable to see": "Wählen Sie die Klimavariablen aus, um zu sehen",
      "Forecast Temperature": "Vorhersage Temperatur",
      "Wind": "Wind",
      "Pressure": "Druck",
      "Snow": "Schnee",
      "Precipitation": "Niederschlag",
      "Clouds": "Wolken",
      "Temperature": "Temperatur",
      "Add / Modify / Delete": "Hinzufügen / Ändern / Löschen",
      "Field Registered": "Feld registriert",
      "Field Name": "Feldname",
      "Select the field to view": "Wählen Sie das Feld aus, um es anzuzeigen",
      "Number of farmers": "Anzahl der Landwirte",
      "Number of fields": "Anzahl der Felder",
      "Hectares Monitored": "Überwachte Hektar",
      "Anomalies": "Anomalien",
      "IoT Number": "IoT-Nummer",
      "Last Reading": "Letzte Messung",
      "Soil Temperature": "Bodentemperatur",
      "Soil Humidity": "Bodenfeuchtigkeit",
      "Electrical Conductivity": "Elektrische Leitfähigkeit",
      "Organic Carbon": "Organischer Kohlenstoff",
      "Nitrogen": "Stickstoff",
      "Potassium": "Kalium",
      "Battery": "Batterie",
      "Select the variable": "Variable auswählen",
      "Select the IoT Cabin": "IoT-Kabine auswählen",
      "View data": "Daten anzeigen",
      "Time series Graph": "Zeitreihen-Diagramm",
      "Time Series Comparison": "Zeitreihenvergleich",
      "Table": "Tabelle",
      "Add Alert": "Alarm hinzufügen",
      "Alert Registered": "Alarm registriert",
      "Sensor": "Sensor",
      "Variable": "Variable",
      "Condition": "Bedingung",
      "Value": "Wert",
      "No Alert Registered": "Kein Alarm registriert",
      "No IoT is linked to your account": "Kein IoT ist mit Ihrem Konto verknüpft",
      "No IoT alert is set": "Kein IoT-Alarm ist festgelegt",
      "Please select the IoT Cabin": "Bitte wählen Sie die IoT-Kabine aus",
      "Please select the variable": "Bitte wählen Sie die Variable aus",
      "Please select the logic to apply": "Bitte wählen Sie die Logik aus, die angewendet werden soll",
      "Please set the threshold value": "Bitte setzen Sie den Schwellenwert",
      "Select the logic": "Wählen Sie die Logik aus",
      "Set the threshold value": "Setzen Sie den Schwellenwert",
      "Adding the alert...": "Hinzufügen der Warnung...",
      "Higher than": "Höher als",
      "Lower than": "Niedriger als",
      "IoT Position": "IoT-Position",
      "Create Alert": "Warnung erstellen",
      "Are you sure to remove this alert?": "Sind Sie sicher, diese Warnung zu entfernen?",
      "Cabin Number:": "Kabinennummer:",
      "Logic - Higher or Lower:": "Logik - Höher oder Niedriger:",
      "Threshold Value:": "Schwellenwert:",
      "Delete Alert": "Warnung löschen",
      "Update Alert": "Warnung aktualisieren",
      "Updating the alert...": "Aktualisierung der Warnung...",
      "Please the expected yield (q/ha)": "Bitte geben Sie den erwarteten Ertrag an (q/ha)",
      "Please the fertilizer title (%)": "Bitte geben Sie den Düngertitel an (%)",
      "Setting of the Calculation": "Einstellung der Berechnung",
      "Select the Nutrient": "Nährstoff auswählen",
      "Select the Crop": "Kultur auswählen",
      "Set the expected yield (q/ha)": "Setzen Sie den erwarteten Ertrag (q/ha)",
      "Title of fertilizer - range 0-100 %": "Titel des Düngers - Bereich 0-100 %",
      "Calculate": "Berechnen",
      "The amount of fertilizer that you have provided to the crop for the entire crop growth cycle is:": "Die Menge an Dünger, die Sie der Kultur während des gesamten Wachstumszyklus bereitgestellt haben, beträgt:",
      "Set the field to get the soil data": "Wählen Sie das Feld aus, um die Bodendaten zu erhalten",
      "Hello, I'm your Digital Agronomist Consultant! Ask me anything!": "Hallo, ich bin Ihr Digitaler Agronomieberater! Fragen Sie mich alles!",
      "The digital agronomist consultant is typing": "Der digitale Agronomieberater tippt",
      "Send a Message": "Nachricht senden",
      "Digital agronomist consultant": "Digitaler Agronomieberater",
      "You have done the integration with John Deere": "Sie haben die Integration mit John Deere abgeschlossen",
      "Please fill all the requests": "Bitte füllen Sie alle Anfragen aus",
      "Set the analysis parameter": "Setzen Sie den Analyseparameter",
      "Date of sowing": "Aussaatdatum",
      "Select the soil type": "Wählen Sie den Bodentyp aus",
      "Sand": "Sand",
      "Loamy Sand": "Lehmiger Sand",
      "Sandy Loam": "Sandiger Lehm",
      "Loam": "Lehm",
      "Silty Loam": "Schluffiger Lehm",
      "Silt": "Schluff",
      "Sandy Clay Loam": "Sandiger Tonlehm",
      "Clay Loam": "Lehmiger Ton",
      "Silty Clay Loam": "Schluffiger Tonlehm",
      "Sandy Clay": "Sandton",
      "Clay": "Ton",
      "Sunflower": "Sonnenblume",
      "Millet": "Hirse",
      "Rice": "Reis",
      "Sugarbeet": "Zuckerbeete",
      "Sorghum": "Sorghum",
      "Soybean": "Sojabohne",
      "Canola": "Raps",
      "Tomato": "Tomate",
      "Cabbage": "Kohl",
      "Potato": "Kartoffel",
      "Sugarcane": "Zuckerrohr",
      "Select the Variety": "Sorte auswählen",
      "Do you want to provide fertilization?": "Möchten Sie Düngung bereitstellen?",
      "Date of fertilization": "Datum der Düngung",
      "Define the amount of Nitrogen provided (kg N / ha)": "Definieren Sie die Menge an Stickstoff, die bereitgestellt wird (kg N / ha)",
      "Define the amount of Phosphorus provided (kg P / ha)": "Definieren Sie die Menge an Phosphor, die bereitgestellt wird (kg P / ha)",
      "Define the amount of Potassium provided (kg K / ha)": "Definieren Sie die Menge an Kalium, die bereitgestellt wird (kg K / ha)",
      "Do you want to provide irrigation": "Möchten Sie Bewässerung bereitstellen",
      "Date of irrigation": "Datum der Bewässerung",
      "Define the amount of water mm": "Definieren Sie die Menge Wasser mm",
      "Next": "Weiter",
      "Submit": "Einreichen",
      "Temporal creation of the yield": "Zeitliche Erstellung des Ertrags",
      "Before you can run the model you should define the crop type. Go to the Field and update section the information": "Bevor Sie das Modell ausführen können, müssen Sie den Pflanzentyp definieren. Gehen Sie zum Abschnitt Feld und aktualisieren Sie die Informationen.",
      "Set the Field where apply the analysis": "Legen Sie das Feld fest, auf dem die Analyse durchgeführt werden soll.",
      "Set the Farmer": "Legen Sie den Landwirt fest.",
      "Set the Field": "Legen Sie das Feld fest.",
      "Run The Analysis": "Führen Sie die Analyse durch.",
      "NDVI Comparation": "NDVI-Vergleich",
      "SAR Comparation": "SAR-Vergleich",
      "Probabilistic Map": "Wahrscheinlichkeitskarte",
      "% Convenctional": "% Konventionell",
      "% Organic": "% Bio",
      "The field was declared by the farmer as": "Das Feld wurde vom Landwirt als erklärt",
      "Select the start year to analyze": "Wählen Sie das Startjahr für die Analyse aus.",
      "Select the base temperature for the crop": "Wählen Sie die Basistemperatur für die Pflanze aus.",
      "Select the max temperature for the crop": "Wählen Sie die maximale Temperatur für die Pflanze aus.",
      "Start analysis": "Starte die Analyse",
      "Upgrade your account": "Aktualisieren Sie Ihr Konto",
      "If you want to access to the satellite data and generate the prescription map, you must upgrade your account to PRO": "Wenn Sie auf die Satellitendaten zugreifen und die Verschreibungskarte generieren möchten, müssen Sie Ihr Konto auf PRO aktualisieren.",
      "Not Now": "Nicht jetzt",
      "Upgrade your account to PRO": "Aktualisieren Sie Ihr Konto auf PRO",
      "Your Account is PRO": "Ihr Konto ist PRO",
      "You can access to all the service": "Sie haben Zugriff auf alle Dienste",
      "Your Account is not PRO": "Ihr Konto ist nicht PRO",
      "Purchase the PRO Service": "Kaufen Sie den PRO-Service",
      "The total price is": "Der Gesamtpreis beträgt",
      "The payment was successful": "Die Zahlung war erfolgreich",
      "Now you should logout and login again to access all the features just unlocked": "Sie sollten sich jetzt abmelden und erneut anmelden, um auf alle freigeschalteten Funktionen zugreifen zu können.",
      "By purchasing this service you will access:": "Durch den Kauf dieses Dienstes haben Sie Zugriff auf:",
      "Prescription maps that can be integrated with your display tractors": "Verschreibungskarten, die mit Ihren Anzeigetraktoren integriert werden können",
      "Visualization & Analysis of sensor data": "Visualisierung und Analyse von Sensordaten",
      "Set alert": "Alarm einstellen",
      "All the agronomic tools": "Alle agronomischen Werkzeuge",
      "AI Tools": "KI-Werkzeuge",
      "Synchronization with Operation Center John Deere": "Synchronisation mit dem Betriebszentrum John Deere",
      "Vineyards": "Weinberge",
      "perennial": "Mehrjährig",
      "biennial": "Zweijährig",
      "triennial": "Dreijährig",
      "Kiwi": "Kiwi",
      "Garlic": "Knoblauch",
      "Apricot": "Aprikose",
      "Orange": "Orange",
      "Asparagus": "Spargel",
      "Oats": "Hafer",
      "Basil": "Basilikum",
      "Swiss_chard": "Mangold",
      "Beet_leaves": "Rübenblätter",
      "Broccoli": "Brokkoli",
      "Hemp": "Hanf",
      "Artichoke": "Artischocke",
      "Carrot": "Karotte",
      "Cauliflower": "Blumenkohl",
      "chickpea": "Kichererbse",
      "cucumber": "Gurke",
      "Chicory": "Chicorée",
      "Onion": "Zwiebel",
      "Endive": "Endivie",
      "Watermelon": "Wassermelone",
      "Rapeseed": "Raps",
      "Green_bean_for_industry": "Grüne Bohne für die Industrie",
      "Fresh_green_bean": "Frische grüne Bohne",
      "Bean": "Bohne",
      "Dry_bean": "Trockenbohne",
      "Spelt": "Dinkel",
      "Grain_fava_bean": "Grain Saubohne",
      "Favino": "Favino",
      "Fico": "Feige",
      "Fennel": "Fenchel",
      "Strawberry": "Erdbeere",
      "winter_wheat": "Winterweizen",
      "Kaki": "Persimmon",
      "Lettuce": "Kopfsalat",
      "Lentil": "Linsen",
      "Lemon": "Zitrone",
      "Linen": "Leinen",
      "Lupin": "Lupine",
      "Corn": "Mais",
      "Tangerine": "Mandarine",
      "Almond": "Mandel",
      "Eggplant": "Aubergine",
      "Melon": "Melone",
      "Blueberry": "Heidelbeere",
      "Nectarines": "Nektarinen",
      "Kernel": "Kern",
      "Walnut": "Walnuss",
      "Bell_pepper": "Paprika",
      "Pear": "Birne",
      "Peach": "Pfirsich",
      "Pea": "Erbse",
      "Leek": "Lauch",
      "Parsley": "Petersilie",
      "Radicchio": "Radicchio",
      "Turnip": "Rübe",
      "Radish": "Radieschen",
      "Ribes": "Johannisbeere",
      "Shallots": "Schalotten",
      "Celery": "Sellerie",
      "Soy": "Soja",
      "Spinach": "Spinat",
      "Plum_tree": "Pflaumenbaum",
      "Tabacco": "Tabak",
      "Vineyard": "Weinberg",
      "Savoy_cabbage": "Wirsing",
      "Pumpkin": "Kürbis",
      "Zucchini_for_industry": "Zucchini für die Industrie",
      "Fresh_zucchini": "Frische Zucchini",
      "Peanuts": "Erdnüsse",
      "Chard": "Mangold",
      "Leaf_beet": "Rübenblätter",
      "Hemp_for_seeds": "Hanf (Samen)",
      "Hemp_for_fiber": "Hanf (Fasern)",
      "Cardoon": "Artischocke",
      "Chestnut": "Kastanie",
      "Cherry_tree": "Kirschbaum",
      "French_bean": "Grüne Bohne",
      "Fava_bean": "Dicke Bohne",
      "Wheat": "Weizen",
      "Raspberry": "Himbeere",
      "Flax": "Flachs",
      "Ley_grass": "Lupine",
      "Corn_for_grain": "Aubergine",
      "Hazelnut": "Haselnuss",
      "Pear_tree": "Birnenbaum",
      "Peach_tree": "Pfirsichbaum",
      "Protein_pea": "Protein-Erbsen",
      "Processing_tomato": "Verarbeitete Tomaten",
      "Table_tomato": "Tafel-Tomate",
      "Table_grape": "Tafeltraube",
      "Gooseberry": "Stachelbeere",
      "Grapevine": "Weinstock",
      "Clover": "Klee",
      "Corn_salad": "Feldsalat",
      "Grapevine_for_wine_grapes": "Weinstock für Weintrauben",
      "Sweet_corn": "Zuckermais",
      "Silage_corn": "Maissilage",
      "Oilseed_rape": "Raps",
      "Field-grown_bell_pepper": "Feldpaprika",
      "Pea_for_industry": "Industrie-Erbsen",
      "Fresh_pea": "Frische Erbse",
      "Greenhouse_table_tomato": "Gewächshaus-Tafel-Tomate",
      "Clover_grassland": "Klee-Weideland",
      "Hill_pastures": "Hügelweiden",
      "Mixed_grassland_>50_legumes": "Gemischtes Weideland >50% Leguminosen",
      "Artificial_mixed_hill_pastures": "Künstliche gemischte Hügelweiden",
      "Stable_plains": "Stabile Ebenen",
      "Silage_fodder": "Silagefutter",
      "Arugula_1st_cut": "Rucola, 1. Schnitt",
      "Arugula_2nd_cut": "Rucola, 2. Schnitt",
      "Shallot": "Schalotte",
      "Arugula_in_open_field": "Rucola im Freiland",
      "Organic vs Conventional:": "Bio vs Konventionell:",
      "Are you sure to edit the polygon? if yes press again the Update Field button": "Sind Sie sicher, das Polygon bearbeiten zu wollen? Wenn ja, drücken Sie erneut auf die Schaltfläche 'Feld aktualisieren'",
      "Set the Year of analysis": "Jahr der Analyse festlegen",
      "Set the nutrient to visualize": "Nährstoff zur Visualisierung festlegen",
      "Carbonates": "Carbonate",
      "Soil Electrical Conductivity": "Bodenleitfähigkeit",
      "pH": "pH-Wert",
      "Please set the year": "Bitte legen Sie das Jahr fest",
      "Please set the nutrient": "Bitte legen Sie den Nährstoff fest",
      "Please set the farmer": "Bitte legen Sie den Landwirt fest",
      "Please set the field": "Bitte legen Sie das Feld fest",
      "The map is not ready yet": "Die Karte ist noch nicht bereit",
      "Please select the object of your request": "Bitte wählen Sie das Objekt Ihrer Anfrage aus",
      "Please report a valid message": "Bitte geben Sie eine gültige Nachricht ein",
      "Send us your message": "Senden Sie uns Ihre Nachricht",
      "Object of the request:": "Objekt der Anfrage:",
      "Object:": "Objekt:",
      "Support Request": "Supportanfrage",
      "Upgrade account": "Konto aktualisieren",
      "Problem with the application": "Problem mit der Anwendung",
      "Other": "Andere",
      "Message of the request:": "Nachricht der Anfrage:",
      "Message:": "Nachricht:",
      "Send message": "Nachricht senden",
      "My account": "Mein Konto",
      "Do you want to upgrade your account?": "Möchten Sie Ihr Konto aktualisieren?",
      "Send us a message": "Senden Sie uns eine Nachricht",
      "Your Message have been sent with success": "Ihre Nachricht wurde erfolgreich gesendet",
      "As soon as possible one of our operators will check your request and answer you": "So schnell wie möglich wird einer unserer Betreiber Ihre Anfrage überprüfen und Ihnen antworten",
      "Frumento_tenero": "Winterweizen",
      "Winter Wheat": "Winterweizen",
      "Annual percentage increase": "Jährliche prozentuale Zunahme",
      "Tons of CO2 Sequestered": "Tonnen CO2 eingelagert",
      "Carbon Credits Generated": "Generierte Kohlenstoffgutschriften",
      "Soil Map": "Bodenkarte",
      "Temporal Variation": "Zeitliche Variation",
      "If you want to access to this module data, you must upgrade your account to PRO": "Wenn Sie auf die Daten dieses Moduls zugreifen möchten, müssen Sie Ihr Konto auf PRO aktualisieren.",
      "Run Model": "Modell ausführen",
      "Prescription AI Map": "Rezept-KI-Karte",
      "Please set the expeted yield": "Bitte setzen Sie die erwartete Ausbeute",
      "Please set the number of the application of fertilizer during the year": "Bitte setzen Sie die Anzahl der Düngemittelanwendungen während des Jahres",
      "AI Section": "KI-Abschnitt",
      "Which crop?": "Welche Ernte?",
      "Which nutrient?": "Welcher Nährstoff?",
      "Expected yield quintals per hectares?": "Erwartete Ausbeute Quintale pro Hektar?",
      "Percentage of title of the fertilizer": "Prozentsatz des Titels des Düngemittels",
      "Number of the application during the year": "Anzahl der Anwendungen während des Jahres",
      "Search City:": "Stadt suchen:",
      "Export Document": "Dokument exportieren",
      "Download Section": "Downloadbereich",
      "Download Field - Format ShapeFile": "Feld herunterladen - ShapeFile-Format",
      "Download Field - Format Excel": "Feld herunterladen - Excel-Format",
      "Download Farmer Data - Format Excel": "Bauerndaten herunterladen - Excel-Format",
      "Welcome to AIBIOSAT": "Willkommen bei AIBIOSAT",
      "The 1st AI-based application that support the organic certification": "Die erste KI-basierte Anwendung, die die Bio-Zertifizierung unterstützt",
      "Before to start to use the application you should follow this step by step welcome on boarding procedure": "Bevor Sie mit der Verwendung der Anwendung beginnen, sollten Sie dieses schrittweise Willkommens-Onboarding-Verfahren befolgen",
      "1) Setting up a Farm": "1) Einrichten einer Farm",
      "2) Create a Field to be associated with the Farm": "2) Erstellen eines Feldes, das mit der Farm verbunden werden soll",
      "3) You are done": "3) Sie sind fertig",
      "Let's start": "Lass uns anfangen",
      "Skip the procedure": "Das Verfahren überspringen",
      "Start Using the application": "Beginnen Sie mit der Verwendung der Anwendung",
      "Hectares Deleted": "Gelöschte Hektar",
      "Create a farm": "Eine Farm erstellen",
      "Create a field": "Ein Feld erstellen",
      "Delete Account": "Konto löschen",
      "Are you sure to delete your account?": "Sind Sie sicher, dass Sie Ihr Konto löschen möchten?",
      "Are you sure to delete the account?": "Sind Sie sicher, dass Sie das Konto löschen möchten?",
      "Please be carefull cause if you delete your account all the data related will be removed": "Bitte seien Sie vorsichtig, denn wenn Sie Ihr Konto löschen, werden alle zugehörigen Daten entfernt",
      "If you want to proceed, please report your email ": "Wenn Sie fortfahren möchten, geben Sie bitte Ihre E-Mail-Adresse an ",
      " in the following form input": " im folgenden Formularfeld",
      "Deleting the user...": "Benutzer wird gelöscht...",
      "Delete account": "Benutzer löschen",
      "In order to register you should accept the Terms And Conditions": "Um sich zu registrieren, müssen Sie die Allgemeinen Geschäftsbedingungen akzeptieren.",
      "Account Level:": "Kontoniveau:",
      "Farmer company name:": "Name des Landwirtschaftsbetriebs:",
      "Your account is basic": "Ihr Konto ist einfach",
      "Close": "Schließen",
      "Select the Farm": "Wähle den Bauernhof aus",
      "No Sensors Are Linked to Your Account": "Keine Sensoren sind mit Ihrem Konto verknüpft",
      "Table of the Soil Data": "Tabelle der Bodendaten",
      "Depth start (cm)": "Tiefe Beginn (cm)",
      "Depth end (cm)": "Tiefe Ende (cm)",
      "Mean Bulk density (cg/cm3)": "Mittlere Schüttdichte (cg/cm3)",
      "Uncertainty Bulk density (cg/cm3)": "Unsicherheit der Schüttdichte (cg/cm3)",
      "Mean Cation Exchange Capacity (mmol/kg)": "Mittlere Kationenaustauschkapazität (mmol/kg)",
      "Uncertainty Cation Exchange Capacity (mmol/kg)": "Unsicherheit der Kationenaustauschkapazität (mmol/kg)",
      "Mean Clay (g/kg)": "Mittlere Ton (g/kg)",
      "Uncertainty Clay (g/kg)": "Unsicherheit der Ton (g/kg)",
      "Mean Nitrogen (cg/kg)": "Mittlerer Stickstoff (cg/kg)",
      "Uncertainty Nitrogen (cg/kg)": "Unsicherheit des Stickstoffs (cg/kg)",
      "Mean pH": "Mittlerer pH-Wert",
      "Uncertainty pH": "Unsicherheit des pH-Werts",
      "Mean Sand (g/kg)": "Mittlerer Sand (g/kg)",
      "Uncertainty Sand (g/kg)": "Unsicherheit des Sandes (g/kg)",
      "Mean Silt (g/kg)": "Mittlerer Schluff (g/kg)",
      "Uncertainty Silt (g/kg)": "Unsicherheit des Schluffes (g/kg)",
      "Mean SOC (g/kg)": "Mittlerer organische Kohlenstoff (g/kg)",
      "Uncertainty SOC (g/kg)": "Unsicherheit des organischen Kohlenstoffs (g/kg)",
      "Operators": "Operatoren",
      "Warehouse": "Lagerhaus",
      "Field Notes": "Feldnotizen",
      "Yield": "Ertrag",
      "Seed": "Saatgut",
      "Fertilizers": "Düngemittel",
      "Pesticide": "Pestizid",
      "Field Activities": "Feldaktivitäten",
      "Operator Cost": "Betriebskosten",
      "Revenue": "Einnahmen",
      "Economy": "Wirtschaft",
      "Operators Registred": "Registrierte Betreiber",
      "Create Operator": "Operator erstellen",
      "Create operator": "Operator erstellen",
      "Last Name": "Nachname",
      "Operator Name:": "Operatorname:",
      "Operator Last Name:": "Operator Nachname:",
      "Operator Email:": "Operator E-Mail:",
      "Operator Phone:": "Operator Telefon:",
      "Are you sure to delete this operator?": "Sind Sie sicher, diesen Operator zu löschen?",
      "Delete Operator": "Operator löschen",
      "Deleting the Operator...": "Operator wird gelöscht...",
      "Creating the operator...": "Operator wird erstellt...",
      "Update Operator": "Operator aktualisieren",
      "Updating Operator...": "Operator wird aktualisiert...",
      "The name is empty": "Der Name ist leer",
      "The last name is empty": "Der Nachname ist leer",
      "The phone is empty": "Das Telefon ist leer",
      "You must select a farm to associate to the operators": "Sie müssen einen Bauernhof auswählen, um ihn den Betreibern zuzuweisen",
      "The phone number isn't correct": "Die Telefonnummer ist nicht korrekt",
      "Create Warehouse": "Lager erstellen",
      "Warehouse Name": "Lagername",
      "Warehouses Registred": "Registrierte Lager",
      "Warehouse Map": "Lagerkarte",
      "Warehouse Name:": "Lagername:",
      "Select warehouse location:": "Wählen Sie den Lagerstandort:",
      "Creating the Warehouse...": "Lager wird erstellt...",
      "Please report the position of the warehouse": "Bitte geben Sie die Position des Lagers an",
      "Please insert the name of the warehouse": "Bitte geben Sie den Namen des Lagers ein",
      "Please insert the farm to relate to the warehouse": "Bitte geben Sie den Bauernhof an, der mit dem Lager verbunden werden soll",
      "Edit Warehouse": "Lager bearbeiten",
      "The red point is the old location of the warehouse": "Der rote Punkt ist der alte Standort des Lagers",
      "Editing the Warehouse...": "Lager wird bearbeitet...",
      "Are you sure to delete the warehouse?": "Sind Sie sicher, dass Sie das Lager löschen möchten?",
      "Latitude:": "Breitengrad:",
      "Longitude:": "Längengrad:",
      "Warehouse Farm:": "Mit dem Lager verbundener Bauernhof:",
      "Delete Warehouse": "Lager löschen",
      "Deleting the Warehouse...": "Lager wird gelöscht...",
      "Actual Wheater Data": "Aktuelle Wetterdaten",
      "Wheater Map": "Wetterkarte",
      "Create Field Note": "Feldnotiz erstellen",
      "Field Note Registred": "Feldnotizen registriert",
      "Date": "Datum",
      "Title of the note": "Titel der Feldnotiz",
      "Field Note Title:": "Titel der Feldnotiz:",
      "No Field Note Are Registred": "Keine Feldnotizen registriert",
      "No Field exist for your account": "Kein Feld existiert für Ihr Konto",
      "If you want to register an Field Note you should create first an Operator": "Wenn Sie eine Feldnotiz registrieren möchten, sollten Sie zuerst einen Betreiber erstellen",
      "Select field note location:": "Wählen Sie den Standort der Feldnotiz:",
      "Warehouse location": "Lagerstandort",
      "Type of Note": "Notiztyp",
      "Generic": "Allgemein",
      "Pathogens": "Pathogene",
      "Insects": "Insekten",
      "Weed": "Unkraut",
      "Waterlogging": "Staunässe",
      "Generic Damage": "Allgemeiner Schaden",
      "Field Survey": "Felderhebung",
      "Crop Status": "Kulturstatus",
      "Description": "Beschreibung",
      "Operator": "Betreiber",
      "Creating the Field Note...": "Feldnotiz wird erstellt...",
      "Please insert the title of the field note": "Bitte geben Sie den Titel der Feldnotiz ein",
      "Please set the farm related to the field note": "Bitte geben Sie den Hof an, der mit der Feldnotiz verbunden ist",
      "Please set the field related to the field note": "Bitte geben Sie das Feld an, das mit der Feldnotiz verbunden ist",
      "Please insert the type of the field note": "Bitte geben Sie den Typ der Feldnotiz ein",
      "Please insert the date of the field note": "Bitte geben Sie das Datum der Feldnotiz ein",
      "Please insert the operator that create the field note": "Bitte geben Sie den Betreiber ein, der die Feldnotiz erstellt",
      "type": "Typ",
      "Title": "Titel",
      "Field Note Map": "Karte der Feldnotizen",
      "Edit Field Note": "Feldnotiz bearbeiten",
      "In red there is the old Field Note": "In Rot ist die alte Position der Feldnotiz",
      "Old Field Note": "Alte Feldnotiz",
      "Editing the Field Note...": "Feldnotiz wird bearbeitet...",
      "Please report the position of the field note": "Bitte geben Sie die Position der Feldnotiz an",
      "Field Note Date:": "Datum der Feldnotiz:",
      "Field Note Farm:": "Hof der Feldnotiz:",
      "Field Note Field:": "Feld der Feldnotiz:",
      "Field Note Operator:": "Betreiber der Feldnotiz:",
      "Field Note Type:": "Typ der Feldnotiz:",
      "Field Note Description:": "Beschreibung der Feldnotiz:",
      "Delete Field Note": "Feldnotiz löschen",
      "Deleting the Field Note...": "Feldnotiz wird gelöscht...",
      "Are you sure to delete this field note?": "Sind Sie sicher, dass Sie diese Feldnotiz löschen wollen?",
      "Field Note Data": "Feldnotiz Daten",
      "Create Yield data": "Ertragsdaten erstellen",
      "Yield Data Registred": "Ertragsdaten registriert",
      "Harvest Date": "Erntedatum",
      "Crop": "Kultur",
      "Total Quantity": "Gesamtmenge",
      "Price": "Preis",
      "Total Cost": "Gesamtkosten",
      "No Yield data are registred": "Keine Ertragsdaten registriert",
      "No Operator exist for your account": "Kein Betreiber für Ihr Konto registriert",
      "If you want to register an Yield you should create first a Operator": "Wenn Sie einen Ertrag registrieren möchten, müssen Sie zuerst einen Betreiber erstellen",
      "If you want to register an Yield you should create first a Field": "Wenn Sie einen Ertrag registrieren möchten, müssen Sie zuerst ein Feld erstellen",
      "Please select the farm": "Bitte wählen Sie den Hof aus",
      "Please select the field": "Bitte wählen Sie ein Feld",
      "Please select the operator": "Bitte wählen Sie den Operator aus",
      "Please set the harvest date": "Bitte wählen Sie ein Erntedatum",
      "Please set the total yield": "Bitte geben Sie den Gesamtertrag ein",
      "Please set the price of the yield": "Bitte geben Sie den Verkaufspreis des Ertrags ein",
      "Please set the cost of the operation": "Bitte geben Sie die Kosten der Operation ein",
      "Register a Yield": "Ertragsdaten registrieren",
      "The crop is: ": "Die Kultur ist: ",
      "Total Yield quintals": "Gesamtertrag in Doppelzentnern",
      "Price selled euro per quintal": "Verkaufspreis pro Doppelzentner",
      "Did you use a subcontractors?": "Haben Sie Subunternehmer eingesetzt?",
      "Subcontractor Name": "Name des Subunternehmers",
      "Total cost of the harvest operation": "Gesamtkosten der Ernteoperation",
      "Create Yield": "Ertrag erstellen",
      "Creating the Yield...": "Ertragsdaten werden erstellt...",
      "Update Yield": "Ertrag aktualisieren",
      "Updating the Yield...": "Ertragsdaten werden aktualisiert...",
      "Are you sure to delete this yield data?": "Sind Sie sicher, dass Sie die Ertragsdaten löschen möchten?",
      "Delete Yield": "Ertragsdaten löschen",
      "Deleting the Yield...": "Ertragsdaten werden gelöscht...",
      "Total cost to harvest": "Gesamtkosten der Ernteoperation",
      "Create Seed": "Saatgut erstellen",
      "Seed Registred": "Saatgut Registriert",
      "Macro Type": "Makrotyp",
      "Type": "Typ",
      "Commercial Product Name": "Kommerzieller Produktname",
      "Organic Approved": "Für den Biolandbau zugelassen?",
      "Duplicate": "Duplizieren",
      "Please select the unit doses": "Bitte wählen Sie die Dosis",
      "Please set the name of the seed": "Bitte geben Sie den Namen des kommerziellen Produkts ein",
      "Please select the type of the seed": "Bitte wählen Sie den Saatguttyp/Pflanzentyp",
      "Please set the productor name": "Bitte wählen Sie den Namen des Herstellers aus",
      "Please set the crop name": "Bitte geben Sie den Namen der Kulturpflanze ein",
      "Commercial Product Name:": "Kommerzieller Produktname:",
      "Product Type:": "Produkttyp:",
      "Unit Dose:": "Dosis:",
      "Can be used in organic farming production?": "Kann im Biolandbau verwendet werden?",
      "Day Growth Cycle": "Wachstumszyklus in Tagen",
      "FAO Class": "FAO-Klasse",
      "Productor": "Produzent",
      "Creating the Seed...": "Saatgut wird erstellt...",
      "Are you sure to delete this seed?": "Sind Sie sicher, dass Sie dieses Saatgut löschen möchten?",
      "Macro Type:": "Makrotyp:",
      "Is organic product approved": "Ist das Produkt für den Biolandbau zugelassen",
      "Unit dose": "Dosis",
      "Delete seed": "Saatgut löschen",
      "Deleting the seed...": "Saatgut wird gelöscht...",
      "Duplicate Seed": "Saatgut duplizieren",
      "Edit Seed": "Saatgut bearbeiten",
      "Editing the Seed...": "Saatgut wird aktualisiert...",
      "Create Fertilizer": "Dünger erstellen",
      "Fertilizer Registred": "Dünger registriert",
      "Please set the name of the fertilizer": "Bitte geben Sie den Handelsnamen des Düngers ein",
      "Please set the type of fertilizer": "Bitte wählen Sie den Düngertyp aus",
      "Please set the nitrogen": "Bitte geben Sie den Stickstoffgehalt des Düngers ein",
      "Please set the potassium": "Bitte geben Sie den Kaliumgehalt des Düngers ein",
      "Please set the phosphorus": "Bitte geben Sie den Phosphorgehalt des Düngers ein",
      "Nitrogen Percentange": "Stickstoffgehalt in %",
      "Phosphorus Percentange": "Phosphorgehalt in %",
      "Potassium Percentange": "Kaliumgehalt in %",
      "Are you sure to delete this fertilizer?": "Sind Sie sicher, dass Sie diesen Dünger löschen möchten?",
      "Delete Fertilizer": "Dünger löschen",
      "Deleting the Fertilizer...": "Dünger wird gelöscht...",
      "Creating the Fertilizer...": "Dünger wird erstellt...",
      "Duplicate Fertilizer": "Dünger duplizieren",
      "Edit Fertilizer": "Dünger bearbeiten",
      "Editing the Fertilizer...": "Dünger wird bearbeitet...",
      "Create Product": "Produkt erstellen",
      "Product Registred": "Produkt registriert",
      "Register Number": "Registrierungsnummer",
      "Registration Date": "Registrierungsdatum",
      "Product Formulation:": "Produktformulierung:",
      "Active substances:": "Wirkstoffe:",
      "Content per 100 grams": "Gehalt pro 100 Gramm",
      "Danger Indications": "Gefahrenhinweise",
      "Phenological Stage": "Phänologisches Stadium",
      "Scientific name adversity": "Wissenschaftlicher Name der Krankheit",
      "Common name adversity": "Allgemeiner Name der Krankheit",
      "Maximum dose": "Maximale Dosis",
      "Minimum dose": "Minimale Dosis",
      "Shortage time": "Wartezeit - Tage bis zur Ernte/Verkauf",
      "Return time": "Rückkehrzeit - Minimale Tage bis zur Rückkehr der Arbeiter auf das Feld",
      "Minimum interval between treatments": "Mindestabstand zwischen Behandlungen",
      "Maximum interval between treatments": "Maximalabstand zwischen Behandlungen",
      "Maximum number of treatments": "Maximale Anzahl von Behandlungen",
      "Unit for maximum number of treatment": "Einheit für die maximale Anzahl von Behandlungen",
      "Max Volume of water": "Maximales Wasservolumen",
      "Min Volume of water": "Minimales Wasservolumen",
      "Creating the Product...": "Produkt wird erstellt...",
      "Are you sure to delete this product?": "Sind Sie sicher, dass Sie dieses Produkt löschen möchten?",
      "Organic Product": "Bioprodukt",
      "Delete Product": "Produkt löschen",
      "Deleting the Product...": "Produkt wird gelöscht...",
      "Duplicate Product": "Produkt duplizieren",
      "Edit Product": "Produkt bearbeiten",
      "Editing the Product...": "Produkt wird bearbeitet...",
      "Cost": "Kosten",
      "Create Field Activities": "Feldaktivitäten erstellen",
      "Type of Field Activity": "Art der Feldaktivität",
      "Tillage": "Bodenbearbeitung",
      "Sowing / Planting": "Saat / Pflanzung",
      "Defence": "Verteidigung",
      "Tillage Type": "Art der Bodenbearbeitung",
      "Plowing": "Pflügen",
      "Harrowing": "Eggen",
      "Hoeing": "Hacken",
      "Weeding": "Unkraut jäten",
      "Clod breaking": "Klutenbrechen",
      "Milling": "Fräsen",
      "Ripping": "Aufreißen",
      "Rolling": "Walzen",
      "Compaction": "Verdichtung",
      "Mulching": "Mulchen",
      "Shredding": "Zerkleinern",
      "Tillage Depht cm": "Bodenbearbeitungstiefe cm",
      "You didn't create a seed object": "Sie haben kein Saatgutobjekt erstellt",
      "Sowing Dose kg / ha": "Saatdosis kg / ha",
      "Irrigation Volume m3/ha": "Bewässerungsvolumen m3/ha",
      "You didn't create a Fertilizer object": "Sie haben kein Düngerobjekt erstellt",
      "Create a fertilizer": "Dünger erstellen",
      "Fertilization Product": "Düngemittelprodukt",
      "Fertilization Dose kg/ha": "Düngemitteldosis kg/ha",
      "You didn't create a Defence object": "Sie haben kein Verteidigungsobjekt erstellt",
      "Create a Defence Product": "Verteidigungsprodukt erstellen",
      "Defence Product": "Verteidigungsprodukt",
      "Defence Type": "Verteidigungsart",
      "Dose Used": "Verwendete Dosis",
      "Volume Water Used": "Verwendetes Wasservolumen",
      "Level of infestaction": "Befallsgrad",
      "Low": "Niedrig",
      "Medium": "Mittel",
      "High": "Hoch",
      "Authorization Technichian?": "Autorisierung des Technikers?",
      "Respect strip": "Streifen respektieren",
      "Exceeding the intervention threshold": "Überschreitung des Eingriffsschwellenwerts",
      "It is done?": "Ist es erledigt?",
      "Crop Phenology": "Pflanzenphänologie",
      "Hour of work": "Arbeitsstunde",
      "Variety": "Sorte",
      "Subcontractors?": "Subunternehmer?",
      "Wheater Class": "Wetterklasse",
      "Temperature °C": "Temperatur °C",
      "Wind Speed km / h": "Windgeschwindigkeit km / h",
      "Humidity %": "Luftfeuchtigkeit %",
      "Rainfall mm": "Niederschlag mm",
      "Create Field Activity": "Feldaktivität erstellen",
      "Creating the Field Activity...": "Erstellen der Feldaktivität...",
      "Are you sure to delete this field activity?": "Sind Sie sicher, dass Sie diese Feldaktivität löschen möchten?",
      "Delete Field Activity": "Feldaktivität löschen",
      "Deleting the Field Activity...": "Löschen der Feldaktivität...",
      "Edit Field Activities": "Feldaktivitäten bearbeiten",
      "Edit Field Activity": "Feldaktivität bearbeiten",
      "Editing the Field Activity...": "Bearbeiten der Feldaktivität...",
      "Seed / Planting": "Saat / Pflanzung",
      "Field Activity": "Feldaktivität",
      "Date of Hire": "Einstellungsdatum",
      "Operator Cost Registered": "Registrierte Betriebskosten",
      "Create Operator Cost": "Betriebskosten erstellen",
      "No Operator are available for your account.": "Kein Operator ist für Ihr Konto verfügbar.",
      "First you have to create an operator": "Sie müssen zuerst einen Operator erstellen",
      "No Farm are available for your account.": "Kein Hof ist für Ihr Konto verfügbar.",
      "First you have to create an Farm": "Sie müssen zuerst einen Hof erstellen",
      "Please set the year operotor cost": "Bitte legen Sie die jährlichen Betriebskosten fest",
      "Create a Operator Cost": "Betriebskosten erstellen",
      "Operator year salary": "Jahresgehalt des Operators",
      "Creating the Operator Cost...": "Erstellen der Betriebskosten...",
      "Are you sure to delete this operator cost": "Sind Sie sicher, dass Sie diese Betriebskosten des Operators löschen möchten?",
      "Operator Cost Detail": "Betriebskostendetails",
      "Delete Operator Cost": "Betriebskosten löschen",
      "Deleting the Operator Cost...": "Löschen der Betriebskosten...",
      "Duplicate a Operator Cost": "Betriebskosten duplizieren",
      "Duplicate Operator Cost": "Betriebskosten duplizieren",
      "Duplicating the Operator Cost...": "Duplizieren der Betriebskosten...",
      "Edit a Operator Cost": "Betriebskosten bearbeiten",
      "Edit Operator Cost": "Betriebskosten bearbeiten",
      "Editing the Operator Cost...": "Bearbeiten der Betriebskosten...",
      "Create Revenue": "Einnahmen erstellen",
      "Revenue Registered": "Einnahmen registriert",
      "Total Revenue": "Gesamteinnahmen",
      "Please set the total revenue": "Bitte geben Sie den Wert der Gesamteinnahmen ein",
      "Please set the type of the revenue": "Bitte geben Sie die Art der Einnahmen ein",
      "Register a Revenue": "Einnahme registrieren",
      "Select the type of revenue:": "Wählen Sie die Art der Einnahme:",
      "Direct Payments": "Direktzahlungen",
      "pac": "GAP",
      "indinizzi": "Anzeichen",
      "Agricultural compensation": "Landwirtschaftliche Entschädigungen",
      "Creating the Revenue...": "Einnahme wird erstellt...",
      "Are you sure to delete this revenue?": "Sind Sie sicher, dass Sie diese Einnahme löschen möchten?",
      "Delete Revenue": "Einnahme löschen",
      "Deleting the Revenue...": "Einnahme wird gelöscht...",
      "Duplicate a Revenue": "Einnahme duplizieren",
      "Duplicate Revenue": "Einnahme duplizieren",
      "Duplicating the Revenue...": "Einnahme wird dupliziert...",
      "Edit a Revenue": "Einnahme bearbeiten",
      "Edit Revenue": "Einnahme bearbeiten",
      "Editing the Revenue...": "Einnahme wird bearbeitet...",
      "Farmer Economy Analysis": "Analyse der landwirtschaftlichen Wirtschaft",
      "Farm Profit": "Bauernhofgewinn",
      "Farm Revenue": "Hofeinnahmen",
      "Farm Total Cost": "Gesamtkosten des Bauernhofs",
      "Economy Data": "Wirtschaftsdaten",
      "Activity": "Aktivität",
      "Cost/Price": "Kosten/Preis",
      "Quantity/Hours": "Menge/Stunden",
      "Cost allocation": "Kostenzuweisung",
      "Allocation of Revenues": "Einnahmenzuweisung",
      "Revenues": "Einnahmen",
      "Monthly Costs and Revenues": "Monatliche Kosten und Einnahmen",
      "Cash Flow": "Kapitalfluss",
      "Air Temperature": "Lufttemperatur",
      "Atmospheric Pressure": "Luftdruck",
      "Rainfall": "Niederschlag",
      "Air Humidity": "Luftfeuchtigkeit",
      "Evapotraspiration": "Evapotranspiration",
      "Degree Day": "Gradtag",
      "TWH Index": "TWH-Index",
      "Dew Point": "Taupunkt",
      "Gust": "Böe",
      "Real time data or Historic data?": "Echtzeitdaten oder historische Daten?",
      "Last 24 Hours Data": "Daten der letzten 24 Stunden",
      "Degree Days": "Gradtage",
      "Leaf Wetness": "Blattnässe",
      "Irrigation Analysis": "Bewässerungsanalyse",
      "Download Operator - Format Excel": "Operator herunterladen - Format Excel",
      "Download Warehouse - Format Excel": "Lager herunterladen - Format Excel",
      "Download Field Note - Format Excel": "Feldnotiz herunterladen - Format Excel",
      "Download Yield - Format Excel": "Ertrag herunterladen - Format Excel",
      "Download Field Activity - Format Excel": "Feldaktivität herunterladen - Format Excel",
      "Download Other Revenue - Format Excel": "Andere Einnahmen herunterladen - Format Excel",
      "Download Operator Cost - Format Excel": "Betriebskosten herunterladen - Format Excel",
      "generic": "Generisch",
      "phatogens": "Pathogene",
      "insect": "Insekten",
      "weed": "Unkraut",
      "waterlogging": "Staunässe",
      "genericdamage": "Allgemeiner Schaden",
      "fieldsurvey": "Felduntersuchung",
      "cropstatus": "Kulturstatus",
      "acaricida": "Akarizid",
      "aficida": "Aphizid",
      "antideriva": "Anti-Drift",
      "antidoto": "Gegenmittel",
      "antigermogliante": "Keimhemmend",
      "antiriscaldo": "Anti-Erwärmung",
      "attrattivo": "Attraktiv",
      "bagnante": "Netzmittel",
      "coaudiuvante": "Hilfsmittel",
      "diradante": "Ausdünnungsmittel",
      "feromone": "Pheromon",
      "fitoregolatore": "Pflanzenwachstumsregulator",
      "geodisinfestante": "Bodenentseuchungsmittel",
      "repellente": "Repellent",
      "sinergizzante": "Synergist",
      "adiuvante": "Adjuvans",
      "fungicida": "Fungizid",
      "diserbante": "Herbizid",
      "insetticida": "Insektizid",
      "molluschicida": "Molluskizid",
      "organic_product": "Organisches Produkt",
      "nematocida": "Nematizid",
      "sostanza_di_crescita": "Wachstumsstoff",
      "biostimolante": "Biostimulans",
      "ritirato": "Zurückgezogen",
      "attivo": "Aktiv",
      "kg_ha": "kg/ha",
      "l_ha": "l/ha",
      "unita_ha": "Einheiten/ha",
      "anno": "Jahr",
      "ciclo": "Zyklus",
      "stagione": "Saison",
      "semente": "Saatgut",
      "piantine": "Sämlinge",
      "libre_acro": "Pfund/Acre",
      "minerale": "Mineral",
      "organico": "Organisch",
      "tillage": "Bodenbearbeitung",
      "sowing": "Aussaat",
      "defence": "Verteidigung",
      "irrigation": "Bewässerung",
      "nutrition": "Ernährung",
      "sereno": "Klar",
      "nuvoloso": "Bewölkt",
      "variabile": "Wechselhaft",
      "molto_nuvoloso": "Stark bewölkt",
      "coperto": "Bedeckt",
      "nebbia": "Nebel",
      "coperto_nebbia": "Bedeckt mit Nebel",
      "variabile_con_rovesci": "Wechselhaft mit Schauern",
      "temporale": "Gewitter",
      "coperto_con_neve": "Bedeckt mit Schnee",
      "pioggia_misto_neve": "Schneeregen",
      "erbacee_botticella": "Krautig Knospenbildung",
      "erbacee_fioritura": "Krautig Blüte",
      "erbacee_invaiatura": "Krautig Reifung",
      "erbacee_allegagione": "Krautig Fruchtansatz",
      "erbacee_ingrossamento": "Krautig Anschwellen",
      "erbacee_germinazione": "Krautig Keimung",
      "erbacee_emergenza_infioriscenza": "Krautig Infloreszenznotstand",
      "erbacee_sviluppo_fogliare": "Krautig Blattentwicklung",
      "erbacee_maturazione": "Krautig Reifung",
      "erbacee_senescenza": "Krautig Seneszenz",
      "erbacee_levata": "Krautig Aufrichtung",
      "erbacee_accestimento": "Krautig Bestockung",
      "arboree_gemme_inverno": "Baum Knospen Winter",
      "arboree_apertura_gemme": "Baum Knospenöffnung",
      "arboree_ripresa_vegetativa": "Baum Vegetationsbeginn",
      "arboree_sviluppo_germogli": "Baum Triebentwicklung",
      "arboree_foglie_distese": "Baum Blätter entfaltet",
      "arboree_grappoli_visibili": "Baum Trauben sichtbar",
      "arboree_grappoli_separati": "Baum Trauben getrennt",
      "arboree_ingrossamento_frutti": "Baum Fruchtanschwellung",
      "arboree_pre_chiusura_grappolo": "Baum Vor Traubenschluss",
      "arboree_chiusura_grappolo": "Baum Traubenschluss",
      "arboree_indurimento_nocciolo": "Baum Kernhärtung",
      "arboree_invaitura": "Baum Reifung",
      "arboree_riposo_vegetativo": "Baum Vegetationsruhe",
      "arboree_fioritura": "Baum Blüte",
      "arboree_allegagione": "Baum Fruchtansatz",
      "arboree_pianta_ferma": "Baum Pflanzenstopp",
      "arboree_germogliamento": "Baum Austrieb",
      "arboree_germogli_5_10_cm": "Baum Triebe 5-10 cm",
      "arboree_comparsa_boccioli_floreali": "Baum Blütenknospen erscheinen",
      "arboree_bottone_bianco": "Baum Weißknospe",
      "arboree_crescita": "Baum Wachstum",
      "arboree_semi_neri": "Baum Schwarze Samen",
      "arboree_pre_raccolta": "Baum Vorernte",
      "arboree_raccolta": "Baum Ernte",
      "basso": "Niedrig",
      "medio": "Mittel",
      "alto": "Hoch",
      "aratura": "Pflügen",
      "erpicatura": "Eggen",
      "zappatura": "Hackarbeit",
      "sarchiatura": "Jäten",
      "frangizollatura": "Grubber",
      "fresatura": "Fräsen",
      "rippatura": "Rippern",
      "rullatura": "Walzen",
      "compattazione": "Verdichtung",
      "pacciamatura": "Mulchen",
      "trinciatura": "Schlegeln",
      "Seedlings": "Sämlinge",
      "Pounds per acre": "Pfund pro Morgen",
      "Mineral Product": "Mineralprodukt",
      "Nutrition": "Ernährung",
      "Historic Data": "Historische Daten",
      "Withdrawn": "Zurückgezogen",
      "Active": "Aktiv",
      "Year": "Jährlich",
      "Cycle": "Zyklus",
      "Season": "Saison",
      "Defense": "Verteidigung",
      "Active or Retrivied:": "Aktiv oder zurückgezogen",
      "Please fill all the field": "Bitte füllen Sie alle Felder aus",
      "Please fill all the form to create a field activities": "Bitte füllen Sie alle Formularfelder aus, um eine Feldaktivität zu erstellen",
      "Other Revenue": "Andere Einnahmen",
      "Harvest": "Ernte",
      "Please insert the date of the revenue": "Bitte geben Sie das Datum des Ertrags ein",
      "Please enter the start date of the operator cost": "Bitte geben Sie das Startdatum der Betriebskosten ein",
      "Pear_fruit": "Birne",
      "Dry_pulses": "Hülsenfrüchte",
      "Strawberries": "Erdbeere",
      "Tobacco": "Tabak",
      "Other_fibre_and_oleaginous_crops": "Andere Faser- und Ölpflanzen",
      "Other_fresh_vegetables": "Andere frische Gemüse",
      "Upgrade to PRO": "Auf PRO upgraden",
      "Something Went Wrong During the Payment": "Beim Bezahlen ist ein Fehler aufgetreten",
      "Please send us an email to info@automaticfarmsolution.com": "Bitte senden Sie uns eine E-Mail an info@automaticfarmsolution.com",
      "An operator will answear you as soon as possible": "Ein Operator wird Ihnen so schnell wie möglich antworten",
      "VAT Excluded": "MwSt. ausgeschlossen",
      "The total price is 832 € (VAT Included)": "Der Gesamtpreis beträgt 832 € (inklusive MwSt.)",
      "All the modules of free version": "Alle Module der kostenlosen Version",
      "Generation of prescription map with Artificial Intelligence": "Erstellung von Verschreibungskarten mit Künstlicher Intelligenz",
      "Capability to connect IoT Sensors": "Fähigkeit, IoT-Sensoren anzuschließen",
      "Set automatic alerts": "Automatische Warnungen einstellen",
      "Artificial Intelligence Digital Soil Mapping": "Digitale Bodenkartierung mit Künstlicher Intelligenz",
      "Advanced Report & Documentation": "Erweiterte Berichte & Dokumentation",
      "Online Live Training of the Platform": "Online-Live-Schulung der Plattform",
      "Number of Plant": "Anzahl der Pflanzen",
      "Number of note": "Anzahl der Notizen",
      "Note": "Notiz",
      "Add Note": "Notiz hinzufügen",
      "Download Note": "Notiz herunterladen",
      "List of the note": "Liste der gespeicherten Notizen",
      "User": "Benutzer",
      "Plant": "Pflanze",
      "Image": "Bild",
      "Created at": "Erstellt am",
      "Detail": "Details",
      "No Note are Registrated": "Keine Notizen wurden registriert",
      "Plant Number": "Pflanzennummer",
      "Upload or Capture Image": "Bild hochladen oder aufnehmen",
      "Save": "Speichern",
      "Detail Note - ": "Detail der Notiz - ",
      "User - ": "Benutzer, der die Notiz erstellt hat - ",
      "Plant - ": "Pflanze - ",
      "Note - ": "Notiz - ",
      "Image": "Bild",
      "Created at - ": "Erstellt am - ",
      "Are you sure to delete the note?": "Sind Sie sicher, dass Sie die Notiz löschen möchten?",
      "Update Note": "Notiz aktualisieren",
      "New registration": "Neue Registrierung",
      "You don't have yet the permission to use this application": "Sie haben noch nicht die Berechtigung, diese Anwendung zu nutzen",
      "Please use the following button to send a request to the wine manager to be activated": "Um mit der Nutzung der Anwendung zu beginnen, verwenden Sie bitte die folgende Schaltfläche, um eine Anfrage an den Weinmanager zur Genehmigung zu senden",
      "Send the request": "Anfrage senden",
      "The plant cannot be empty": "Die Pflanzennummer darf nicht leer sein",
      "The note cannot be empty": "Die Notiz darf nicht leer sein",
      "You must upload a image": "Sie müssen ein Bild hochladen"
    }
  }
}

export default translation;
  
  
import React from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux'; 
import { Container } from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import ScrollToTop from "react-scroll-to-top";

import NoteList from '../views/examples/Note/NoteList'
import AddNote from '../views/examples/Note/AddNote'
import UpdateNote from '../views/examples/Note/UpdateNote'
import DeleteNote from '../views/examples/Note/DeleteNote'
import ViewNote from '../views/examples/Note/ViewNote'

import AdminControl from '../views/examples/AdminControl/AdminControl'

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    if (mainContent.current) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const is_collaborator = useSelector((state) => state.userLogin.userInfo.is_collaborator);
  const is_wine_manager = useSelector((state) => state.userLogin.userInfo.is_wine_manager);

  return (
    <>
      <ScrollToTop smooth color="#6f00ff" />
      <Sidebar
        {...props}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/argon-react.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={"Marco"}
        />
        <Routes>
          
          {is_collaborator ? (
            <>
              <Route path="/" element={<NoteList />} />
              <Route path="add-note/" element={<AddNote />} />
              <Route path="delete-note/:fieldId" element={<DeleteNote />} />
              <Route path="update-note/:fieldId" element={<UpdateNote />} />
              <Route path="detail-note/:fieldId" element={<ViewNote />} />
            </>
          ):(
            <>
              <Route path="/" element={<AdminControl />} />
            </>
          )}
          
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
        
    </>
  );
};

export default Admin;

import { Link } from "react-router-dom";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import LanguageSelector from '../LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {logout} from 'actions/userActions'

const AdminNavbar = (props) => {  

  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  const { t } = useTranslation();

  const navigate = useNavigate()  

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
    navigate("/")
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {/* {props.brandText} */}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <LanguageSelector/>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={"https://i.ibb.co/KXBJ1dm/149071.png"}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {userInfo.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={logoutHandler}>
                  <i class="fa-solid fa-right-from-bracket"></i>
                  <span>{t('Logout')}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;

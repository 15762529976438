import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, Button, Spinner, Alert, CardBody, CardFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Header from "components/Headers/Header.js";
import { Form } from 'react-bootstrap';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';

function AddNote() {

  const [loading, setLoading] = useState(false);
  const [plant, setPlant] = useState('');
  const [note, setNote] = useState('');
  const [image, setImage] = useState('');

  const token = useSelector((state) => state.userLogin.userInfo.token);
  const userid = useSelector((state) => state.userLogin.userInfo.userid);

  const navigate = useNavigate();

  const [loadingsubmit, setLoadingsubmit] = useState(true)

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguageAFS');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  // Generate options from 1 to 22,000
  const plantOptions = Array.from({ length: 22000 }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}`
  }));

  // Handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Handle capturing photo on mobile
  const handleCapturePhoto = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Display the selected image as a preview (if applicable)
  const renderImagePreview = () => {
    if (image) {
      return (
        <div className="mt-3">
          <h5>{t('Selected Image')}:</h5>
          <img
            src={URL.createObjectURL(image)}
            alt="Preview"
            style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
          />
        </div>
      );
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingsubmit(false);

    if (plant==='') {
      setMessage(t('The plant cannot be empty'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    }

    if (note==='') {
      setMessage(t('The note cannot be empty'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    } 

    if (image==='') {
      setMessage(t('You must upload a image'))
      setError(true)
      setTimeout(function() {
        setError(false)
      }, 5000);
      setLoadingsubmit(true);
      return;
    } 

    try {
      const formData = new FormData();
      formData.append("plant", plant.value); // Assuming `plant` is an object with value and label
      formData.append("note", note);
      formData.append("image", image); // Directly append the file
      formData.append("user", userid);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Include the authorization token
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/note/add-note/`,
        formData,
        config
      );
      
    } catch (error) {
      console.error("Error submitting note:", error);
    } finally {
      setLoadingsubmit(true);
      navigate('/');
    }
  };

  return (
    <div>
      <Header/>
      <Container className="mt--15" fluid>
        {loading ? (
          <Row>
            <Col xs={12} className="text-center mt-5">
              <Spinner>
                {t('Loading')}
              </Spinner>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} className="mt-5">
              <Card>
                <CardHeader>
                  <h3>{t('Add Note')}</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Form.Group controlId="formCreateFarmer">
                      <Form.Label>{t('Plant Number')}</Form.Label>
                      <Select
                        options={plantOptions}
                        value={plant}
                        onChange={(selectedOption) => setPlant(selectedOption)}
                        placeholder={t('Plant Number')}
                        isSearchable
                        isClearable
                      />
                      <br />
                      <Form.Label>{t('Note')}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t('Note')}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                      <br />
                      <Form.Label>{t('Upload or Capture Image')}</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        capture="environment" // Enables camera on mobile devices
                        onChange={handleCapturePhoto}
                      />
                      {renderImagePreview()}
                      <br />
                      {error && 
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                          <Alert color="danger" fade={false}>{message}</Alert>
                        </Col>                    
                      }
                    </Form.Group>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                      {loadingsubmit ? (
                        <Button color="success" onClick={handleSubmit}>
                          {t('Save')}
                        </Button>
                      ):(
                        <Button color="success" disabled>
                          <Spinner size="sm">
                            Loading...
                          </Spinner>
                          <span>
                            {' '}Loading
                          </span>
                        </Button>
                      )}
                      <Link to="/">
                        <Button color="primary">
                          {t('Go back')}
                        </Button>
                      </Link>   
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default AddNote;

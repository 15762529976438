import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Col, Row, Container, Card, CardHeader, CardBody, Spinner, CardFooter } from 'reactstrap';
import axios from 'axios';
import Header from 'components/Headers/Header'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';


function DeleteNote() {

  const [loading, setLoading]=useState(true)
  const [note, setNote] = useState('')
  const [loadingsubmit, setLoadingsubmit] = useState(true)

  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  const { fieldId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {

      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        };
  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/note/note-detail/${fieldId}`, config);
        setNote(response.data);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingsubmit(false);

     try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Include the authorization token
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_API}/note/delete-note/${fieldId}/`, config);

    } catch (error) {
      console.error("Error submitting note:", error);
    } finally {
      setLoadingsubmit(true);
      navigate('/');
    }

  }

  return (
    <div>
      <Header/>
      <Container className="mt--15" fluid>
        {loading ? (
          <>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Spinner>
                  {t('Loading')}
                </Spinner>
              </Col>
            </Row>
          </>
        ):(
          <>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                <Card>
                  <CardHeader>
                    <h3>{t('Are you sure to delete the note?')}</h3>
                  </CardHeader>
                  <CardBody>
                    {note && (
                      <>
                        <p>{t('User - ')} {note.User_Email}</p>
                        <br></br>
                        <p>{t('Plant - ')} {note.Plant}</p>
                        <br></br>
                        <p>{t('Note - ')} {note.Note}</p>
                        <br></br>
                        <h5>{t('Image')}</h5>
                        <img src={note.Image} alt="Note" style={{ width: '300px', height: '300px', objectFit: 'cover' }} />
                        <br></br>
                        <br></br>
                        <p>{t('Created at - ')} {(() => {
                                                          const date = new Date(note.created_at); // Convert to Date object
                                                          date.setHours(date.getHours() + 1); // Add 1 hour
                                                          const day = date.getDate().toString().padStart(2, '0'); // Get day with leading zero
                                                          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month with leading zero
                                                          const year = date.getFullYear(); // Get year
                                                          const hours = date.getHours().toString().padStart(2, '0'); // Get hours with leading zero
                                                          const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes with leading zero
                                                          return `${day}/${month}/${year} ${hours}:${minutes}`; // Return formatted date
                                                        })()}
                          </p>
                      </>
                    )}
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        {loadingsubmit ? (
                          <Button color="danger" onClick={handleSubmit}>
                            {t('Delete')}
                          </Button>
                        ):(
                          <Button color="danger" disabled>
                            <Spinner size="sm">
                              Loading...
                            </Spinner>
                            <span>
                              {' '}Loading
                            </span>
                          </Button>
                        )}
                        <Link to="/">
                          <Button color="primary">
                            {t('Go back')}
                          </Button>
                        </Link>   
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  )
}

export default DeleteNote

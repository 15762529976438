import React from 'react'
import { useState } from "react";
import {useParams, useNavigate} from 'react-router-dom'

import axios from 'axios';

import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert,
    Spinner
  } from "reactstrap";

import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

function ChangePassword() {

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);

    ////////////

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    
    const [loading, setLoading] = useState(true);

    const { uid, token } = useParams();

    const navigate = useNavigate();

    const handleSubmit  = async (e) => {
        e.preventDefault()
        
        setLoading(false)

        if (password1==='') {
            setMessage(t('The first password is empty'))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
        } 

        if (password2==='') {
            setMessage(t('The confirm password is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoading(true)
            return;
        } 

        if (password1 !== password2) {
            setMessage(t("The password don't match"))
            setError(true)
            setTimeout(function() {
            setError(false)
            }, 5000);
            setLoading(true)
            return;
        } 

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/users/change-password/${uid}/${token}/`, 
                                             { new_password: password1 });
            
            if (response.request.status===200) {
                
                setLoading(true)
                navigate('/change-password-confirmed');

            } else {
                setMessage(t("Password change failed. If the problem persist, please send us an email at info@automaticfarmsolution.com"))
                setError(true)
                setTimeout(function() {
                  setError(false)
                }, 5000);
                setLoading(true)
                return;

            }

        } catch (error) {
            console.error('Error changing password:', error);
            setMessage(t("An error occurred while changing the password. If the problem persist, please send us an email at info@automaticfarmsolution.com"))
            setError(true)
            setTimeout(function() {
              setError(false)
            }, 5000);
            setLoading(true)
            return;
        }
        
    }

    return (
      <>
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                <Row className="justify-content-center">
                    <Col lg="12" md="12">
                    <h1 >Vineyard Note</h1>
                    <img
                        alt="Automatic Farm Solution"
                        src={"https://i.ibb.co/NZ8qZsk/111111.png"}
                        width={"200px"}
                        height={"200px"}
                    />
                    </Col>
                </Row>
                </div>
                {error && <Alert color="danger" fade={false}>{message}</Alert>}
                <FormGroup>
                  <h4>{t("Change Password")}</h4>
                  <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                  </InputGroupAddon>
                  <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      controlid='password1'
                      value={password1} 
                      onChange={(e) => setPassword1(e.target.value)}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder={t("Confirm Password")}
                        type="password"
                        autoComplete="new-password"
                        controlid='password2'
                        value={password2} 
                        onChange={(e) => setPassword2(e.target.value)}/>
                    </InputGroup>
                </FormGroup>
                <br></br>
                {loading ? (
                  <>
                    <Button className="my-4" color="success" type="button" onClick={handleSubmit}>
                      {t("Change Password")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button color="success" disabled>
                        <Spinner size="sm">
                            Loading...
                        </Spinner>
                        <span>
                        {' '}Loading
                        </span>
                    </Button>
                  </>
                )}
            </CardBody>
            </Card>
        </Col>
      </>
    )
}

export default ChangePassword

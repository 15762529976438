import React, { useState, useEffect } from 'react';

import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import "./stylesheadersbackgroundimage.css";

import axios from 'axios';
import { useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';

const Header = () => {
  
  const token = useSelector((state) => state.userLogin.userInfo.token);

  ////////////
  const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

  useEffect(() => {
      const storedLanguage = localStorage.getItem('selectedLanguageAFS');
      if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);  // Use i18n to change language
      }
  }, [i18n]);
  ////////////

  const [plant, setPlants] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const fetchData = async () => {

      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/note/number-of-note/`, config);
        setNotes(response.data.note);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      } finally {
      }
    };
  
    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/plant/plant-number/`, config);
        setPlants(response.data.plant);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      } finally {
      }
    };
  
    fetchData();
  }, [token]);
  
  return (
    <>
      {/* <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"> */}
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t('Number of Plant')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {plant && (
                            <>
                              {plant}
                            </>
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i className="fa fa-user" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {t('Number of note')}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                        {notes && (
                            <>
                              {notes}
                            </>
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fa fa-plus" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;

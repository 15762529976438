import React, { useEffect, useState } from 'react';
import { Container, Row, Col,  Card, CardBody, CardHeader, Button, Spinner, CardFooter, Alert } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from "components/Headers/Header.js";

export default function AdminControl() {

    const [loadingsubmit, setLoadingsubmit] = useState(true)
    const [message, setMessage]=useState('')
    const [error, setError]=useState(false)

    const token = useSelector((state) => state.userLogin.userInfo.token);

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);
    ////////////

    const handleSubmit = async(e) => {

        setLoadingsubmit(false)

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            await axios.get(`${process.env.REACT_APP_BACKEND_API}/users/send-request-to-activate-account/`, config);

        } catch (error) {
            console.error("Error submitting note:", error);
        } finally {

            setMessage(t('The request has been sent'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoadingsubmit(true);

        }
    }

    return (
        <div>
            <Header />
            <Container className="mt--15" fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">{t('New registration')}</h3>
                            </CardHeader>
                            <CardBody>
                                <h3>{t("You don't have yet the permission to use this application")}</h3>
                                <h3>{t('Please use the following button to send a request to the wine manager to be activated')}</h3>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    {error && 
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        <Alert color="success" fade={false}>{message}</Alert>
                                        </Col>                    
                                    }
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="text-center mt-5">
                                        {loadingsubmit ? (
                                            <Button color="success" onClick={handleSubmit}>
                                                {t('Send the request')}
                                            </Button>
                                        ):(
                                            <Button color="success" disabled>
                                                <Spinner size="sm">
                                                Loading...
                                                </Spinner>
                                                <span>
                                                {' '}Loading
                                                </span>
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
